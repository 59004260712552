.insureds-list {
    .insureds-list-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 40px;
      flex-wrap: wrap;
    }
  }
  .custom-datatable {
    padding-top: 5px;
  
    a.icon-quick_view {
      color: #8CC739 !important;
      //position: relative;
      cursor: pointer;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
    }
    a:not([href]) {
      //color: inherit;
      text-decoration: none;
    }
    a.icon-quick_view:before {
      color: #8CC739 !important;
      content: "\e912";
      margin: 5px;
    }
    a.icon-delete:before {
      color: #8CC739 !important;
      content: "\E906";
      cursor: pointer;
      margin: 5px;
    }
  
    a.icon-edit {
      color: #8CC739 !important;
      //position: relative;
      cursor: pointer;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
    }
  
    a.icon-edit:before {
      content: "\E908";
      color: #8CC739;
      margin: 5px;
    }
    .tooltip-template-missing {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: flex-start;
      svg{
        font-size: 1rem;
        color: #8CC739;
      }
    }

    .hctable-link {
      color: #007bff !important;
    }
    .email-checkbox:checked:after {
      top: -1px !important;
    }

    a.icon-link {
      color: #8cc739;
      position: relative;
      cursor: pointer;
      //font-size: 12px;
    }

    a.icon-link:before{
      color: #8cc739;
      content: "\E90C";
      margin: 5px;
    }

    a.icon-add {
      cursor: pointer;
      color: #8cc739;
    }

    a.icon-add:before {
      color: #8cc739;
    }

    .pq-link {
      color: #2e5965 !important;
    }
  }
  .pq {
    a.icon-edit, a.icon-edit:before {
      color: #2e5965 !important;
    }
    a.icon-delete, a.icon-delete:before {
      color: #2e5965 !important;
    }
    a.icon-quick_view, a.icon-quick_view:before {
      color: #2e5965 !important;
    }

    a.icon-add:before {
      color: #2e5965 !important;
    }
  }
  
