.block-ui {
  position: relative;
  min-height: 3em; }

.block-ui-container {
  position: absolute;
  z-index: 1010;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  min-height: 2em;
  /*cursor: wait;*/
  overflow: hidden; }

.block-ui-container:focus {
  outline: none; }

.block-ui-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  filter: alpha(opacity=50);
  background-color: white; }
