.sciButton {
  cursor: pointer;
  min-height: 40px;
  min-width: 100px;
  border-radius: 5px;
  border-top: none;
  border-right: none;
  border-left: none;
  background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
  border-bottom-color: #2E5965;
  border-bottom-width: 4px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding-left: 10px;
  padding-right: 10px; }

.statusContainer {
  display: flex;
  justify-content: space-between;
  width: 260px; }

.projects-list .limitedWidthText {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.scprofile-container {
  padding: 30px 40px; }
  .scprofile-container .scprofile-tabs {
    background-color: #e8edee;
    margin-top: 30px; }
  .scprofile-container .sc-header-info {
    padding-top: 3rem !important;
    box-shadow: 0px 0px 2px 1px #4aad98;
    margin: 0;
    padding: 15px; }
    .scprofile-container .sc-header-info .sc-general-info {
      border-right: 2px solid #c3e3dc;
      padding: 0 40px 0 0; }
      .scprofile-container .sc-header-info .sc-general-info .sc-name-container {
        width: 100%;
        border-bottom: 2px solid #c3e3dc;
        color: #2E5965;
        font-weight: 600;
        padding: 0 20px 15px 20px;
        text-align: center; }
      .scprofile-container .sc-header-info .sc-general-info .sc-address-container {
        width: 100%;
        padding: 30px 0; }
        .scprofile-container .sc-header-info .sc-general-info .sc-address-container label {
          color: #666666;
          text-align: center;
          width: 100%;
          margin: 0;
          font-size: 13px;
          font-weight: 500; }
      .scprofile-container .sc-header-info .sc-general-info button {
        display: block;
        margin: auto;
        width: 80%; }
    .scprofile-container .sc-header-info .sc-financial-info {
      padding: 10px 60px 10px 50px; }
      .scprofile-container .sc-header-info .sc-financial-info .row {
        margin: 0;
        padding: 5px 0; }
      .scprofile-container .sc-header-info .sc-financial-info .thinRow {
        margin: 0;
        padding: 0; }
      .scprofile-container .sc-header-info .sc-financial-info .rowAbove {
        margin: 0;
        padding-bottom: 0 !important; }
      .scprofile-container .sc-header-info .sc-financial-info .rowBelow {
        margin: 0;
        padding-top: 0 !important; }
      .scprofile-container .sc-header-info .sc-financial-info .sc-header-main-trade {
        position: relative;
        z-index: 1; }
      .scprofile-container .sc-header-info .sc-financial-info .sc-financial-value {
        color: #2E5965;
        font-size: 14px;
        font-weight: 500;
        z-index: 0; }
      .scprofile-container .sc-header-info .sc-financial-info .sc-financial-label {
        color: #A3A1AA;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase; }
    .scprofile-container .sc-header-info button {
      height: 40px;
      min-width: 130px;
      border-radius: 5px;
      border-top: none;
      border-right: none;
      border-left: none;
      background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
      border-bottom-color: #305866;
      border-bottom-width: 4px;
      font-size: 14px;
      font-weight: bold;
      color: white;
      padding-left: 10px;
      padding-right: 10px; }
    .scprofile-container .sc-header-info .change-status-btn {
      height: 30px;
      min-width: 100px;
      background: none;
      background-color: #8CC739;
      border: 1px solid #2E5965;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      margin-left: 10px;
      cursor: pointer; }
    .scprofile-container .sc-header-info .no-margin-left {
      margin-left: 0 !important; }

.sc-name .sc-financial-value {
  white-space: pre-wrap;
  word-break: break-word; }

.files-filter-form {
  background-color: #FFFFFF; }
  .files-filter-form .field-validation-message {
    right: 38px;
    top: 6px; }

.download-file .spinner-wrapper {
  margin-top: 0;
  width: 54.5px; }

.filter__checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 16px; }

.filter__checkbox-label {
  width: max-content !important;
  margin-right: 8px; }

.filter__checkbox {
  width: auto !important; }

.changeHcSelect {
  margin: 0;
  padding: 0; }
  .changeHcSelect select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent;
    border-radius: 3px;
    border: 1px none #CFCFD0;
    color: #4E4E4E;
    font-size: 12px; }
  .changeHcSelect option {
    color: #2E5965;
    font-size: 14px;
    font-weight: 500; }

.rowHeight {
  height: 35px !important; }

.lineHeight {
  line-height: 28px !important; }

.noBottomMarPad {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important; }

.noTopMarPad {
  margin-top: 0 !important;
  padding-top: 0 !important; }

.noTopBottomMarPad {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  padding-top: 0 !important; }

.labelPad {
  padding-right: 5px; }

.specialSelect {
  line-height: 25px;
  float: right !important;
  color: #2E5965;
  font-size: 14px;
  font-weight: 500; }
  .specialSelect:after {
    top: 10px !important; }

.hcSelect {
  max-width: 100%;
  width: fit-content;
  padding: 4px 8px;
  cursor: pointer;
  padding-right: 2rem; }
  .hcSelect:focus {
    outline: none;
    border: 0; }

.sc-financial-recommend {
  color: #FF0000;
  font-size: 11px;
  font-weight: 500;
  line-height: 2px !important;
  text-transform: uppercase; }

.notice {
  font-size: 20px;
  width: 100%;
  text-align: center; }

.rightCol {
  margin-left: 80px !important; }

.rightCol2 {
  margin-left: 40px !important; }

.profileBtn {
  width: 150px !important; }

.editBtn {
  content: "Edit";
  color: blue; }

.vk_hc_sc_label {
  margin-bottom: 5px;
  color: #373a3c;
  font-size: 14px;
  font-weight: 500; }

.vk_hc_sc_container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 !important;
  position: relative;
  top: -6px;
  z-index: 1;
  width: fit-content;
  border-radius: 4px; }
  .vk_hc_sc_container .vk_hc_label {
    display: block;
    min-width: 6rem; }
  .vk_hc_sc_container .vk_hc_label_select {
    display: block;
    width: fit-content;
    white-space: nowrap;
    font-weight: 800 !important;
    color: #2E5965 !important; }
  .vk_hc_sc_container .vk_hc_value_single {
    display: flex;
    align-items: center;
    min-width: fit-content !important;
    max-width: 150px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    position: relative; }
    padding .vk_hc_sc_container .vk_hc_value_single:after {
      display: none; }
  .vk_hc_sc_container .vk_hc_value {
    display: block;
    min-width: fit-content !important;
    max-width: 180px;
    position: relative; }
    .vk_hc_sc_container .vk_hc_value:after {
      content: '';
      position: absolute;
      margin-top: 0.3rem;
      right: 0.6rem;
      color: #ffffff00;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #085DAD;
      z-index: 0; }
    .vk_hc_sc_container .vk_hc_value .sc_role_dropdown {
      border: 1px solid #085DAD !important; }
    .vk_hc_sc_container .vk_hc_value div {
      width: fit-content;
      border: 1px solid transparent;
      border-radius: 4px;
      position: relative;
      z-index: 1; }
      .vk_hc_sc_container .vk_hc_value div:focus-within {
        border: 1px solid #085DAD; }
  .vk_hc_sc_container .vk_sc_move {
    display: block;
    max-width: fit-content !important;
    min-width: 160px;
    font-size: .8rem;
    margin-left: .5rem; }
    .vk_hc_sc_container .vk_sc_move .vk_move_sc__control {
      border-style: none; }
      .vk_hc_sc_container .vk_sc_move .vk_move_sc__control .vk_move_sc__placeholder {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 3px; }
      .vk_hc_sc_container .vk_sc_move .vk_move_sc__control .vk_move_sc__indicator-separator {
        display: none; }
      .vk_hc_sc_container .vk_sc_move .vk_move_sc__control .vk_move_sc__indicator {
        padding: 0px 8px; }
        .vk_hc_sc_container .vk_sc_move .vk_move_sc__control .vk_move_sc__indicator:after {
          content: ' ';
          position: absolute;
          top: 1.1rem;
          right: .7rem;
          border-top: 6px solid #085DAD;
          color: #ffffff00;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          width: 0;
          z-index: 0; }
        .vk_hc_sc_container .vk_sc_move .vk_move_sc__control .vk_move_sc__indicator svg {
          visibility: hidden; }

.fin-update-check {
  font-size: 20px; }
  .fin-update-check:before {
    color: #2e5965; }
