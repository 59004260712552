.logo-sidebar-wrapper {
  margin-top: 0px;
  padding-left: 0px;
  margin-bottom: 10px;
  padding-right: 0px;
  border-bottom: 1px solid #1d3a42;
  padding-bottom: 12px;
  padding-top: 12px;
  text-align: center; }

.img-extended > img {
  width: 200px;
  height: auto; }

.img-not-extended > img {
  width: 40px;
  height: auto; }

.sidebar-col {
  background: #2E5965;
  width: 8%;
  z-index: 1;
  position: absolute;
  transition: width .1s; }

.viewport .sidebar-col {
  padding-left: 0;
  padding-right: 0;
  min-height: 100vh; }
  .viewport .sidebar-col.extra-height {
    min-height: 100vh; }

.sidebar-wrapper ul, li {
  list-style: none;
  padding: 0; }
  .sidebar-wrapper ul.extended-list, li.extended-list {
    margin-bottom: 40px; }

.sidebar-root {
  display: flex;
  overflow-x: hidden; }

.sidebar-col a:hover {
  color: #FFFFFF;
  text-decoration: underline; }

ul.nav-list {
  padding-top: 33px; }
  ul.nav-list a.sidebar-link {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
    margin-right: 15px;
    margin-left: 10px;
    position: relative;
    text-align: center;
    padding-bottom: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column; }
    ul.nav-list a.sidebar-link:before {
      font-size: 39px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 7px; }
    ul.nav-list a.sidebar-link:hover, ul.nav-list a.sidebar-link:focus {
      color: #D1D8E5;
      text-decoration: none; }
      ul.nav-list a.sidebar-link:hover:before, ul.nav-list a.sidebar-link:focus:before {
        color: #D1D8E5; }
  ul.nav-list li {
    text-align: center;
    margin-bottom: 19px; }

ul.nav-list-extended a.sidebar-link {
  font-size: 13px;
  font-weight: 600;
  padding-right: 13px;
  padding-left: 13px;
  position: relative;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: row; }
  ul.nav-list-extended a.sidebar-link.parent {
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 10px; }
  ul.nav-list-extended a.sidebar-link:before {
    font-size: 13px;
    font-weight: 800;
    color: rgba(255, 255, 255, 0.5);
    display: block;
    margin-right: 5px;
    margin-top: 1px; }
  ul.nav-list-extended a.sidebar-link:hover, ul.nav-list-extended a.sidebar-link:focus {
    color: #CFCFD0;
    text-decoration: none; }
    ul.nav-list-extended a.sidebar-link:hover:before, ul.nav-list-extended a.sidebar-link:focus:before {
      color: #D1D8E5; }
  ul.nav-list-extended a.sidebar-link li.extended-list {
    text-align: center; }

ul.nav-list-extended .child {
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: none;
  color: #E3E7EF; }

.render-list {
  display: flex;
  flex-direction: column; }
  .render-list span {
    text-transform: uppercase;
    align-self: center;
    color: #F2FAF8;
    margin-bottom: 40px;
    font-weight: 500; }

a.sidebar-link.child:hover {
  background: #1d3a42;
  border-left: 7px solid #29AEA2; }

.viewport .sidebar-col .sidebar-container.open:after {
  content: 'a';
  color: transparent;
  background: rgba(100, 100, 100, 0.7);
  position: absolute;
  width: 500%;
  top: 0;
  left: 100%;
  height: 100%; }

select {
  color: black !important; }
