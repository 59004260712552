.holder-settings .settings-error {
  color: red;
  text-align: center; }

.holder-settings .entity-info-form {
  padding-top: 0px;
  margin-left: 5%; }
  .holder-settings .entity-info-form.ml-0 select {
    margin-left: 5%;
    width: calc((100%) - (5%)) !important; }
  .holder-settings .entity-info-form .admin-form-field-wrapper .label {
    min-width: 100px;
    line-height: 18px;
    height: fit-content; }
  .holder-settings .entity-info-form .admin-form-field-wrapper input {
    padding: 8px 7px 6px;
    height: fit-content;
    font-style: normal; }
  .holder-settings .entity-info-form .admin-form-field-wrapper .save-btn {
    color: #fff;
    background-color: #8CC739;
    border: none;
    padding: 8px 5px;
    margin-left: 10px;
    font-weight: 700;
    font-size: 12px;
    border-radius: 3px;
    height: fit-content; }
  .holder-settings .entity-info-form .admin-form-field-wrapper .save-btn-disabled {
    background-color: #badd88;
    cursor: not-allowed; }
  .holder-settings .entity-info-form .hawkIExtractionLimit {
    margin-top: 10px; }
