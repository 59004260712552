.project-view .section-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap; }

.project-view .profile-view-header-title {
  color: #2E5965;
  font-weight: bold;
  margin-bottom: 0; }

.project-view .profile-view-info-table {
  width: 100%;
  height: 100%;
  max-width: 100%;
  -webkit-box-shadow: rgba(46, 89, 101, 0.5) 0px 0px 5px;
  box-shadow: rgba(46, 89, 101, 0.5) 0px 0px 5px;
  border-radius: 5px;
  margin-bottom: 0; }
  .project-view .profile-view-info-table td {
    vertical-align: initial;
    padding: 0.4rem;
    border-top: none; }
    .project-view .profile-view-info-table td:first-child {
      padding-left: 5%;
      color: #2E5965;
      font-weight: bold;
      width: 25%; }

.project-description-modal {
  display: block; }

.project-description-modal-dialog {
  width: 800px;
  height: 80vh;
  margin: 1.75rem auto; }

.project-description-modal-content {
  width: 100%; }

.project-description-modal-body {
  overflow-y: scroll;
  max-height: calc(80vh - 80px);
  word-wrap: break-word;
  padding-right: 15px; }
