.workflow-table {
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 2px;
  border-color: grey;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400; }

.col-th-wrapper {
  color: #2E5965;
  font-size: 14px;
  text-transform: uppercase;
  padding: 9px 11px;
  letter-spacing: 0.5px;
  position: relative;
  display: inline-block;
  width: 100%;
  cursor: pointer; }

.center-table {
  width: 95%;
  margin: 0 auto; }

.workflow-wrapper {
  margin-top: 20px;
  margin-top: 50px; }

.type {
  overflow-wrap: break-word;
  width: 300px; }

.description {
  overflow-wrap: break-word;
  width: 360px; }

.edit-workflow-container {
  width: 100%;
  margin: 0 auto; }

.workflow-components a {
  float: right;
  margin-right: 30px;
  margin-top: 10px; }

.table-with-pagination {
  padding-bottom: 25px; }

.right-buttons {
  margin-left: 271px;
  width: 335px;
  display: inline-block; }

.workflow-title {
  font-weight: bolder;
  font-size: 20px;
  color: #305866;
  border-bottom: 3px solid #7ED0BC; }

.buttons-container {
  margin-top: 50px;
  margin-bottom: 50px; }

.workflow-button {
  background: linear-gradient(to bottom right, #60818A, #2E5965);
  color: white; }

.workflow-button:hover {
  color: white; }

.button-text {
  margin-left: 2px; }

.workflow-components {
  background: #f3f3f3;
  margin-top: 20px; }
  .workflow-components .cell {
    justify-content: center;
    min-height: 80px;
    display: inline-flex;
    align-items: center;
    padding: 0px;
    border-bottom: 2px dashed #979797; }
  .workflow-components .cell.dashed-border-left {
    border-left: 2px dashed #979797; }

.component-title {
  color: #305866;
  text-align: center;
  font-weight: bold; }

.component-row {
  margin: 0 !important; }
  .component-row .component-line-id {
    color: #7ED0BC;
    font-size: 24px;
    font-weight: bold; }
  .component-row .component-select {
    width: 100%;
    margin: 10% 15%; }
  .component-row .parameters-row {
    width: 100%;
    padding: 15px;
    align-items: center; }
    .component-row .parameters-row .parameter-col {
      padding: 0; }
      .component-row .parameters-row .parameter-col .parameter-name {
        overflow: hidden;
        text-overflow: ellipsis; }
      .component-row .parameters-row .parameter-col .param-select {
        width: 100%; }
  .component-row a {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    color: #2E5965; }
    .component-row a.cell-table-link {
      color: #8CC739 !important;
      position: relative;
      cursor: pointer; }
      .component-row a.cell-table-link:hover {
        text-decoration: underline; }
      .component-row a.cell-table-link:before {
        color: #8CC739;
        position: absolute;
        top: 1px;
        left: -16px; }

.possbile-value-select {
  width: 300px;
  text-overflow: ellipsis; }

.add-component-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; }
  .add-component-btn-container .add-component-btn {
    height: 40px;
    min-width: 130px;
    border-radius: 5px;
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: #29AEA2;
    border-bottom-color: #305866;
    border-bottom-width: 4px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px; }
