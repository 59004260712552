.notes-list .notes-list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap; }

.notes-list .limitedWidthText {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
