.disabledBtn {
  cursor: not-allowed;
  opacity: 0.5 !important; }

.nav-btn:disabled {
  cursor: not-allowed;
  opacity: 0.5 !important; }

.deficiency-menu-item {
  width: 100%;
  justify-content: center; }
