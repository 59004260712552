.insured-view .section-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap; }

.insured-view .compliance-status {
  border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1em;
  height: 1em; }
  .insured-view .compliance-status.compliant {
    background-color: #8CC739; }
  .insured-view .compliance-status.minor {
    background-color: #FEEC0F; }
  .insured-view .compliance-status.non-compliant {
    background-color: #f04c41; }
  .insured-view .compliance-status.escalated {
    background-color: #FEAB0F; }
  .insured-view .compliance-status.pending {
    background-color: lightgray; }

.insured-view .insured-actions .header-primary-button {
  margin-bottom: 10px; }

.insured-view .insured-actions .insured-actions-icons > span {
  margin-right: 10px;
  font-size: 28px;
  cursor: pointer; }

.insured-view .profile-view-info-header-title {
  color: #2E5965;
  font-weight: bold;
  margin-bottom: 0; }

.insured-view .profile-view-info-table {
  width: 100%;
  height: 100%;
  max-width: 100%;
  -webkit-box-shadow: rgba(46, 89, 101, 0.5) 0px 0px 5px;
  box-shadow: rgba(46, 89, 101, 0.5) 0px 0px 5px;
  border-radius: 5px;
  margin-bottom: 0; }
  .insured-view .profile-view-info-table td {
    vertical-align: initial;
    padding: 0.4rem;
    border-top: none; }
    .insured-view .profile-view-info-table td:first-child {
      padding-left: 5%;
      color: #2E5965;
      font-weight: bold; }

.insured-view .main-information {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .insured-view .main-information .main-information-table {
    flex-grow: 1;
    margin-bottom: 1rem; }
  .insured-view .main-information .main-information-tags .tags-group .tag-btn {
    cursor: pointer; }
  .insured-view .main-information .main-information-tags .tags-group .spinner-wrapper {
    margin-bottom: 0px; }
    .insured-view .main-information .main-information-tags .tags-group .spinner-wrapper .spinner {
      width: 20px;
      height: 20px; }
