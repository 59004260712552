.user-profile-container {
  padding: 60px 40px; }
  .user-profile-container button {
    margin-bottom: 40px;
    height: 40px;
    min-width: 130px;
    border-radius: 5px;
    border-top: none;
    border-right: none;
    border-left: none;
    background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
    font-size: 14px;
    font-weight: bold;
    color: white;
    padding-left: 10px;
    padding-right: 10px; }
  .user-profile-container .up-header-info {
    box-shadow: 0px 0px 2px 1px #4aad98;
    margin: 0;
    padding: 15px; }
    .user-profile-container .up-header-info .up-general-info {
      border-right: 2px solid #c3e3dc;
      padding: 0 40px 0 0; }
      .user-profile-container .up-header-info .up-general-info .up-name-container {
        width: 100%;
        border-bottom: 2px solid #c3e3dc;
        padding: 0 20px 15px 20px; }
        .user-profile-container .up-header-info .up-general-info .up-name-container .up-full-name {
          color: #2E5965;
          font-weight: 600;
          font-size: 16px; }
      .user-profile-container .up-header-info .up-general-info .up-phone-mail-container {
        width: 100%;
        padding: 30px 0; }
      .user-profile-container .up-header-info .up-general-info label {
        color: #666666;
        text-align: center;
        width: 100%;
        margin: 0;
        font-size: 13px;
        font-weight: 500; }
      .user-profile-container .up-header-info .up-general-info button {
        display: block;
        margin: auto;
        width: 80%; }
    .user-profile-container .up-header-info .up-hc-sc-info {
      padding: 10px 60px 10px 50px; }
      .user-profile-container .up-header-info .up-hc-sc-info .row {
        margin: 0;
        padding: 10px 0; }
      .user-profile-container .up-header-info .up-hc-sc-info .up-users-list {
        height: 185px;
        overflow-y: scroll; }
      .user-profile-container .up-header-info .up-hc-sc-info .up-list-title {
        color: #2E5965;
        font-size: 14px;
        font-weight: 500; }
      .user-profile-container .up-header-info .up-hc-sc-info .up-list-element {
        color: #A3A1AA;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase; }
    .user-profile-container .up-header-info button {
      height: 40px;
      min-width: 130px;
      border-radius: 5px;
      border-top: none;
      border-right: none;
      border-left: none;
      background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
      border-bottom-color: #305866;
      border-bottom-width: 4px;
      font-size: 14px;
      font-weight: bold;
      color: white;
      padding-left: 10px;
      padding-right: 10px; }
  .user-profile-container .up-activity-container {
    margin-top: 40px; }
    .user-profile-container .up-activity-container .up-activity-title {
      padding-bottom: 20px;
      color: #2E5965;
      width: 100%;
      margin: 0;
      font-size: 18px;
      font-weight: 600; }
