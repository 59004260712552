.tab-list .tab-list-header {
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap; }

.tab-list .row {
  display: flex; }

.tab-list .buttons {
  align-items: right; }
