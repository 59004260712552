.admin-view-body {
  padding: 30px 16px 50px; }

.searchModal {
  height: 600px !important; }

section.list-view-table {
  background: #FFFFFF;
  border: 1px solid rgba(46, 89, 101, 0.2);
  border-top: 0.5px solid rgba(46, 89, 101, 0.2);
  box-shadow: rgba(46, 89, 101, 0.2) 0px 0px 5px; }
  section.list-view-table .table-wrapper table {
    margin-bottom: 0; }
  section.list-view-table .table-wrapper thead tr {
    box-shadow: 0px 0px 0px 0px transparent; }
  section.list-view-table .table-wrapper th {
    padding: 0; }
    section.list-view-table .table-wrapper th:empty {
      cursor: auto;
      pointer-events: none; }
    section.list-view-table .table-wrapper th:first-child {
      padding-left: 41px; }
    section.list-view-table .table-wrapper th span.col-th-wrapper {
      color: #085DAD;
      font-size: 14px;
      text-transform: uppercase;
      padding: 9px 11px;
      letter-spacing: 0.5px;
      position: relative;
      display: inline-block;
      width: 100%;
      cursor: pointer; }
      section.list-view-table .table-wrapper th span.col-th-wrapper span.col-th-arrow {
        font-size: 0;
        display: inline-block;
        width: 0;
        border-top: 6px solid #2E5965;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        position: relative;
        right: -5px;
        top: -2px; }
      section.list-view-table .table-wrapper th span.col-th-wrapper.th-desc span.col-th-arrow {
        border-top: 0px;
        border-bottom: 6px solid #2E5965;
        top: -2px; }
  section.list-view-table .table-wrapper tr {
    box-shadow: 0px -1px 0px #A3A1AA; }
    section.list-view-table .table-wrapper tr:nth-of-type(even) {
      background: #F7F7F7; }
  section.list-view-table .table-wrapper td {
    vertical-align: middle;
    padding: 9px 11px;
    line-height: 32px;
    font-size: 13px;
    font-weight: 200;
    color: #000; }
    section.list-view-table .table-wrapper td span.status-cell {
      padding-left: 17px;
      position: relative; }
      section.list-view-table .table-wrapper td span.status-cell:before {
        content: '\2022';
        position: absolute;
        font-size: 30px;
        left: 0px;
        color: transparent;
        top: -10px; }
      section.list-view-table .table-wrapper td span.status-cell.inactive:before {
        color: #EA5071; }
      section.list-view-table .table-wrapper td span.status-cell.active:before, section.list-view-table .table-wrapper td span.status-cell.complete:before, section.list-view-table .table-wrapper td span.status-cell.completed:before {
        color: #29AEA2; }
      section.list-view-table .table-wrapper td span.status-cell.changepassword:before, section.list-view-table .table-wrapper td span.status-cell.pendinginvitation:before, section.list-view-table .table-wrapper td span.status-cell.pendingsubmission:before, section.list-view-table .table-wrapper td span.status-cell.incomplete:before, section.list-view-table .table-wrapper td span.status-cell.pending:before {
        color: #EA8856; }
      section.list-view-table .table-wrapper td span.status-cell.prequalified:before {
        color: #7ED0BC; }
    section.list-view-table .table-wrapper td img {
      border-radius: 20px; }
    section.list-view-table .table-wrapper td a.link-ada-color {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
      color: #2e5965; }
      section.list-view-table .table-wrapper td a.link-ada-color.cell-table-link {
        color: #2e5965;
        position: relative;
        cursor: pointer; }
        section.list-view-table .table-wrapper td a.link-ada-color.cell-table-link:hover {
          text-decoration: underline; }
        section.list-view-table .table-wrapper td a.link-ada-color.cell-table-link:before {
          color: #2e5965;
          position: absolute;
          top: 1px;
          left: -16px; }
    section.list-view-table .table-wrapper td a {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
      color: #2E5965; }
      section.list-view-table .table-wrapper td a.cell-table-link {
        color: #8CC739;
        position: relative;
        cursor: pointer; }
        section.list-view-table .table-wrapper td a.cell-table-link:hover {
          text-decoration: underline; }
        section.list-view-table .table-wrapper td a.cell-table-link:before {
          color: #8CC739;
          position: absolute;
          top: 1px;
          left: -16px; }
      section.list-view-table .table-wrapper td a.cell-table-link.icon-with-text:before {
        right: 2px;
        left: initial; }
  section.list-view-table .table-wrapper td:first-child {
    padding-left: 50px; }

.pagination-wrapper {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 35px 0 0; }
  .pagination-wrapper ul.table-pagination, .pagination-wrapper ul.table-pagination li {
    display: inline-block;
    margin: 0;
    padding: 0; }
  .pagination-wrapper a.pagination-link {
    cursor: pointer;
    background: transparent;
    font-weight: 700;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #CFCFD0;
    padding: 7px 10px;
    color: #2E5965 !important; }
    .pagination-wrapper a.pagination-link.active {
      background: #2E5965;
      color: #FFFFFF !important; }
  .pagination-wrapper ul.table-pagination > li, .pagination-wrapper span.pag-arrow {
    margin: 0 2px 0; }
  .pagination-wrapper span.pag-arrow.hide-pag-arrow {
    display: none; }

form.list-view-filter-form {
  background-color: #FFFFFF;
  border: 1px solid rgba(46, 89, 101, 0.2);
  padding: 13px 13px 24px;
  margin-top: -13px; }
  form.list-view-filter-form h2.list-view-filter-title {
    color: #2E5965;
    padding: 0;
    margin: 0 0 16px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700; }
  form.list-view-filter-form .type-ahead-search-bar-container {
    width: 100%; }
  form.list-view-filter-form .filter-fields .keywords-field, form.list-view-filter-form .filter-fields .hiring-client-field {
    padding-right: 30px; }
  form.list-view-filter-form .filter-fields label {
    font-size: 13px;
    margin-bottom: 0px;
    width: 100%;
    color: #2E5965;
    font-weight: 500; }
  form.list-view-filter-form .filter-fields input, form.list-view-filter-form .filter-fields select, form.list-view-filter-form .filter-fields textarea {
    color: #4E4E4E;
    padding: 6px 5px;
    font-size: 12px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #CFCFD0;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent; }
  form.list-view-filter-form .filter-fields .select-wrapper:after {
    border-top: 6px solid #A3A1AA; }
  form.list-view-filter-form .filter-fields select {
    padding: 7px 5px;
    font-style: italic;
    color: #4E4E4E; }
  form.list-view-filter-form .filter-fields input::placeholder {
    color: #CFCFD0;
    font-style: italic; }
  form.list-view-filter-form .filter-fields button.filter-bn {
    margin-left: 20px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 13px;
    padding: 5px 9px;
    border: 1px solid #8D959A;
    color: #fff;
    margin-top: 24px;
    font-family: 'Ubuntu';
    background-color: #8CC739;
    cursor: pointer;
    border-radius: 3px; }
