.payment-method-container {
  padding-left: 25%;
  padding-right: 25%; }
  .payment-method-container .payments-logos-container {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 4px solid gray; }
    .payment-method-container .payments-logos-container .payments-logo {
      vertical-align: middle;
      max-width: 200px;
      max-height: 150px;
      width: auto;
      height: auto;
      margin: 10px; }
  .payment-method-container .payments-message-container {
    text-align: center;
    padding: 35px 20px 15px 10px; }
  .payment-method-container .payments-fee-message-container {
    text-align: center;
    padding: 10px 20px 15px 20px; }
  .payment-method-container .payments-purchase-btn,
  .payment-method-container .payments-continue-btn {
    margin-top: 10px;
    background-color: #8cc739;
    border-radius: 2.5px;
    color: white;
    border: none;
    min-width: 100px;
    min-height: 30px; }
  .payment-method-container .payments-purchase-btn:hover,
  .payment-method-container .payments-continue-btn:hover {
    background-color: #70ab1d; }
  .payment-method-container .payments-continue-btn {
    float: right; }
  .payment-method-container [data-braintree-id="toggle"] {
    display: none; }
