.filter-actions {
  align-items: flex-end;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  min-width: 250px; }
  .filter-actions .list {
    margin: 0; }
    .filter-actions .list .list-item {
      display: inline-block; }
      .filter-actions .list .list-item .filter-button {
        background-color: #8CC739;
        border: none;
        color: #fff;
        cursor: pointer;
        font-size: 13px;
        font-weight: 700;
        min-width: 140px;
        padding: 10px 25px;
        text-transform: uppercase; }
        .filter-actions .list .list-item .filter-button:hover {
          text-decoration: none; }
      .filter-actions .list .list-item .disabled-button {
        background-color: #8D959A;
        cursor: unset; }
