.scorecard {
  background-color: #FFFFFF;
  border: 2px solid #dee2e6;
  padding: 30px 50px; }
  .scorecard .header {
    font-size: 35px;
    font-weight: bold; }
  .scorecard .scorecard-mention {
    color: #085DAD;
    font-weight: bold;
    font-size: 24px;
    display: flex;
    justify-content: center;
    text-decoration: underline;
    text-underline-position: under; }
  .scorecard .bold-label {
    font-size: 17px;
    font-weight: bold; }
  .scorecard .commentary {
    margin: 20px 0; }
    .scorecard .commentary .commentary-text {
      font-size: 14px; }
  .scorecard .measures {
    margin-top: 30px; }
  .scorecard .concept-value {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: center; }
    .scorecard .concept-value .concept-flag {
      font-size: 30px;
      margin-left: 25px;
      position: absolute;
      top: -22px; }
      .scorecard .concept-value .concept-flag.green {
        color: #008000; }
      .scorecard .concept-value .concept-flag.yellow {
        color: #ffff00; }
      .scorecard .concept-value .concept-flag.red {
        color: #ff0000; }
