@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?iprl53");
  src: url("fonts/icomoon.eot?iprl53#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?iprl53") format("truetype"), url("fonts/icomoon.woff?iprl53") format("woff"), url("fonts/icomoon.svg?iprl53#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon';
  color: #FFFFFF; }

.icon-add:before {
  content: '+';
  font-size: 19px;
  font-weight: 600 !important;
  line-height: 4px; }

/* If you were provided with a new set of icons, replace all the old icon classes definitions (below this line) with the new ones */
.icon-search_icon:before {
  content: "\e918";
  color: #fff; }

.icon-camera:before {
  content: "\e900"; }

.icon-checkbox:before {
  content: "\e901"; }

.icon-company:before {
  content: "\e902"; }

.icon-database:before {
  content: "\e903"; }

.icon-date:before {
  content: "\e904"; }

.icon-decrease-indent:before {
  content: "\e905"; }

.icon-delete:before {
  content: "\e906"; }

.icon-duplicate:before {
  content: "\e907"; }

.icon-edit:before {
  content: "\e908"; }

.icon-if_password_2639882:before {
  content: "\e909"; }

.icon-if_upload_103738:before {
  content: "\e90a"; }

.icon-increase-indent:before {
  content: "\e90b"; }

.icon-link:before {
  content: "\e90c"; }

.icon-log:before {
  content: "\e90d"; }

.icon-login-door:before {
  content: "\e90e";
  color: #fff; }

.icon-notifications:before {
  content: "\e90f";
  color: #2e5965; }

.icon-numbered-list:before {
  content: "\e910"; }

.icon-personal_info:before {
  content: "\e911"; }

.icon-quick_view:before {
  content: "\e912"; }

.icon-record:before {
  content: "\e913"; }

.icon-save:before {
  content: "\e914"; }

.return-icon:before {
  content: "\2190"; }

.icon-send:before {
  content: "\e915";
  color: #515151; }

.icon-settings:before {
  content: "\e916"; }

.icon-workflow:before {
  content: "\e917"; }
