.answers-modal header {
  color: #FFFFFF;
  font-size: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #7ED0BC;
  margin-bottom: 10px; }

.answers-modal label {
  color: #2E5965;
  font-weight: 500;
  margin-right: 20px;
  padding-left: 20px; }

.answers-modal .subtitle {
  color: black;
  font-weight: 500;
  margin-left: 20px; }

.answers-modal .answer-data {
  color: black;
  align-items: right; }

.answers-modal .action-button {
  margin-left: 20px; }
