.registration-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .registration-container .registration-title {
    text-align: center; }
  .registration-container .missing-data-container {
    margin-top: 60px;
    display: flex;
    justify-content: center; }
  .registration-container .entity-info-form {
    padding: 0px; }
    .registration-container .entity-info-form .filter-form-field-wrapper {
      display: inline-flex;
      margin-right: 15px;
      padding: 3px; }
      .registration-container .entity-info-form .filter-form-field-wrapper label {
        margin-bottom: 0;
        margin-right: 10px;
        padding-top: 5px;
        color: #2E5965;
        font-weight: bold; }
      .registration-container .entity-info-form .filter-form-field-wrapper .form-removable-container .value-container {
        width: 250px; }
      .registration-container .entity-info-form .filter-form-field-wrapper .field-validation-message {
        display: none; }
      .registration-container .entity-info-form .filter-form-field-wrapper .type-ahead-search-bar-container {
        margin: 0 0 0 20px;
        width: 250px; }
        .registration-container .entity-info-form .filter-form-field-wrapper .type-ahead-search-bar-container .type-ahead-search-bar {
          padding: 0; }
          .registration-container .entity-info-form .filter-form-field-wrapper .type-ahead-search-bar-container .type-ahead-search-bar.focused {
            border-radius: 5px 5px 5px 5px; }
          .registration-container .entity-info-form .filter-form-field-wrapper .type-ahead-search-bar-container .type-ahead-search-bar .input-container {
            border-radius: 5px 5px 5px 5px !important;
            padding: 7px 30px 7px 25px !important; }
  .registration-container hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .registration-container input[type="text"], .registration-container select {
    display: block;
    width: 250px !important;
    padding: .175rem .35rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    float: right; }
  .registration-container .sm-input {
    width: 120px !important; }
  .registration-container label {
    font-size: 14px;
    font-weight: 700;
    color: #2E5965;
    display: inline-block;
    width: 100%;
    margin: 0;
    position: relative; }
