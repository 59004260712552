.projectInsureds-list table th {
  white-space: nowrap; }

.projectInsureds-list .compliance-status {
  border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1em;
  height: 1em; }
  .projectInsureds-list .compliance-status.compliant {
    background-color: #8CC739; }
  .projectInsureds-list .compliance-status.minor {
    background-color: #FEEC0F; }
  .projectInsureds-list .compliance-status.non-compliant {
    background-color: #f04c41; }
  .projectInsureds-list .compliance-status.escalated {
    background-color: #FEAB0F; }
  .projectInsureds-list .compliance-status.pending {
    background-color: lightgray; }
  .projectInsureds-list .compliance-status.on-hold {
    background-color: #2BAFF4; }
