.register-sidebar {
  padding-bottom: 50px; }
  .register-sidebar + .register-main {
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px; }

.vertikal-img {
  display: block;
  margin: 0 auto 40px;
  max-width: 100%; }

.side-by-side-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; }

.logos-wrapper {
  text-align: center;
  margin-top: 250px; }

.register-sidebar-img {
  margin: 0 10px;
  width: auto;
  max-width: 40%;
  height: auto; }

@media (max-width: 767px) {
  .logos-wrapper {
    margin-top: 50px; } }

@media (min-width: 768px) {
  .logos-wrapper {
    margin-top: 150px; } }

p.register-slogan {
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  margin-top: 20px; }

h1.register-title {
  color: #085DAD;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 22px; }

p.register-instr {
  text-align: center;
  margin-bottom: 0px;
  font-size: 13px;
  color: #8D959A;
  letter-spacing: 0.3px; }

.register-main-header {
  margin-bottom: 30px; }

.tab-wrapper {
  width: 50%; }

.tabs-frame .nav-tabs {
  border-bottom: 0px; }
  .tabs-frame .nav-tabs a.step-nav {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 13px;
    color: #8D959A;
    border-top: 1px solid #8D959A;
    border-bottom: 1px solid #8D959A;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center; }
    .tabs-frame .nav-tabs a.step-nav.active {
      color: #085DAD;
      border-top: 2px solid #085DAD;
      border-bottom: 2px solid #085DAD; }

.tabs-frame .step-col-left {
  padding-left: 0; }

.tabs-frame .tabs-frame .step-col-right {
  padding-right: 0; }

.register-field-wrapper {
  padding-top: 20px;
  position: relative;
  min-height: 85px; }

.css-151xaom-placeholder {
  color: black !important;
  font-size: 13px;
  font-style: italic; }

span.field-validation-message {
  position: absolute;
  font-size: 10px;
  color: #FF0000;
  right: 0;
  left: auto;
  top: 27px;
  margin: 0; }

.register-main input, .register-main select {
  width: 100%;
  border: 1px solid #BABBBD;
  border-radius: 3px;
  padding: 7px 10px;
  font-size: 13px; }

.register-main input::placeholder {
  color: #CFCFD0;
  font-style: italic; }

.register-main select {
  padding: 7px 10px;
  font-style: italic;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  z-index: 0; }

.register-main label {
  color: #085DAD;
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 14px;
  letter-spacing: 0.3px; }
  .register-main label.required:after {
    content: ' *';
    color: #FF0000; }

.register-select-wrapper {
  position: relative; }
  .register-select-wrapper:after {
    content: 'a';
    position: absolute;
    top: 13px;
    right: 10px;
    border-top: 6px solid #085DAD;
    color: transparent;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    width: 0;
    z-index: -1; }
  .register-select-wrapper .select-error-message {
    top: -22px !important; }

div[class*=select-] {
  font-size: .8rem;
  font-style: italic; }

.select-title-error > div,
.select-trade-error > div,
.select-secondarytrade-error > div,
.select-tertiarytrade-error > div,
.select-quaternaryTrade-error > div,
.select-quinaryTrade-error > div,
.select-country-error > div,
.select-state-error > div {
  border-color: #FF0000; }

.step-button {
  margin-left: -15px;
  margin-top: 45px;
  position: relative; }

input.pretty-checkbox.agree {
  border: 1px solid #BABBBD;
  border-radius: 3px; }
  input.pretty-checkbox.agree:checked + span.check:after {
    top: 2px !important;
    color: #BABBBD !important; }
  input.pretty-checkbox.agree.invalid {
    border: 1px solid #FF0000; }

.agree-checkbox {
  position: absolute;
  top: -90px;
  display: inline-block;
  width: 100%; }
  .agree-checkbox p a {
    color: #085DAD; }

label[for='agree'] {
  cursor: pointer;
  position: relative;
  color: #8D959A;
  font-size: 13px;
  user-select: none; }
  label[for='agree'] .label {
    vertical-align: text-bottom;
    margin-left: 12px; }

.state-field span.field-validation-message {
  top: -20px; }

.agreement-container {
  padding-top: 40px; }
  .agreement-container .agreement-label {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #8D959A; }
    .agreement-container .agreement-label a {
      color: #085DAD; }

.step-button p {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #8D959A; }

.step-button .error-msj {
  position: absolute;
  top: -153%;
  width: 100%;
  text-align: left;
  font-size: 10px;
  color: #FF0000; }

.register-main .register-field-wrapper input.invalid, .register-main .register-field-wrapper select.invalid, .register-main .register-field-wrapper .react-select__control.invalid {
  border: 1px solid #FF0000; }

.error-register {
  position: absolute;
  font-size: 10px;
  color: #FF0000;
  top: -23px; }

.register-main .tab-pane .register-field-wrapper label::before {
  content: ''; }

.wiz-continue-btn-disabled {
  filter: opacity(0.3); }

.register-modal-error {
  position: relative;
  width: 100%;
  font-size: 0.75rem;
  text-align: center;
  pointer-events: none;
  color: red;
  margin-top: 3rem; }

html > body > #root > .container-fluid {
  height: 100%; }

html > body > #root > .container-fluid .row.pq-register {
  height: 100%; }

body.swal2-height-auto {
  height: 100% !important; }

@media (max-width: 767px) {
  .logos-wrapper {
    margin-top: 100px; }
  .register-sidebar {
    height: auto; }
  .tabs-frame .step-col-left, .tabs-frame .step-col-right {
    padding: 0; } }

@media (min-width: 768px) {
  .row .register-main {
    height: auto !important;
    overflow-y: hidden !important; } }
