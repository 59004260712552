.modal-body {
  margin: 20px 0 0 0; }

.send-template-header {
  background: #2E5965;
  color: white;
  font-weight: 700;
  font-size: 13px;
  font-family: 'Ubuntu', sans-serif !important; }

.send-template-header p {
  padding: 30px 0 30px 0;
  width: 80%;
  margin: 0 auto;
  text-align: center; }

.send-template-button {
  text-transform: uppercase;
  border-bottom: 4px solid #2E5965 !important;
  font-family: 'Ubuntu', sans-serif;
  font-size: 13px;
  color: #FFFFFF;
  padding: 11px 45px;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  background: -webkit-gradient(linear, left top, right bottom, from(#7ED0BC), to(#29AEA2));
  background: -webkit-linear-gradient(top left, #7ED0BC, #29AEA2);
  background: -o-linear-gradient(top left, #7ED0BC, #29AEA2);
  background: linear-gradient(to bottom right, #7ED0BC, #29AEA2); }

.cancel-button {
  background: white;
  color: #2E5965 !important;
  text-transform: uppercase;
  font-size: 14px;
  font-style: italic;
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
  margin-right: 20px;
  text-align: right; }

.cancel-button:hover {
  text-decoration: underline !important; }

.select-container p {
  color: #2E5965;
  text-align: center;
  font-weight: 500;
  margin-bottom: 3px; }

.select-container select {
  color: #2E5965;
  padding: 8px 7px 6px;
  border-radius: 2px;
  width: 80%;
  margin-left: 10%;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 20px; }

.template-name {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
  color: #7ED0BC; }

.template-name span {
  color: #2E5965; }
