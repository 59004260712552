.change-basic-data-modal .first-row {
  display: flex;
  justify-content: center; }

.change-basic-data-modal .change-status-modal-header {
  color: #FFFFFF;
  font-size: 20px; }

.change-basic-data-modal .change-status-content {
  font-size: 15px;
  padding: 30px 50px; }
  .change-basic-data-modal .change-status-content .statusContainer {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px; }
    .change-basic-data-modal .change-status-content .statusContainer label {
      color: #2E5965;
      font-weight: 500;
      margin-right: 20px; }
    .change-basic-data-modal .change-status-content .statusContainer .sc-fields-list {
      text-align: center; }
      .change-basic-data-modal .change-status-content .statusContainer .sc-fields-list .sc-row-field-wrapper {
        text-align: left;
        width: 30%; }
        .change-basic-data-modal .change-status-content .statusContainer .sc-fields-list .sc-row-field-wrapper input {
          border-radius: 2px;
          border: 1px solid #CFCFD0;
          color: #4E4E4E;
          display: inline-block;
          font-size: 12px;
          width: 100%; }
        .change-basic-data-modal .change-status-content .statusContainer .sc-fields-list .sc-row-field-wrapper .field-validation-message {
          right: 16px;
          top: 73px; }
  .change-basic-data-modal .change-status-content .action-button {
    margin-left: 20px; }
