.remove-modal-padding-margin {
  padding: 0 !important;
  margin: 0; }

.status-change-modal {
  box-shadow: 1px 1px 3px #7ED0BC, -1px -1px 3px #7ED0BC; }
  .status-change-modal .status-change-modal-header {
    background: linear-gradient(to right, #7ED0BC, #29AEA2);
    color: #2E5965;
    font-size: 17px;
    font-weight: bold;
    height: 65px;
    padding: 25px; }
  .status-change-modal .status-change-content {
    font-size: 15px;
    padding: 50px; }
    .status-change-modal .status-change-content .status-change-label {
      color: #2E5965;
      font-weight: bold;
      font-size: 14px; }
  .status-change-modal label {
    color: #2E5965;
    font-weight: 500;
    margin-right: 20px; }
  .status-change-modal .ml-40 {
    margin-top: 10px;
    margin-left: 40px; }
  .status-change-modal .ml-130 {
    margin-left: 138px;
    padding-top: 15px; }
