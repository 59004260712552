.row .form-link-main {
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px; }

@media (min-width: 768px) {
  .row .form-link-main {
    overflow-y: scroll;
    height: stretch; } }

.form-table-of-contents {
  padding: 50px 40px 0 40px;
  height: stretch;
  overflow-y: scroll; }
  .form-table-of-contents .table-of-contents-title {
    color: white;
    text-align: center;
    margin-bottom: 30px;
    font-size: 25px; }
  .form-table-of-contents .table-of-contents-item {
    color: white;
    font-size: 18px;
    cursor: pointer; }
  .form-table-of-contents .page-item {
    margin-top: 20px; }
  .form-table-of-contents .section-item {
    margin-top: 10px;
    margin-left: 15px; }
  .form-table-of-contents .sub-section-item {
    margin-top: 10px;
    margin-left: 30px; }

@page {
  size: 100%;
  margin: 20mm 0mm 20mm 43mm; }

@media print {
  #formLegacy {
    width: 100%;
    height: 100%; } }
