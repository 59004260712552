.currency-input-field {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .currency-input-field .currency-input {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 380px;
    gap: 5px; }
    .currency-input-field .currency-input .label {
      text-align: justify;
      padding-left: 15px;
      width: 100px;
      line-height: 18px; }
    .currency-input-field .currency-input .field-validation-message {
      margin: 0;
      width: 240px; }
      .currency-input-field .currency-input .field-validation-message input {
        padding: 5px;
        outline: none;
        border: 2px solid #080f11;
        border-radius: 4px;
        width: 100%;
        border-color: #a8cdd7;
        background-color: #eef5f7; }
      .currency-input-field .currency-input .field-validation-message input:focus {
        outline: none; }
      .currency-input-field .currency-input .field-validation-message p {
        width: 100%;
        color: #e74c3c;
        font-weight: 400;
        font-style: normal;
        margin-top: 7px;
        margin-bottom: 0;
        line-height: 12px; }

/* Type Ahead Form Field */
.form-type-ahead-container .type-ahead-search-bar-container {
  margin: 0;
  height: auto; }
  .form-type-ahead-container .type-ahead-search-bar-container .type-ahead-search-bar {
    padding: 0; }
    .form-type-ahead-container .type-ahead-search-bar-container .type-ahead-search-bar .input-container.grey-border {
      border: 1px solid #CFCFD0;
      border-radius: 0;
      padding: 0 5px 0 0; }
      .form-type-ahead-container .type-ahead-search-bar-container .type-ahead-search-bar .input-container.grey-border input {
        width: calc(100% - 20px);
        border: none; }
    .form-type-ahead-container .type-ahead-search-bar-container .type-ahead-search-bar .results-container {
      font-size: 12px; }
      .form-type-ahead-container .type-ahead-search-bar-container .type-ahead-search-bar .results-container .spinner-wrapper .spinner {
        width: 30px; }
  .form-type-ahead-container .type-ahead-search-bar-container .type-ahead-search-bar.focused .input-container.grey-border {
    padding: 8px 30px 8px 25px;
    border: none; }

.form-type-ahead-container.invalid .type-ahead-search-bar-container .type-ahead-search-bar .input-container.grey-border {
  border-color: red; }

/* Removable Form Field */
.form-removable-container .value-container {
  display: flex;
  height: 34px;
  align-items: center; }
  .form-removable-container .value-container .value-label {
    font-size: 14px; }
  .form-removable-container .value-container .remove-btn {
    width: auto !important;
    margin-left: 10px;
    cursor: pointer; }
    .form-removable-container .value-container .remove-btn:before {
      font-weight: bold; }

.custom-checkbox-button {
  width: fit-content !important;
  display: flex;
  align-items: center;
  gap: 5px; }
  .custom-checkbox-button .custom-checkbox {
    margin-top: -1rem; }
  .custom-checkbox-button label {
    padding-top: 0.5rem;
    line-height: 16px;
    color: #212529;
    font-weight: 400;
    font-size: 14px !important; }

.cursor-not-allowed {
  cursor: not-allowed !important; }

.cursor-pointer {
  cursor: pointer !important; }
