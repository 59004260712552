.container-fluid.mail-composer {
  padding-right: 30px;
  padding-left: 30px; }
  .container-fluid.mail-composer .editor-column {
    width: 100%; }
  .container-fluid.mail-composer .mailHeader {
    margin: 15px 0; }
    .container-fluid.mail-composer .mailHeader .col-md-6 {
      padding: 0 30px 0 0; }
    .container-fluid.mail-composer .mailHeader .hcContainer,
    .container-fluid.mail-composer .mailHeader .subjectContainer,
    .container-fluid.mail-composer .mailHeader .recipientsContainer,
    .container-fluid.mail-composer .mailHeader .scContainer,
    .container-fluid.mail-composer .mailHeader .dueDateContainer,
    .container-fluid.mail-composer .mailHeader .taskDetailContainer {
      margin-top: 5px; }
      .container-fluid.mail-composer .mailHeader .hcContainer label,
      .container-fluid.mail-composer .mailHeader .subjectContainer label,
      .container-fluid.mail-composer .mailHeader .recipientsContainer label,
      .container-fluid.mail-composer .mailHeader .scContainer label,
      .container-fluid.mail-composer .mailHeader .dueDateContainer label,
      .container-fluid.mail-composer .mailHeader .taskDetailContainer label {
        width: auto;
        margin: 0;
        font-size: 13px;
        color: #2E5965;
        font-weight: 500; }
      .container-fluid.mail-composer .mailHeader .hcContainer input,
      .container-fluid.mail-composer .mailHeader .subjectContainer input,
      .container-fluid.mail-composer .mailHeader .recipientsContainer input,
      .container-fluid.mail-composer .mailHeader .scContainer input,
      .container-fluid.mail-composer .mailHeader .dueDateContainer input,
      .container-fluid.mail-composer .mailHeader .taskDetailContainer input {
        width: 100%;
        height: 32px;
        color: #4E4E4E;
        padding: 6px 5px;
        font-size: 12px;
        border-radius: 3px;
        border: 1px solid #CFCFD0;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: transparent; }
      .container-fluid.mail-composer .mailHeader .hcContainer .mandatory::after,
      .container-fluid.mail-composer .mailHeader .subjectContainer .mandatory::after,
      .container-fluid.mail-composer .mailHeader .recipientsContainer .mandatory::after,
      .container-fluid.mail-composer .mailHeader .scContainer .mandatory::after,
      .container-fluid.mail-composer .mailHeader .dueDateContainer .mandatory::after,
      .container-fluid.mail-composer .mailHeader .taskDetailContainer .mandatory::after {
        pointer-events: none;
        position: relative;
        float: right;
        right: -5px;
        color: red;
        content: "*"; }
    .container-fluid.mail-composer .mailHeader .hcContainer select,
    .container-fluid.mail-composer .mailHeader .scContainer select {
      width: 50%;
      height: 32px; }
    .container-fluid.mail-composer .mailHeader .dueDateContainer input {
      width: 50%;
      min-width: 200px; }
    .container-fluid.mail-composer .mailHeader .recipientsContainer .suggestions-container {
      position: absolute;
      background-color: white;
      z-index: 1;
      padding: 5px 10px;
      border: solid 1px lightslategrey; }
      .container-fluid.mail-composer .mailHeader .recipientsContainer .suggestions-container .recipientSuggestion {
        margin: 5px 0; }
    .container-fluid.mail-composer .mailHeader .recipientsContainer .emails-container {
      height: auto !important;
      display: inline-block;
      margin: 5px 0 0 0;
      width: 100%; }
      .container-fluid.mail-composer .mailHeader .recipientsContainer .emails-container .used-email {
        background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
        color: #FFF;
        padding-left: 6px;
        border-radius: 3px;
        margin-right: 8px;
        float: left;
        margin-bottom: 5px;
        overflow-wrap: break-word;
        max-width: 500px; }
        .container-fluid.mail-composer .mailHeader .recipientsContainer .emails-container .used-email .clear-email {
          font-size: 8px;
          font-weight: bold;
          position: relative;
          top: -2px;
          margin-left: 5px;
          padding-right: 5px; }
  .container-fluid.mail-composer .mailFooter {
    height: 80px;
    width: 100%;
    background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 150px;
    margin-top: 20px; }
    .container-fluid.mail-composer .mailFooter .footerBtn {
      min-width: 200px;
      text-transform: uppercase; }
