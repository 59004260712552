.insureds-list .insureds-list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap; }

.insured-modal {
  padding-top: 5px; }
  .insured-modal a.icon-quick_view {
    color: #8CC739 !important;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400; }
  .insured-modal a:not([href]) {
    color: inherit;
    text-decoration: none; }
  .insured-modal a.icon-quick_view:before {
    color: #8CC739 !important;
    content: "\e912";
    margin: 5px; }
