.profile-view-wrapper h1.list-view-title.hc {
  position: absolute;
  top: 18px;
  left: 36px; }

.tab-frame {
  width: 100%; }

.list-view section.list-view-header.templates-view-header {
  margin-bottom: 5px; }

.list-view section.list-view-table.templates-list {
  box-shadow: 0 0 0 transparent !important;
  border-top-width: 1px; }
  .list-view section.list-view-table.templates-list .table-wrapper th:first-child {
    padding-left: 11px; }
  .list-view section.list-view-table.templates-list .table-wrapper td:first-child {
    padding-left: 23px; }

a.icon-edit.cell-table-link > .quickview-popover {
  display: none;
  width: 200px;
  top: 10px !important;
  left: -60px !important; }

a.icon-edit.cell-table-link:hover > .quickview-popover {
  display: block; }

.quickview-popover {
  color: #A3A1AA;
  margin-top: 10px;
  box-shadow: 0px 0px 46px -12px #2E5965; }

.quickview-popover.popover > .popover-content:after, .quickview-popover.popover > .popover-content:before {
  content: 'a';
  left: 26%;
  position: absolute;
  top: -27px;
  color: transparent;
  width: 0;
  display: inline-block;
  border-bottom: 8px solid #FFFFFF;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent; }

.quickview-popover.popover > .popover-content:before {
  border-bottom: 8px solid #ccc;
  top: -28.5px; }

.form-options {
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px; }
  .form-options li {
    color: #2E5965;
    font-weight: 500;
    left: 0;
    margin-top: 10px;
    min-height: 30px; }
    .form-options li:hover {
      background-color: #F7F7F7; }

.item-field {
  width: 80%;
  height: 30px;
  font-size: 13px;
  font-weight: 500;
  padding-left: 5px;
  text-transform: uppercase;
  background-color: #F7F7F7;
  color: #4A4A4A;
  border: 1px solid #8D959A;
  border-radius: 3px;
  outline: none; }

.search-field {
  width: 70%;
  height: 35px;
  padding-left: 10px;
  font-style: italic;
  border-radius: 5px;
  font-size: 12px;
  border: 1.5px solid #8D959A;
  background-color: #F7F7F7;
  outline: none;
  padding-right: 30px; }

.search-field-wrapper ul {
  width: 100%; }
  .search-field-wrapper ul li {
    width: 100%; }
    .search-field-wrapper ul li .icon-search_icon {
      position: absolute;
      z-index: 1;
      right: 10px;
      top: 10px; }

.terminology {
  text-transform: uppercase;
  color: #4A4A4A; }

div.col-sm-6.message-row {
  display: flex; }

[class$="-message"] {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
  margin-left: 15px; }

.success-message {
  color: #29AEA2; }

.error-message {
  color: #D0021B; }

.col-sm-6 .save-lng-btn {
  min-width: 206px;
  height: 37px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #8CC739;
  border-radius: 2px;
  padding: 0 30px;
  outline: none;
  border: 1px solid #2E5965; }
  .col-sm-6 .save-lng-btn .icon-save:before {
    font-size: 18px;
    margin-right: 5px;
    margin-top: 10px; }

@media screen and (max-width: 1371px) {
  .no-overlapping {
    margin-bottom: 20px; } }

.project-view-container {
  background-color: #FFFFFF; }
  .project-view-container .row {
    margin: 40px; }
  .project-view-container .divider {
    width: 100%;
    height: 7px;
    background: linear-gradient(to bottom right, #7ED0BC, #29AEA2); }
  .project-view-container .project-view-title {
    font-size: 24px;
    font-weight: bolder;
    color: #2E5965; }
  .project-view-container .stats span {
    font-size: 20px;
    font-weight: bolder;
    color: #2E5965; }
  .project-view-container .stats p {
    color: #8D959A;
    font-size: 11px;
    text-transform: uppercase; }
  .project-view-container .cards {
    height: 300px;
    border: 2px solid #ebad49;
    border-radius: 5px; }
    .project-view-container .cards .card-title {
      width: 100%;
      text-align: center;
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 2px solid #ebad49; }
      .project-view-container .cards .card-title span {
        text-transform: uppercase;
        font-weight: bolder;
        color: #2E5965; }
    .project-view-container .cards .card-content {
      padding-left: 10px; }
      .project-view-container .cards .card-content span {
        font-weight: bolder;
        color: #2E5965; }
      .project-view-container .cards .card-content p {
        text-transform: uppercase;
        color: #8D959A;
        font-size: 12px; }

.stick-left {
  padding-left: 10px;
  padding-right: 1px; }

.add-contract-form .add-contract-fields {
  height: auto;
  min-height: 250px;
  padding: 0 0 0 20px !important; }
  .add-contract-form .add-contract-fields .remove-contract-button {
    cursor: pointer;
    margin: 23px 0; }
  .add-contract-form .add-contract-fields .add-contract-button {
    cursor: pointer;
    margin-top: 25px; }
  .add-contract-form .add-contract-fields .add-contract-rows {
    max-height: 250px;
    overflow-y: auto; }
    .add-contract-form .add-contract-fields .add-contract-rows .row {
      justify-content: center;
      margin: 0; }

.add-contract-form .wiz-buttons {
  margin: 25px 50px;
  position: static;
  width: auto; }

.project-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 360px  !important;
  padding: 0 0 70px 20px !important; }
  .project-form .admin-form-field-wrapper {
    height: 50px; }
    .project-form .admin-form-field-wrapper .label {
      font-size: 16px !important;
      text-align: right;
      width: auto !important; }

.trade-categories-form .table-with-pagination {
  padding-bottom: 50px !important; }

.trade-categories-form .save-button {
  border-bottom: 3px solid #2E5965 !important; }

.trade-categories-form .error-message {
  font-size: 12px;
  color: #FF0000; }

.trade-categories-form .orderInput {
  background: #7ED0BC;
  border: 2px solid #29AEA2; }
