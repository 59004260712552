.country-state-filter {
  font-family: 'Ubuntu', sans-serif !important;
  color: rgba(0, 0, 0, 0.54); }
  .country-state-filter .country {
    padding-left: 15px !important;
    padding-right: 4px !important; }
  .country-state-filter .state {
    padding-left: 4px !important;
    padding-right: 15px !important; }
  .country-state-filter .country-filter-field label {
    margin-bottom: 0 !important; }
  .country-state-filter .country-filter-field .select-country .vk__control, .country-state-filter .country-filter-field .select-state .vk__control {
    background: transparent !important;
    border: none !important;
    border-radius: unset !important;
    box-shadow: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54) !important; }
  .country-state-filter .country-filter-field .select-country .vk__menu, .country-state-filter .country-filter-field .select-state .vk__menu {
    position: relative !important; }
  .country-state-filter .country-filter-field .select-country .vk__value-container--has-value, .country-state-filter .country-filter-field .select-country .vk__value-container, .country-state-filter .country-filter-field .select-state .vk__value-container--has-value, .country-state-filter .country-filter-field .select-state .vk__value-container {
    padding: 0 !important; }
  .country-state-filter .country-filter-field .select-country .vk__placeholder, .country-state-filter .country-filter-field .select-state .vk__placeholder {
    color: rgba(0, 0, 0, 0.54); }
