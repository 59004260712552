.wiz-wrapper header.small {
  padding-top: 10px;
  padding-bottom: 10px; }

.projects-list .projects-list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap; }

.projects-list .projects-table table > tbody > tr > td:nth-child(3) {
  line-height: initial; }

.projects-list .projects-table table > tbody > tr > td:nth-child(6) {
  line-height: initial; }

.projects-list .projects-table .status-cell.inprogress::before {
  color: orange; }

.projects-list .projects-table .status-cell.completed::before {
  color: #29AEA2; }

.projects-list .projects-table .status-cell.unassigned::before {
  color: red; }

.archived-text {
  color: #F00; }

.unarchived-text {
  color: #8CC739; }

.copyToClip {
  color: #8CC739;
  position: relative;
  cursor: pointer; }
  .copyToClip:hover {
    text-decoration: underline; }

.project-header {
  display: flex !important;
  justify-content: space-between !important; }
