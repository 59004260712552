.add-item-modal.add-sc .modal-dialog {
  max-width: 89.9%; }

.add-sc-modal header {
  background: #2E5965;
  margin-bottom: 40px;
  text-align: center;
  padding: 40px; }
  .add-sc-modal header h1 {
    color: #FFFFFF;
    font-size: 19px;
    font-weight: 400;
    text-transform: uppercase; }

.add-sc-modal .modalSearchWrapper {
  width: 100%;
  position: relative;
  top: -40px; }
  .add-sc-modal .modalSearchWrapper .searchWrapper {
    padding: 0 !important;
    width: 350px;
    display: inline-block;
    position: absolute;
    margin: auto !important;
    right: 0;
    left: 0;
    height: 32px; }
    .add-sc-modal .modalSearchWrapper .searchWrapper .searchBtn {
      background-color: #8CC739;
      border-style: none;
      color: white;
      float: right;
      margin-top: 4px;
      height: 32px;
      width: 70px !important;
      cursor: pointer;
      border-radius: 3px; }
    .add-sc-modal .modalSearchWrapper .searchWrapper .searchBtn:active {
      background-color: darkgreen; }
    .add-sc-modal .modalSearchWrapper .searchWrapper .search_subcontractors {
      color: #4E4E4E;
      padding: 6px 5px;
      margin-top: 4px;
      font-size: 12px;
      height: 32px;
      width: 280px;
      border-radius: 3px;
      border: 1px solid #CFCFD0;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: white; }

label.choose-hc {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 200;
  font-style: italic;
  margin-top: 30px; }

.add-sc-tabs {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0; }
  .add-sc-tabs li {
    display: inline-block;
    width: 50%; }
    .add-sc-tabs li a.addsc-tab-link {
      display: inline-block;
      width: 100%;
      text-align: center;
      color: #2E5965;
      background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
      padding: 10px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500; }
      .add-sc-tabs li a.addsc-tab-link.active {
        background: #E3E7EF;
        border: 1px solid #4E4E4E;
        box-sizing: border-box;
        padding: 9px;
        border-bottom: 0; }

.addsc-tab-body .admin-form-field-wrapper {
  padding-right: 0 !important; }

.upload-file-wrapper {
  text-align: center;
  margin-bottom: 40px; }
  .upload-file-wrapper span.field-validation-message {
    top: 5px !important;
    right: 180px !important; }
  .upload-file-wrapper input[type=file] {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
  .upload-file-wrapper label.csv {
    color: #666666 !important;
    margin-top: 40px;
    border: 2px dashed #29AEA2;
    text-align: center;
    background: #E3E7EF;
    padding: 20px;
    width: 70% !important;
    display: inline-block !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    font-style: italic;
    margin-top: 40px !important;
    cursor: pointer; }

.addsc-tab-bodies .tab-body {
  display: none; }
  .addsc-tab-bodies .tab-body.active {
    display: block; }

.addsc-form-tab-buttons {
  padding: 50px 35px 40px;
  text-align: right; }
  .addsc-form-tab-buttons a {
    color: #2E5965;
    text-transform: uppercase;
    font-style: italic;
    margin-right: 20px;
    font-size: 12px;
    cursor: pointer; }
  .addsc-form-tab-buttons button {
    text-transform: uppercase;
    border-bottom: 3px solid #2E5965 !important; }

.sc-fields-list {
  margin: 0;
  padding: 0; }
  .sc-fields-list li {
    margin: 0;
    padding: 20px 30px 0px 30px;
    text-align: center; }

.sc-row-field-wrapper {
  display: inline-block;
  width: 15%;
  padding-right: 15px;
  position: relative; }
  .sc-row-field-wrapper label {
    margin-bottom: 10px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    text-align: left !important; }
  .sc-row-field-wrapper input {
    background: #E3E7EF !important;
    padding: 11px 7px 8px !important; }
  .sc-row-field-wrapper span.error-sc-field {
    position: absolute;
    right: 17px;
    color: red;
    font-size: 10px; }

#list {
  align-content: center !important; }

.flush {
  width: 24% !important; }

.formItem {
  display: inline-block;
  width: 85% !important; }
  .formItem .formSelect {
    height: 100% !important;
    padding: 11px 7px 8px !important; }

.remove-row-button {
  display: inline-block;
  position: relative !important;
  height: 100% !important;
  width: 15% !important;
  bottom: -9px !important;
  float: right; }

.upload-file-form {
  padding-top: 20px; }

.sc-fields-list .add-new-sc-row-wrapper {
  text-align: center;
  padding-top: 60px; }
  .sc-fields-list .add-new-sc-row-wrapper .add-new-sc-row {
    display: inline-block;
    width: 60%;
    color: #29AEA2;
    background: #E3E7EF;
    border: 2px dashed #29AEA2;
    padding: 15px;
    text-transform: uppercase; }

.add-sc-select {
  position: relative;
  text-align: center;
  width: 25%;
  margin: auto; }
  .add-sc-select.select-wrapper:after {
    border-top: 6px solid #2E5965;
    z-index: 2; }
  .add-sc-select select {
    appearance: none;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 14px;
    padding: 5px 10px;
    color: #2E5965; }

.filter-sc-list .admin-form-field-wrapper {
  padding-right: 15px; }
  .filter-sc-list .admin-form-field-wrapper.max-aggregate-field {
    padding-right: 0; }

.filter-sc-list .sc-filter-row {
  margin-bottom: 20px; }

.sc-filter-bn {
  text-align: right;
  margin-bottom: -20px; }

.closeBtn {
  background-color: #8CC739;
  border-style: none;
  color: white;
  float: right;
  margin-top: 4px;
  height: 32px;
  width: 70px !important;
  cursor: pointer;
  border-radius: 3px;
  margin-bottom: 20px;
  margin-right: 50px; }

.closeBtn:active {
  background-color: darkgreen; }

.dividingHR {
  margin-bottom: 0 !important; }
