.requirement-sets-details-container .header {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px; }

.requirement-sets-details-container .requirement-condition {
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center; }

.requirement-sets-details-container .requirements-icons > span {
  cursor: pointer; }

.requirement-sets-details-container .requirements-groups > div:first-child > .card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.requirement-sets-details-container .requirements-groups > div:not(:first-child):not(:last-child) > .card {
  border-radius: 0;
  border-top: none; }

.requirement-sets-details-container .requirements-groups > div:last-child > .card {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.requirement-sets-details-container .file-attachment-card-block {
  padding: 0.75rem; }
  .requirement-sets-details-container .file-attachment-card-block .attachments-icons > span {
    font-size: 24px;
    cursor: pointer; }
    .requirement-sets-details-container .file-attachment-card-block .attachments-icons > span:first-child {
      margin-right: 20px; }

.requirement-sets-details-container h4, .requirement-sets-details-container h5, .requirement-sets-details-container strong {
  color: #2E5965; }

.requirement-sets-details-container .card {
  -webkit-box-shadow: rgba(46, 89, 101, 0.1) 0px 0px 5px;
  box-shadow: rgba(46, 89, 101, 0.1) 0px 0px 5px; }

.attachment-form-modal .admin-field-wrapper-file {
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .attachment-form-modal .admin-field-wrapper-file label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 45px;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold; }
    .attachment-form-modal .admin-field-wrapper-file label::before {
      margin-right: 7px;
      font-weight: normal;
      color: white; }
  .attachment-form-modal .admin-field-wrapper-file > div > span {
    position: relative;
    top: 0; }

.v-cursor-not-allowed {
  cursor: not-allowed !important; }

.header-requirmentset-with-error {
  display: flex; }

.error-item-form {
  display: inline-block;
  text-align: center;
  width: calc(100% - 40px);
  font-size: 16px;
  color: #e74a3b;
  margin-top: 8px; }

.requirement-rule-button {
  padding: 0;
  background-color: transparent;
  border: none; }

.advance-option-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
  width: 100%; }
  .advance-option-container > button {
    padding: 7px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #2E5965;
    font-weight: 700;
    border: 0.5px solid #CFCFD0;
    border-radius: 4px; }
    .advance-option-container > button .rotate-icon {
      transform: rotate(90deg); }
  .advance-option-container .advance-option-label-container {
    display: flex;
    width: fit-content;
    min-width: fit-content;
    align-items: center;
    gap: 2px;
    padding: 0 3px; }
    .advance-option-container .advance-option-label-container > label {
      min-width: 80px !important; }
    .advance-option-container .advance-option-label-container .MuiSvgIcon-root {
      width: fit-content;
      max-width: fit-content;
      height: 15px;
      cursor: pointer; }
