.submit {
  background-color: #8CC739;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  min-width: 140px;
  padding: 10px 25px;
  text-transform: uppercase; }
