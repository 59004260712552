.hc-profile-forms-modal .discrete-acounts-modal-header {
  border-bottom: 1px solid #2E5965;
  padding-bottom: 5px; }
  .hc-profile-forms-modal .discrete-acounts-modal-header .discrete-acounts-modal-header-title {
    color: #2E5965;
    font-weight: bold; }

.hc-profile-forms-modal .hide-scorecards-fields-modal-field {
  color: #2E5965;
  font-weight: bold;
  margin-bottom: 10px; }
