@media only screen and (max-width: 700px) {
  #SectionChart {
    width: calc(100%);
    height: inherit; } }

@media only screen and (min-width: 701px) {
  #SectionText, #SectionChart {
    width: calc(50% - 22px);
    height: calc(100% - 22px); } }

#exagomastercontainer {
  width: 100% !important; }

#ExagoWrapper {
  position: absolute;
  margin-top: 20px;
  height: 100%;
  width: 100%; }

#Container-Report, #Container-FullUI {
  height: calc(100%);
  position: relative; }

#SectionText, #SectionChart, #SectionReport {
  float: left;
  position: relative;
  padding: 10px; }

#SectionText {
  border: 1px solid #EFEFEF;
  overflow-y: auto; }
  @media only screen and (max-width: 700px) {
    #SectionText {
      width: calc(100%);
      height: auto; } }

#SectionReport {
  width: calc(100%); }
  @media only screen and (max-width: 700px) {
    #SectionReport {
      height: inherit; } }
  @media only screen and (min-width: 701px) {
    #SectionReport {
      height: 50%; } }

.hide {
  display: none; }
