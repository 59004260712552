.taskDetail .modal-dialog {
  max-width: 80%;
  width: 80%; }

.taskDetail .modal-content {
  top: 100px; }

.taskDetail .modal-body {
  padding-top: 0 !important;
  margin-top: 0; }

.taskDetail .innerTitle {
  background-color: #7ED0BC;
  color: #305866;
  padding: 5px 0 !important;
  margin: 10px 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.taskDetail header {
  padding: 0 !important;
  margin: 00 0 10px 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #7ED0BC;
  height: 40px; }
  .taskDetail header .title {
    color: #305866;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase; }

.taskDetail span.field-validation-message {
  top: 5px;
  right: 10px; }

.taskDetail form.list-view-filter-form .filter-fields textarea {
  height: 100px; }

.taskDetail form.list-view-filter-form .filter-fields textarea:disabled {
  background-color: #eeeeee; }

.taskDetail .formButtons {
  width: 100%;
  height: 45px;
  margin-top: 20px;
  padding: 0;
  text-align: center; }
  .taskDetail .formButtons button, .taskDetail .formButtons a {
    border-radius: 6px;
    border-bottom: 4px solid #305866;
    text-transform: uppercase;
    margin: 0 5px; }
  .taskDetail .formButtons a {
    padding: 12px 25px;
    color: white;
    font-size: 13px;
    display: inline-block;
    margin-right: 15px;
    cursor: pointer; }
    .taskDetail .formButtons a:hover {
      color: white; }

.taskDetail .comment {
  margin: auto auto 0 auto; }

.taskDetail .comment button {
  margin: 0 10px; }

.taskDetail .card-header {
  background-color: #7ED0BC;
  font-size: 12px;
  text-align: center;
  color: #305866;
  font-weight: 500;
  text-transform: uppercase; }
