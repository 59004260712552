.profile-view-wrapper {
  background: #F2FAF8;
  padding: 5px 0; }

.profile-column-inner {
  display: flex;
  flex-wrap: wrap;
  margin: 35px 0 35px 10px;
  background: #FFFFFF;
  padding: 10px; }
  .profile-column-inner.profile-sidebar .profile-sidebar-content {
    width: 100%;
    display: flex; }
  .profile-column-inner.profile-main {
    margin-left: 10px;
    padding: 0; }

.profile-sidebar {
  box-shadow: 0px 0px 2px 1px #4aad98;
  padding: 23px 15px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .profile-sidebar section.sidebar-header {
    display: flex;
    align-items: center; }
    .profile-sidebar section.sidebar-header .logo-wrapper {
      margin-left: auto;
      margin-right: auto; }
    .profile-sidebar section.sidebar-header .logo {
      max-width: 350px;
      max-height: 170px;
      width: auto;
      height: auto; }
    .profile-sidebar section.sidebar-header .phone {
      font-size: 11px;
      font-weight: 100;
      color: #4E4E4E; }
    .profile-sidebar section.sidebar-header .link {
      margin-bottom: 5px; }
      .profile-sidebar section.sidebar-header .link a {
        font-size: 11px;
        position: relative;
        top: -7px;
        text-decoration: underline; }
  .profile-sidebar section.sidebar-section {
    display: flex;
    flex-wrap: wrap; }
    .profile-sidebar section.sidebar-section h3 {
      font-size: 15px;
      color: #2E5965;
      font-weight: 600;
      margin-bottom: 0; }
    .profile-sidebar section.sidebar-section .sidebar-item {
      margin-top: 5px;
      padding-right: 80px; }
      .profile-sidebar section.sidebar-section .sidebar-item span.item-label {
        color: #A3A1AA;
        font-size: 11px;
        font-weight: 100;
        margin-right: 5px; }
      .profile-sidebar section.sidebar-section .sidebar-item span.item-value {
        font-size: 14px;
        font-weight: 500;
        color: #4E4E4E;
        overflow-wrap: anywhere;
        width: 80%; }
  .profile-sidebar .sidebar-button {
    background-color: #8CC739;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 35%; }

.profile-tabs.nav-tabs {
  background: #D5DEE0;
  border-bottom: 0; }
  .profile-tabs.nav-tabs .tab-item {
    display: flex; }
    .profile-tabs.nav-tabs .tab-item .tab-button {
      background: #D5DEE0;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      font-weight: 500;
      padding: 23px 18.5px 21px;
      text-align: center;
      text-transform: uppercase;
      width: 100%; }
      .profile-tabs.nav-tabs .tab-item .tab-button.active {
        background: #085DAD;
        color: #FFFFFF; }
      .profile-tabs.nav-tabs .tab-item .tab-button:hover {
        background: #8CC739;
        color: #FFFFFF; }
