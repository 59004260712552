.holder-logo {
  max-width: 200px;
  max-height: 150px; }

.dropzone {
  position: 'relative';
  width: '200px';
  height: '200px';
  border-width: '2px';
  border-color: 'rgb(102, 102, 102)';
  border-style: 'dashed';
  border-radius: '5px';
  background: '#4982de'; }

.comments-textarea {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.requirement-sets-table {
  overflow-y: auto;
  height: 250px; }

.attachments-table,
.endorsements-table {
  overflow-y: auto;
  height: 116px; }

.documents-table {
  overflow-y: auto;
  height: 346px; }

.documents-table table {
  border-collapse: collapse;
  width: 100%; }

.requirement-sets-table table th,
.attachments-table table th,
.endorsements-table table th,
.documents-table table th {
  background-color: #2E5965;
  color: #eee;
  padding: 8px 16px; }

.requirement-sets-table table td,
.attachments-table table td,
.endorsements-table table td,
.documents-table table td {
  padding: 8px 16px; }
  .requirement-sets-table table td a,
  .attachments-table table td a,
  .endorsements-table table td a,
  .documents-table table td a {
    color: #085DAD; }

.requirement-sets-table th,
.attachments-table th,
.endorsements-table th,
.documents-table th {
  position: sticky;
  top: 0;
  background: #eee; }

.small-input {
  width: 100%; }

.explanatory-text {
  color: red;
  font-size: 17px; }
  .explanatory-text a {
    color: red;
    font-weight: 500;
    text-decoration: underline; }
    .explanatory-text a:hover {
      color: red; }

.requirement-sets-to-pdf {
  font-size: 0.75rem; }
  .requirement-sets-to-pdf .header {
    margin-top: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 5px; }
  .requirement-sets-to-pdf .requirement-condition {
    font-weight: bold;
    font-size: 0.75rem;
    text-align: center; }
  .requirement-sets-to-pdf .requirements-icons > span {
    cursor: pointer; }
  .requirement-sets-to-pdf .requirements-groups > div:first-child > .card {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .requirement-sets-to-pdf .requirements-groups > div:not(:first-child):not(:last-child) > .card {
    border-radius: 0;
    border-top: none; }
  .requirement-sets-to-pdf .requirements-groups > div:last-child > .card {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .requirement-sets-to-pdf .file-attachment-card-block {
    padding: 0.75rem; }
    .requirement-sets-to-pdf .file-attachment-card-block .attachments-icons > span {
      font-size: 24px;
      cursor: pointer; }
      .requirement-sets-to-pdf .file-attachment-card-block .attachments-icons > span:first-child {
        margin-right: 20px; }
  .requirement-sets-to-pdf h4, .requirement-sets-to-pdf h5, .requirement-sets-to-pdf h6, .requirement-sets-to-pdf strong {
    color: #2E5965; }
  .requirement-sets-to-pdf .card {
    -webkit-box-shadow: rgba(46, 89, 101, 0.1) 0px 0px 5px;
    box-shadow: rgba(46, 89, 101, 0.1) 0px 0px 5px; }
