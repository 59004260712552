.sc-financial-info-copy-submission-modal .sc-financial-info-copy-submission-modal-header {
  color: #FFFFFF;
  font-size: 20px; }

.sc-financial-info-copy-submission-modal .sc-financial-info-copy-submission-modal-content {
  padding: 30px 50px; }
  .sc-financial-info-copy-submission-modal .sc-financial-info-copy-submission-modal-content .sc-financial-info-copy-submission-modal-container {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px; }
  .sc-financial-info-copy-submission-modal .sc-financial-info-copy-submission-modal-content .sc-financial-info-copy-submission-modal-buttons-container {
    padding-right: 15px;
    padding-left: 15px; }
    .sc-financial-info-copy-submission-modal .sc-financial-info-copy-submission-modal-content .sc-financial-info-copy-submission-modal-buttons-container .action-button {
      margin-left: 20px; }
