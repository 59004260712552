.change-tax-id-modal .change-tax-id-modal-header {
  color: #FFFFFF;
  font-size: 20px; }

.change-tax-id-modal .change-tax-id-content {
  font-size: 15px;
  padding: 30px 50px; }
  .change-tax-id-modal .change-tax-id-content .tax-id-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px; }
    .change-tax-id-modal .change-tax-id-content .tax-id-form-container label {
      color: #2E5965;
      font-weight: 500; }
    .change-tax-id-modal .change-tax-id-content .tax-id-form-container select {
      height: 32px;
      max-width: 300px;
      margin-bottom: 30px; }
  .change-tax-id-modal .change-tax-id-content .action-button {
    margin-left: 20px; }
