.scoreCardInput .sciButton, .scoreCardInput .sciFooter button {
  cursor: pointer;
  height: 40px;
  min-width: 160px;
  border-radius: 5px;
  border-top: none;
  border-right: none;
  border-left: none;
  background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
  border-bottom-color: #2E5965;
  border-bottom-width: 4px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px; }

.scoreCardInput {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 40px; }
  .scoreCardInput .sciButton.disabled {
    background: grey; }
  .scoreCardInput .sciActions {
    margin-bottom: 10px; }
  .scoreCardInput .sciHeader {
    background-color: white;
    border: 2px solid #dee2e6;
    padding: 10px 15px; }
    .scoreCardInput .sciHeader .sciTitle {
      display: inline-flex;
      align-items: center;
      min-height: 70px;
      font-size: 24px; }
    .scoreCardInput .sciHeader .row {
      margin: 0; }
      .scoreCardInput .sciHeader .row .col-sm-6 {
        padding: 0;
        align-items: center;
        display: inline-flex; }
    .scoreCardInput .sciHeader .sciLabelWrapper {
      width: 100%;
      height: 100%;
      padding: 0 10px;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 500;
      color: #2E5965; }
  .scoreCardInput .sciSelectWrapper {
    position: relative;
    z-index: 1;
    width: 100%; }
    .scoreCardInput .sciSelectWrapper:after {
      content: 'a';
      position: absolute;
      top: 13px;
      right: 10px;
      border-top: 6px solid #A3A1AA;
      color: transparent;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      width: 0;
      z-index: -1; }
  .scoreCardInput .sciInput {
    padding: 6px 5px;
    font-size: 12px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #CFCFD0;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent;
    height: 30px; }
  .scoreCardInput .sciBody {
    margin-top: 30px; }
    .scoreCardInput .sciBody .sciGroupTitle {
      cursor: pointer;
      margin-top: 15px;
      display: inline-flex;
      align-items: center;
      width: 100%;
      min-height: 45px;
      color: #2E5965;
      text-transform: uppercase;
      background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
      font-size: 15px;
      font-weight: 500; }
      .scoreCardInput .sciBody .sciGroupTitle .sci-arrow-down,
      .scoreCardInput .sciBody .sciGroupTitle .sci-arrow-up {
        margin: 0 15px;
        border-left: 13px solid transparent;
        color: transparent;
        border-right: 13px solid transparent;
        width: 26px; }
      .scoreCardInput .sciBody .sciGroupTitle .sci-arrow-down {
        border-top: 13px solid #2E5965; }
      .scoreCardInput .sciBody .sciGroupTitle .sci-arrow-up {
        border-bottom: 13px solid #2E5965; }
    .scoreCardInput .sciBody .sciGroupTotal {
      border: 2px solid #dee2e6;
      min-height: 60px;
      margin: 0;
      align-items: center;
      text-transform: uppercase;
      background-color: #fbe0e4;
      font-size: 13px;
      font-weight: 500;
      color: #4E4E4E; }
    .scoreCardInput .sciBody .sciGroupVariables {
      border: 2px solid #dee2e6;
      align-items: center;
      background-color: white;
      border-bottom: none;
      color: #666666;
      display: flex;
      font-size: 13px;
      font-weight: 500;
      justify-content: space-around;
      margin: 20px 0 0 0;
      min-height: 40px;
      padding: 25px 0; }
      .scoreCardInput .sciBody .sciGroupVariables input {
        width: 100%; }
    .scoreCardInput .sciBody .sciParentGroupsContainer {
      padding: 25px 40px; }
    .scoreCardInput .sciBody .sciGroup {
      margin-bottom: 15px; }
      .scoreCardInput .sciBody .sciGroup .sciGroupTableHeader {
        border: 2px solid #dee2e6;
        min-height: 40px;
        margin: 20px 0 0 0;
        align-items: center;
        color: #2E5965;
        background-color: white;
        font-size: 13px;
        font-weight: 600;
        border-bottom: none; }
      .scoreCardInput .sciBody .sciGroup .sciGroupTable {
        border: 2px solid #dee2e6;
        border-bottom: none;
        font-size: 14px;
        font-weight: 300;
        background-color: white; }
        .scoreCardInput .sciBody .sciGroup .sciGroupTable .sciTableRow {
          min-height: 60px;
          margin: 0;
          align-items: center;
          border-bottom: 1px solid #a3a1ab;
          font-size: 13px;
          font-weight: 600;
          color: #4A4A4A; }
          .scoreCardInput .sciBody .sciGroup .sciGroupTable .sciTableRow .addAccountButton {
            background-color: #8CC739;
            border: 1px solid #2E5965;
            color: #fff;
            cursor: pointer;
            font-size: 13px;
            font-weight: 700;
            margin-left: 25px;
            padding: 10px 15px;
            text-transform: uppercase; }
            .scoreCardInput .sciBody .sciGroup .sciGroupTable .sciTableRow .addAccountButton:before {
              color: white; }
        .scoreCardInput .sciBody .sciGroup .sciGroupTable .sciTableRow:last-child {
          border-bottom: none; }
        .scoreCardInput .sciBody .sciGroup .sciGroupTable .sciTableRow:nth-child(even) {
          background: #f7f7f7; }
    .scoreCardInput .sciBody input {
      color: #4E4E4E;
      padding-left: 8px;
      border-radius: 5px;
      border: 2px solid #4aad98;
      background-color: #d0ebde;
      height: 30px; }
    .scoreCardInput .sciBody input:disabled {
      background-color: #81cfbd; }
    .scoreCardInput .sciBody .edit-note-button {
      cursor: pointer;
      position: relative; }
      .scoreCardInput .sciBody .edit-note-button:before {
        color: #2E5965;
        left: -16px;
        position: absolute;
        text-decoration: none;
        top: 1px; }
  .scoreCardInput .sciCommentary {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px; }
    .scoreCardInput .sciCommentary textarea {
      background-color: #FFFFFF;
      width: 50%;
      height: 160px; }
  .scoreCardInput .sciFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px; }

.scoreCardModal .modal-dialog {
  max-width: 70%;
  width: 70%; }

.scoreCardModal .modal-body {
  padding: 0 !important;
  margin: 0; }

.scoreCardModal header {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: #7ED0BC; }
  .scoreCardModal header .scoreCardTitle {
    color: white;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase; }

.scoreCardModal .scoreCardTrade {
  background-color: #e6e3ea;
  color: #2E5965;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  width: 100%;
  padding: 5px;
  font-weight: 400;
  text-transform: uppercase;
  min-height: 34px; }

.scoreCardModal .scoreCardDates {
  background-color: #d8d8d8;
  color: #2E5965;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 34px; }
  .scoreCardModal .scoreCardDates div {
    justify-content: center;
    align-items: center;
    display: inline-flex;
    width: 50%;
    padding: 5px;
    text-transform: uppercase; }

.scoreCardModal .scoreCardTables {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px; }
  .scoreCardModal .scoreCardTables .table-with-pagination {
    padding: 0px; }
  .scoreCardModal .scoreCardTables .scoreCardTable .col-th-wrapper {
    text-transform: none; }
  .scoreCardModal .scoreCardTables .scoreCardTable tr {
    box-shadow: none; }
  .scoreCardModal .scoreCardTables .scoreCardTable td {
    padding: 0;
    line-height: 60px;
    font-weight: 400;
    text-transform: uppercase; }
  .scoreCardModal .scoreCardTables .scoreCardTable td:first-child {
    padding-left: 50px; }
  .scoreCardModal .scoreCardTables .scoreCardTable .scoreCardValueCol {
    padding-left: 10px; }
    .scoreCardModal .scoreCardTables .scoreCardTable .scoreCardValueCol.green {
      background-color: #d3eae4; }
    .scoreCardModal .scoreCardTables .scoreCardTable .scoreCardValueCol.yellow {
      background-color: #fee9b1; }
    .scoreCardModal .scoreCardTables .scoreCardTable .scoreCardValueCol.red {
      background-color: #f3cdd7; }
    .scoreCardModal .scoreCardTables .scoreCardTable .scoreCardValueCol span {
      padding-left: 17px;
      position: relative; }
      .scoreCardModal .scoreCardTables .scoreCardTable .scoreCardValueCol span:before {
        content: '\2022';
        position: absolute;
        font-size: 30px;
        left: 0px;
        color: transparent; }
      .scoreCardModal .scoreCardTables .scoreCardTable .scoreCardValueCol span.green:before {
        color: #81d0bb; }
      .scoreCardModal .scoreCardTables .scoreCardTable .scoreCardValueCol span.yellow:before {
        color: #f3a300; }
      .scoreCardModal .scoreCardTables .scoreCardTable .scoreCardValueCol span.red:before {
        color: #ea607d; }

.scoreCardModal .scoreCardMainParameters {
  float: right;
  margin: 0 20px 40px 0;
  border: 2px solid #dee2e6;
  border-bottom: transparent;
  display: flex;
  flex-direction: column; }
  .scoreCardModal .scoreCardMainParameters .mainParametersRow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #2E5965;
    border-bottom: 2px solid #dee2e6; }
    .scoreCardModal .scoreCardMainParameters .mainParametersRow .mainParameterDesc {
      min-width: 250px;
      padding: 15px; }
    .scoreCardModal .scoreCardMainParameters .mainParametersRow .mainParameterVal {
      min-width: 200px;
      min-height: 54px;
      padding: 15px;
      text-align: right;
      background-color: #d3eae4; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

nav.finance-nav {
  text-align: right;
  position: relative; }
  nav.finance-nav .finance-nav-margin {
    margin: 30px 15px 0px 0px !important; }
  nav.finance-nav ul {
    margin: 0;
    padding: 0;
    display: inline-block; }
    nav.finance-nav ul li {
      display: inline-block; }
      nav.finance-nav ul li .finance-nav-link.nav-bn {
        color: #fff;
        border: 0px solid transparent;
        background-color: #8CC739;
        border: 1px solid #2E5965;
        padding: 10px 25px;
        margin-right: 0;
        margin-left: 20px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 13px;
        cursor: pointer; }
