.updateExpiredFile__fileInputContainer {
  position: relative;
  margin: 10px 30px; }
  .updateExpiredFile__fileInputContainer .viewFileSpinnerContainer {
    float: right;
    margin-right: 12px;
    margin-top: 3px;
    padding: 0; }
    .updateExpiredFile__fileInputContainer .viewFileSpinnerContainer .spinner-wrapper {
      margin: 0; }
      .updateExpiredFile__fileInputContainer .viewFileSpinnerContainer .spinner-wrapper .spinner {
        height: 18px;
        width: 18px; }

.updateExpiredFileContainer {
  width: 100%;
  height: 100vh;
  background-color: #085DAD;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
