.builder {
  display: flex;
  flex-direction: row; }

.options {
  display: flex;
  flex-direction: column;
  background-color: #666666;
  margin-top: 30px;
  margin-left: 30px;
  width: 20%;
  margin-bottom: 40px;
  height: calc(100% - 95px); }

.form {
  margin-top: 30px;
  margin-left: 40px;
  margin-right: 20px;
  width: 80%;
  padding-bottom: 40px; }
  .form .formHeader {
    display: flex;
    flex-direction: column; }
    .form .formHeader select {
      width: 250px;
      margin-bottom: 15px; }
    .form .formHeader .formTitle {
      width: 50%;
      height: 40px;
      border-width: 2px;
      border-style: dashed;
      border-color: #B9B9B9;
      margin-bottom: 15px;
      padding-left: 10px;
      font-size: 25px; }
    .form .formHeader .formDescription {
      width: 80%;
      min-height: 60px;
      border-width: 2px;
      border-style: dashed;
      border-color: #B9B9B9;
      margin-bottom: 30px;
      padding-left: 10px;
      font-size: 18px;
      resize: none; }

.formContainer {
  width: 100%;
  min-height: 70%;
  background-color: #F2F2F2;
  margin-bottom: 10px;
  padding: 10px;
  border-width: 2px;
  border-style: dashed;
  border-color: #B9B9B9;
  display: flex;
  flex-direction: column; }

.formFooter {
  height: 30%;
  min-height: 100px;
  width: 100%;
  background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 106px; }

.divider {
  width: 100%;
  height: 2px;
  background-color: #b9b5b5;
  margin-bottom: 30px; }

.formRow {
  display: flex;
  flex-direction: row; }

.fieldsContainer {
  display: flex;
  flex-direction: row; }

.column {
  display: flex;
  flex-direction: column; }

.footerBtn {
  height: 40px;
  width: fit-content;
  min-width: 130px;
  border-radius: 5px;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: #FFFFFF;
  border-bottom-color: #29AEA2;
  border-bottom-width: 4px;
  font-size: 14px;
  font-weight: bold;
  color: #2E5965;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px; }

.tab {
  height: 50px;
  width: 50%;
  background-color: #4E4E4E;
  border: none;
  color: #FFFFFF;
  font-style: italic;
  font-size: 13px;
  font-weight: bold; }

.optionList {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 4%;
  margin-right: 4%;
  overflow-y: scroll; }
  .optionList .list-button:before {
    color: #666666;
    margin-right: 1%; }

.list-button {
  background-color: #F7F7F7;
  border-radius: 5px;
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  border: none;
  text-align: left;
  color: #666666; }

.inputTitle {
  font-weight: bold;
  color: #1C7EFF; }

.selectedTab {
  background-color: #666666; }

.italic {
  font-style: italic;
  color: #A3A1AA; }

.text {
  color: #FFFFFF;
  font-weight: bold; }

.sectionTitle {
  width: 40%;
  font-size: 15px; }

.text2 {
  color: #FFFFFF;
  margin-left: 10px; }

.sectionDivider,
.subSectionDivider {
  width: 100%;
  text-align: center;
  line-height: 30px;
  border-top: solid;
  border-bottom: solid;
  border-color: #42b9f4;
  color: #42b9f4; }
  .sectionDivider label,
  .subSectionDivider label {
    margin: 0; }

.subSectionDivider {
  text-align: left;
  border-top: none; }

.field {
  font-size: 15px;
  width: 50%;
  height: 40px;
  border-width: 2px;
  border-style: dashed;
  border-color: #B9B9B9;
  padding-left: 10px;
  pointer-events: none;
  overflow: hidden; }

.checkboxContainer {
  display: flex;
  align-items: center; }

.fileContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: dashed;
  border-color: #B9B9B9;
  padding-left: 10px;
  pointer-events: none;
  background-color: white; }
  .fileContainer input[type="file"]::-webkit-file-upload-button {
    text-align: center;
    vertical-align: middle;
    -webkit-appearance: none;
    float: left;
    margin: auto;
    border: 1px solid #aaaaaa;
    border-radius: 4px;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#d2d0d0), to(#f0f0f0));
    background-image: -moz-linear-gradient(90deg, #d2d0d0 0%, #f0f0f0 100%); }

.field::placeholder {
  color: #a4a4a4;
  opacity: 1; }

input[type=checkbox] {
  margin-right: 5px;
  margin-left: 5px; }

input[type=radio] {
  margin-right: 5px;
  margin-left: 5px; }

.labelField {
  font-weight: bold;
  color: #42b9f4;
  border: none;
  background-color: transparent;
  margin: 0; }

input[type=file] {
  font-size: 12px;
  user-select: none; }

@media screen and (min-width: 576px) {
  .options {
    position: fixed; }
  .form {
    position: relative;
    left: 25%;
    width: 70%; } }

/* Dialog Box */
.fieldDialogRight,
.fieldDialogLeft {
  position: relative;
  background: #666666;
  height: fit-content;
  width: fit-content;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-height: 430px; }
  .fieldDialogRight .typeTitle,
  .fieldDialogLeft .typeTitle {
    color: white;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin: 5px 0 10px 0;
    border-bottom: 1px solid #3b3b3b; }
  .fieldDialogRight .dialogBody,
  .fieldDialogLeft .dialogBody {
    flex-direction: column;
    display: flex;
    font-size: 14px;
    font-weight: 100;
    padding-bottom: 10px; }
    .fieldDialogRight .dialogBody .dialogInputContainer,
    .fieldDialogLeft .dialogBody .dialogInputContainer {
      display: inline-flex;
      align-items: center;
      padding: 5px; }
      .fieldDialogRight .dialogBody .dialogInputContainer label,
      .fieldDialogLeft .dialogBody .dialogInputContainer label {
        color: white;
        margin: 0px;
        width: 84px; }
      .fieldDialogRight .dialogBody .dialogInputContainer input,
      .fieldDialogLeft .dialogBody .dialogInputContainer input {
        color: #999999;
        background-color: #3b3b3b;
        border: none;
        border-radius: 5px;
        margin-left: 5px;
        padding-left: 10px;
        padding-right: 10px;
        width: 180px;
        font-weight: 400; }
      .fieldDialogRight .dialogBody .dialogInputContainer select,
      .fieldDialogLeft .dialogBody .dialogInputContainer select {
        margin-left: 5px;
        color: #999999 !important;
        background-color: #3b3b3b;
        border: none;
        height: 23px;
        width: 155px; }
      .fieldDialogRight .dialogBody .dialogInputContainer .description,
      .fieldDialogLeft .dialogBody .dialogInputContainer .description {
        color: #a4a4a4;
        margin-left: 5px;
        font-weight: 400; }
      .fieldDialogRight .dialogBody .dialogInputContainer .dialogCheckbox,
      .fieldDialogLeft .dialogBody .dialogInputContainer .dialogCheckbox {
        width: 150px; }
        .fieldDialogRight .dialogBody .dialogInputContainer .dialogCheckbox input,
        .fieldDialogLeft .dialogBody .dialogInputContainer .dialogCheckbox input {
          width: 18px;
          float: right;
          height: 18px;
          margin-top: 2px; }
      .fieldDialogRight .dialogBody .dialogInputContainer .dialogError,
      .fieldDialogLeft .dialogBody .dialogInputContainer .dialogError {
        color: #bc0000; }
      .fieldDialogRight .dialogBody .dialogInputContainer .btnMore,
      .fieldDialogLeft .dialogBody .dialogInputContainer .btnMore {
        background-color: #F7F7F7;
        color: #3b3b3b;
        border-radius: 5px;
        width: 100%;
        border: none; }
  .fieldDialogRight .dialogFooter,
  .fieldDialogLeft .dialogFooter {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    border-top: 1px solid #3b3b3b; }
  .fieldDialogRight .dialogFooterBtn,
  .fieldDialogLeft .dialogFooterBtn {
    background-color: transparent;
    border-color: transparent;
    margin-left: 5px;
    color: white; }
  .fieldDialogRight .dialogFooterBtn:hover,
  .fieldDialogLeft .dialogFooterBtn:hover {
    color: gray; }
  .fieldDialogRight .btnIcon,
  .fieldDialogLeft .btnIcon {
    font-size: 1.5rem; }

.fieldDialogRight:before,
.fieldDialogLeft:before {
  top: 18px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(194, 225, 245, 0);
  border-width: 15px;
  margin-top: -15px; }

.fieldDialogRight:before {
  left: -29px;
  border-right-color: #666666; }

.fieldDialogLeft:before {
  right: -29px;
  border-left-color: #666666; }

/* Section Tabs */
.form .react-tabs-container {
  background-color: #F2F2F2;
  border: none;
  min-height: 45px;
  max-height: 45px;
  overflow: scroll;
  width: calc(100% - 44px); }

.form .react-tabs-tab {
  display: flex;
  background-color: #F2F2F2;
  border-right: 2px #B9B9B9 solid;
  border-bottom: 8px #B9B9B9 solid;
  color: #B9B9B9;
  min-width: 80px;
  min-height: 45px;
  max-height: 45px; }

.form .react-tabs-active {
  border: none;
  border-bottom: 8px #B9B9B9 solid;
  background-color: #B9B9B9;
  color: white; }

.form .react-tabs-tab-content {
  font-size: 13px; }

.form .react-tabs-tab-close {
  width: 20px;
  border: none;
  border-radius: 20px;
  line-height: 23px; }

.form .react-tabs-active .react-tabs-tab-close:hover,
.form .react-tabs-tab-close:hover {
  background-color: #ff6161;
  color: white; }

.form .react-tabs-child {
  position: absolute;
  right: 0;
  width: 44px;
  min-height: 45px;
  padding: 0;
  background-color: #F2F2F2; }

.form .addTabButton {
  background-color: #F2F2F2;
  color: #B9B9B9;
  font-size: 20px;
  border: 0;
  outline: none;
  height: 45px;
  line-height: 37px;
  margin-top: -7px;
  margin-right: -7px;
  text-align: center;
  width: 100%;
  margin: 0px;
  padding: 0px; }

.form .addTabButton:hover {
  background-color: #B9B9B9;
  color: #FFFFFF; }
