.view-task-modal {
  box-shadow: 1px 1px 3px #7ED0BC, -1px -1px 3px #7ED0BC; }
  .view-task-modal .first-row {
    display: flex;
    justify-content: center; }
  .view-task-modal .view-task-modal-header {
    background-color: #2E5965;
    color: #FFFFFF;
    font-size: 25px;
    font-style: italic;
    height: 80px;
    padding: 25px; }
  .view-task-modal .view-task-content {
    border: 1px solid #29AEA2;
    font-size: 15px;
    padding: 50px; }
    .view-task-modal .view-task-content .view-task-label {
      color: #2E5965;
      font-weight: bold;
      font-size: 14px; }
    .view-task-modal .view-task-content .view-task-text {
      background-color: #EFEFEF;
      border: none;
      color: #7ED0BC;
      font-weight: 500;
      padding: 7px 10px;
      margin: 2px 10px 30px 0; }
    .view-task-modal .view-task-content .description-text {
      background-color: #EFEFEF;
      border: none;
      color: #000;
      margin: 2px 10px 30px 0;
      min-height: 200px;
      padding: 7px 10px; }
  .view-task-modal .action-button {
    margin-left: 20px; }
