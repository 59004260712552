.templates-view nav.templates-nav {
  margin-bottom: 20px;
  text-align: right;
  position: relative; }
  .templates-view nav.templates-nav ul {
    margin: 0;
    padding: 0;
    display: inline-block; }
    .templates-view nav.templates-nav ul li {
      display: inline-block; }
      .templates-view nav.templates-nav ul li .templates-nav-link.nav-bn {
        color: #fff;
        border: 0px solid transparent;
        background-color: #8CC739;
        border: 1px solid #2E5965;
        padding: 10px 25px;
        margin-right: 0;
        margin-left: 20px;
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 13px;
        cursor: pointer; }
        .templates-view nav.templates-nav ul li .templates-nav-link.nav-bn:before {
          margin-right: 10px;
          font-weight: 100; }
        .templates-view nav.templates-nav ul li .templates-nav-link.nav-bn.active {
          background: transparent;
          border: 1px solid #2E5965;
          color: #2E5965;
          box-shadow: 0 0 10px 0px rgba(41, 174, 162, 0.3); }
          .templates-view nav.templates-nav ul li .templates-nav-link.nav-bn.active:before {
            color: #2E5965; }
  .templates-view nav.templates-nav .hide-popover {
    display: none; }
