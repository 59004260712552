.holder-settings .settings-error {
  color: red;
  text-align: center; }

.holder-settings .entity-info-form {
  padding-top: 0px;
  margin-left: 5%; }
  .holder-settings .entity-info-form.ml-0 select {
    margin-left: 5%;
    width: calc((100%) - (5%)) !important; }
  .holder-settings .entity-info-form .admin-form-field-wrapper label {
    width: 100% !important; }
