nav.list-view-nav {
  margin-bottom: 0 !important;
  text-align: right;
  position: relative; }

.documents-queue {
  margin: 0;
  display: flex;
  background-color: #FFFFFF;
  padding: 13px 13px 24px;
  margin-top: -13px; }
  .documents-queue label {
    color: #6c8994;
    padding-right: 5px;
    padding-top: 5px; }
  .documents-queue input, .documents-queue select, .documents-queue textarea {
    color: #4E4E4E;
    padding: 6px 5px;
    font-size: 12px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #CFCFD0;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent; }
  .documents-queue .select-wrapper:after {
    border-top: 6px solid #A3A1AA; }
  .documents-queue select {
    padding: 7px 5px;
    font-style: italic;
    color: #4E4E4E; }

.documents-list {
  overflow: auto !important; }
  .documents-list table {
    margin: 2px !important;
    padding: 2px !important; }
  .documents-list table th {
    margin: 0 !important;
    padding: 0 !important;
    white-space: pre-wrap; }
  .documents-list table td {
    padding: 8px !important;
    white-space: unset; }
