.processing-preview-screen-container .missing-data-container {
  margin-top: 60px;
  display: flex;
  justify-content: center; }

.processing-preview-screen-container .entity-info-form {
  padding: 0px; }
  .processing-preview-screen-container .entity-info-form .filter-form-field-wrapper {
    display: inline-flex;
    margin-right: 15px;
    padding: 3px; }
    .processing-preview-screen-container .entity-info-form .filter-form-field-wrapper label {
      margin-bottom: 0;
      margin-right: 10px;
      padding-top: 5px;
      color: #2E5965;
      font-weight: bold; }
    .processing-preview-screen-container .entity-info-form .filter-form-field-wrapper .form-removable-container .value-container {
      width: 250px; }
    .processing-preview-screen-container .entity-info-form .filter-form-field-wrapper .field-validation-message {
      display: none; }
    .processing-preview-screen-container .entity-info-form .filter-form-field-wrapper .type-ahead-search-bar-container {
      margin: 0 0 0 20px;
      width: 250px; }
      .processing-preview-screen-container .entity-info-form .filter-form-field-wrapper .type-ahead-search-bar-container .type-ahead-search-bar {
        padding: 0; }
        .processing-preview-screen-container .entity-info-form .filter-form-field-wrapper .type-ahead-search-bar-container .type-ahead-search-bar.focused {
          border-radius: 5px 5px 5px 5px; }
        .processing-preview-screen-container .entity-info-form .filter-form-field-wrapper .type-ahead-search-bar-container .type-ahead-search-bar .input-container {
          border-radius: 5px 5px 5px 5px !important;
          padding: 7px 30px 7px 25px !important; }

.processing-preview-screen-container hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.processing-preview-screen-container input[type="text"] {
  display: block;
  width: 100%;
  padding: .175rem .35rem;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.processing-preview-screen-container .sm-input {
  width: 120px !important; }

.processing-preview-screen-container label {
  font-size: 14px;
  font-weight: 700;
  color: #2E5965;
  display: inline-block;
  width: 100%;
  margin: 0;
  position: relative; }

.processing-preview-screen-container h2 {
  color: #2E5965;
  padding: 0;
  margin: 0 0 16px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700; }

.processing-preview-screen-container .buttons-section {
  background-color: darkslategray;
  padding: 6px;
  justify-content: center; }

.add-item-modal .modal-dialog {
  max-width: 800px; }

.add-item-modal .modal-content {
  top: 0px !important;
  margin-bottom: 30px; }
  .add-item-modal .modal-content .modal-body {
    padding: 5px 20px 20px; }
