.references-modal .modal-dialog {
  max-width: 50%;
  width: 50%; }

.references-modal .modal-body {
  padding: 0 !important;
  margin: 0; }

.references-modal .add-reference-form {
  box-shadow: 1px 1px 3px #7ED0BC, -1px -1px 3px #7ED0BC; }
  .references-modal .add-reference-form .add-reference-modal-header {
    background: linear-gradient(to right, #7ED0BC, #29AEA2);
    color: #2E5965;
    font-size: 17px;
    font-weight: bold;
    height: 65px;
    padding: 25px; }
  .references-modal .add-reference-form .add-reference-content {
    padding: 25px; }
    .references-modal .add-reference-form .add-reference-content .input {
      border: 1px solid #29AEA2;
      color: #000;
      padding: 7px 10px;
      width: 100%; }
      .references-modal .add-reference-form .add-reference-content .input:disabled {
        background-color: #EFEFEF; }
    .references-modal .add-reference-form .add-reference-content span.field-validation-message {
      right: 3px;
      top: 0; }
