.email-file-upload {
  box-shadow: 1px 1px 3px #7ED0BC, -1px -1px 3px #7ED0BC;
  width: 33%;
  margin-left: 33%; }
  @media screen and (max-device-width: 1440px) {
    .email-file-upload {
      width: 50%;
      margin-left: 25%; } }
  @media screen and (max-device-width: 992px) {
    .email-file-upload {
      width: 80%;
      margin-left: 10%; } }
  @media screen and (max-device-width: 576px) {
    .email-file-upload {
      width: 100%;
      margin-left: 5%; } }
  .email-file-upload .email-file-upload-header {
    background: linear-gradient(to right, #7ED0BC, #29AEA2);
    color: #2E5965;
    font-size: 17px;
    font-weight: bold;
    height: 65px;
    padding: 25px; }
  .email-file-upload .email-file-content {
    border: 1px solid #29AEA2;
    font-size: 15px;
    padding: 50px; }
    .email-file-upload .email-file-content .email-file-label {
      color: #2E5965;
      font-weight: bold;
      font-size: 14px; }
    .email-file-upload .email-file-content .email-file-text {
      background-color: #EFEFEF;
      border: none;
      color: #7ED0BC;
      font-weight: 500;
      padding: 7px 10px;
      margin: 2px 10px 30px 0; }
    .email-file-upload .email-file-content .file-input {
      background-color: #F3FBF9;
      border: 2px dashed #E3E7EF;
      color: #2E5965;
      cursor: pointer;
      font-size: 14px;
      font-style: italic;
      font-weight: bold;
      padding: 20px 0;
      text-align: center;
      width: 100%; }
    .email-file-upload .email-file-content .description-input {
      border: 1px solid #29AEA2;
      color: #000;
      margin: 2px 10px 30px 0;
      min-height: 200px;
      padding: 7px 10px;
      width: 100%; }
  .email-file-upload span.field-validation-message {
    right: 3px;
    top: 0; }
  .email-file-upload .upload-file-wrapper span.field-validation-message {
    right: -316px !important;
    top: -20px !important; }
