.entity-info-form {
  padding: 10px 0 20px 0 !important; }
  .entity-info-form .csv-file-wrapper {
    margin-bottom: 10px;
    cursor: pointer; }
    .entity-info-form .csv-file-wrapper .field-validation-message {
      left: 15px !important;
      text-align: left !important;
      bottom: 20px !important;
      top: unset !important; }
  .entity-info-form .icon-cancel {
    font-weight: 700;
    text-transform: uppercase; }
