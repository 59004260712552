.data-entry-form {
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */ }
  .data-entry-form input[name=documentFile] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
  .data-entry-form label[for=documentFile] {
    cursor: pointer;
    border: 2px dashed #29AEA2;
    text-align: center;
    padding: 235px 0px 235px; }
  .data-entry-form [name=documentFile] + span.field-validation-message {
    top: 10px !important;
    left: 25px !important;
    right: auto !important; }
  .data-entry-form section.border:first-child {
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .data-entry-form section.border:not(:first-child) {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: none; }
  .data-entry-form .upload-file-wrapper {
    text-align: center;
    min-height: 100%; }
    .data-entry-form .upload-file-wrapper label {
      color: white !important;
      background-color: darkslategrey; }
  .data-entry-form .coverages-section button {
    cursor: pointer; }
  .data-entry-form .coverages-section .attribute-row > div {
    color: #2E5965;
    font-weight: bold; }
  .data-entry-form .coverages-section .attributes-section .row {
    display: flex;
    align-items: center; }
  .data-entry-form .insurers-section button {
    cursor: pointer; }
  .data-entry-form .agency-section .agency-data ul {
    margin: 0;
    padding: 0; }
  .data-entry-form .insured-section .insured-data ul {
    margin: 0;
    padding: 0; }
  @media screen and (max-height: 450px) {
    .data-entry-form .fileViewer {
      padding-top: 15px; } }

.large-modal {
  margin-top: 0;
  min-height: 650px !important; }
