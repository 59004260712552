.change-status-modal .first-row {
  display: flex;
  justify-content: center; }

.change-status-modal .change-status-modal-header {
  color: #FFFFFF;
  font-size: 20px; }

.change-status-modal .change-status-content {
  font-size: 15px;
  padding: 30px 50px; }

.change-status-modal .change-status-content .statusContainer {
  display: flex;
  align-items: baseline;
  justify-content: left !important;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%; }

.change-status-modal .change-status-content .statusContainer label {
  color: #2E5965;
  font-weight: 500;
  margin-right: 20px; }

.change-status-modal .change-status-content .statusContainer select {
  height: 38px; }

.change-status-modal .change-status-content .action-button {
  margin-left: 20px; }

.change-status-modal .change-status-content .error {
  color: red; }

.dropdown {
  display: inline-block;
  transition: all .5s ease; }

.dropdown .select {
  cursor: pointer;
  display: block;
  padding: 8px; }

.dropdown .select > i {
  font-size: 13px;
  color: #888;
  cursor: pointer;
  transition: all .3s ease-in-out;
  float: right;
  line-height: 20px; }

.dropdown.active .select > i {
  transform: rotate(-90deg); }

.dropdown .dropdown-menu {
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0;
  margin-top: 1px;
  box-shadow: 0 1px 2px #cccccc;
  border-radius: 0 1px 2px 2px;
  overflow: hidden;
  display: none;
  max-height: 144px;
  overflow-y: auto;
  z-index: 9; }

.dropdown .dropdown-menu li {
  padding: 10px;
  transition: all .2s ease-in-out;
  cursor: pointer; }

.dropdown .dropdown-menu {
  padding: 0;
  list-style: none; }

.dropdown .dropdown-menu li:hover {
  background-color: #f2f2f2; }

.dropdown .dropdown-menu li:active {
  background-color: #e2e2e2; }

.select-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -130px;
  margin-left: -17px; }

.label-hc {
  color: #2E5965;
  font-weight: 500;
  margin-right: 20px; }

.menu-dropdown .dropdown-menu.show {
  display: block; }
