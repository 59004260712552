.spinner-component .spinner-wrapper {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center; }
  .spinner-component .spinner-wrapper .spinner {
    border: 6px solid #D1D8E5;
    border-top: 6px solid #2E5965;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    display: inline-block; }
