.holder-requirement-sets-list-view .back-to-header {
  margin-bottom: 15px; }

.holder-requirement-sets-list-view .holder-requirement-sets-list-view-header .list-data strong {
  color: #2E5965; }

.holder-requirement-sets-list-view .holder-requirement-sets-list-view-header .list-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap; }

.holder-requirement-sets-list-view .requirements-groups > div:first-child > .card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.holder-requirement-sets-list-view .requirements-groups > div:not(:first-child):not(:last-child) > .card {
  border-radius: 0;
  border-top: none; }

.holder-requirement-sets-list-view .requirements-groups > div:last-child > .card {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.holder-requirement-sets-list-view h4, .holder-requirement-sets-list-view h5, .holder-requirement-sets-list-view strong {
  color: #2E5965; }

.holder-requirement-sets-list-view .attachments-icons > span,
.holder-requirement-sets-list-view .endorsements-icons > span {
  font-size: 24px;
  cursor: pointer;
  vertical-align: middle; }
  .holder-requirement-sets-list-view .attachments-icons > span:first-child,
  .holder-requirement-sets-list-view .endorsements-icons > span:first-child {
    margin-right: 20px; }

.holder-requirement-sets-list-view a {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  color: #2E5965; }
  .holder-requirement-sets-list-view a.cell-table-link {
    color: #8CC739 !important;
    position: relative;
    cursor: pointer; }
    .holder-requirement-sets-list-view a.cell-table-link:hover {
      text-decoration: underline; }
    .holder-requirement-sets-list-view a.cell-table-link:before {
      color: #8CC739;
      position: absolute;
      top: 1px;
      left: -16px; }

.holder-requirement-sets-list-view .coverages-table {
  width: 100%; }
  .holder-requirement-sets-list-view .coverages-table .td-first {
    min-width: 180px;
    white-space: nowrap; }

.holder-requirement-sets-list-view .coverages-headers {
  background-color: #2E5965;
  color: #eee;
  font-weight: 500;
  font-size: small;
  white-space: nowrap;
  padding: 5px;
  text-align: left; }
  .holder-requirement-sets-list-view .coverages-headers .td-first {
    min-width: 180px; }

.holder-requirement-sets-list-view .coverages-attr-headers {
  color: #2E5965;
  font-weight: 500;
  font-size: small;
  white-space: nowrap;
  padding: 5px;
  text-align: center; }

.holder-requirement-sets-list-view .compliance-status {
  font-weight: 400;
  font-size: xx-large;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
  height: 120px;
  padding-top: 32px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
  .holder-requirement-sets-list-view .compliance-status.compliant {
    background-color: #8CC739; }
  .holder-requirement-sets-list-view .compliance-status.non-compliant {
    background-color: #f04c41; }
  .holder-requirement-sets-list-view .compliance-status.escalated {
    background-color: #FEAB0F; }
  .holder-requirement-sets-list-view .compliance-status.minor {
    padding-top: 10px;
    background-color: #FEEC0F; }
  .holder-requirement-sets-list-view .compliance-status.on-hold {
    background-color: #2BAFF4; }

.holder-requirement-sets-list-view .def-checkbox {
  height: 22px !important;
  width: 18px !important;
  vertical-align: bottom; }

.requirements-icons > span {
  cursor: pointer; }

.add-item-modal.add-entity-small .modal-dialog {
  max-width: 650px; }

.layers-table {
  overflow-y: auto;
  height: 346px; }
  .layers-table a {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    color: #2E5965; }
    .layers-table a.cell-table-link {
      color: #8CC739 !important;
      position: relative;
      cursor: pointer; }
      .layers-table a.cell-table-link:hover {
        text-decoration: underline; }
      .layers-table a.cell-table-link:before {
        color: #8CC739;
        position: absolute;
        top: 1px;
        left: -16px; }

.layers-table table {
  border-collapse: collapse;
  width: 100%; }

.layers-table table th {
  background-color: #2E5965;
  color: #eee;
  padding: 8px 16px; }

.layers-table table td {
  padding: 8px 16px 8px 20px; }

.layers-table th {
  position: sticky;
  top: 0;
  background: #eee; }

.attributes-status {
  border-radius: 0.8em;
  color: #f04c41;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1em;
  height: 1em; }
  .attributes-status.non-submitted, .attributes-status.rejected, .attributes-status.expired {
    background-color: #f04c41; }
  .attributes-status.pending {
    background-color: lightgray; }
  .attributes-status.accepted, .attributes-status.waived, .attributes-status.exempted {
    background-color: #8CC739; }
  .attributes-status.minor {
    background-color: #FEEC0F; }
  .attributes-status.escalated {
    background-color: #FEAB0F; }
