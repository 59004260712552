form.template-fields {
  padding: 15px 14px 24px; }
  form.template-fields h2.forms-filter-title {
    color: #2E5965;
    padding: 0;
    margin: 0 0 16px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700; }
  form.template-fields .filter-fields .keywords-field, form.template-fields .filter-fields .hiring-client-field {
    padding-right: 30px; }
  form.template-fields .filter-fields label {
    font-size: 13px;
    margin-bottom: 0;
    width: 100%;
    color: #2E5965;
    font-weight: 500; }
  form.template-fields .filter-fields input, form.template-fields .filter-fields select {
    color: #4E4E4E;
    padding: 6px 5px;
    font-size: 12px;
    width: 100%;
    border-radius: 3px;
    border: 1px solid #CFCFD0;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: transparent; }
  form.template-fields .filter-fields .select-wrapper:after {
    border-top: 6px solid #A3A1AA; }
  form.template-fields .filter-fields select {
    padding: 7px 5px;
    font-style: italic;
    color: #CFCFD0; }
  form.template-fields .filter-fields input::placeholder {
    color: #CFCFD0;
    font-style: italic; }
  form.template-fields .filter-fields button.filter-bn {
    margin-left: 20px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 13px;
    padding: 5px 9px;
    border: 1px solid #8D959A;
    color: #fff;
    margin-top: 24px;
    font-family: 'Ubuntu';
    background-color: #8CC739;
    cursor: pointer;
    border-radius: 3px; }

.template-builder .templateHeader {
  margin: 15px 0; }
  .template-builder .templateHeader .col-md-6 {
    padding: 0 30px 0 0; }
  .template-builder .templateHeader .paramContainer {
    margin-top: 5px; }
    .template-builder .templateHeader .paramContainer label {
      width: auto;
      margin: 0;
      font-size: 13px;
      color: #2E5965;
      font-weight: 500; }
    .template-builder .templateHeader .paramContainer input {
      width: 100%;
      height: 32px;
      color: #4E4E4E;
      padding: 6px 5px;
      font-size: 12px;
      border-radius: 3px;
      border: 1px solid #CFCFD0;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: transparent; }
    .template-builder .templateHeader .paramContainer select {
      width: 50%;
      height: 32px; }
    .template-builder .templateHeader .paramContainer .mandatory::after {
      pointer-events: none;
      position: relative;
      float: right;
      right: -5px;
      color: red;
      content: "*"; }

.template-builder .builderFooter {
  text-transform: uppercase; }
  .template-builder .builderFooter button {
    text-transform: uppercase; }

.editor {
  border: 1px solid #8D959A;
  cursor: text;
  height: 550px;
  border-top-left-radius: 6px; }

.builderRow {
  display: flex;
  flex-direction: row;
  margin-right: 20px; }
  .builderRow .button2 {
    margin-right: 5%; }
  .builderRow .btn-transparent {
    margin-right: 5%; }

.column {
  display: flex;
  flex-direction: column; }

.button2 {
  height: 40px;
  width: auto;
  min-width: 200px;
  border-radius: 5px;
  border-top: none;
  border-right: none;
  border-left: none;
  background-color: #FFFFFF;
  border-bottom-color: #29AEA2;
  border-bottom-width: 4px;
  font-size: 14px;
  font-weight: bold;
  color: #2E5965; }

.builderFooter {
  height: 20%;
  width: 100%;
  background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 150px;
  margin-top: 20px; }

.btn-transparent {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  color: #2E5965; }

.superFancyBlockquote {
  color: #999;
  font-family: 'Hoefler Text', Georgia, serif;
  font-style: italic;
  text-align: center; }

.editorContainer {
  padding-left: 0;
  width: 100%;
  display: flex;
  flex-direction: row; }

.editor-column {
  display: flex;
  flex-direction: column;
  width: 100%; }

.editorHeader {
  border-bottom: 1px solid #8D959A;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
  background-color: #D1D8E5;
  height: 50px;
  padding-right: 2%;
  padding-left: 2%;
  display: flex; }

.editor-btn {
  border: 1px solid #8D959A;
  background-color: #FFFFFF;
  margin: 0;
  border-radius: 0;
  box-sizing: border-box;
  padding: 15px 10px;
  font-size: 0;
  min-width: 39px; }

.editor-btn-right {
  border: 1px solid #8D959A;
  background-color: #FFFFFF;
  margin: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 5px;
  box-sizing: border-box;
  padding: 15px 10px;
  font-size: 0; }

.editor-btn-left {
  border: 1px solid #8D959A;
  background-color: #FFFFFF;
  margin: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 5px;
  box-sizing: border-box;
  padding: 15px 10px; }

.editor-btn-wide {
  height: 35px;
  border: 1px solid #8D959A;
  background-color: #FFFFFF;
  width: 40px;
  padding-top: 5px;
  text-align: center; }

.editor-action-btn {
  border: 1px solid #8D959A;
  background-color: #FFFFFF;
  width: 175px;
  border-radius: 5px;
  height: 30px;
  color: black;
  font-weight: lighter;
  display: flex; }

.editor-action-btn:after {
  margin-right: 2px;
  margin-left: 13px;
  font-size: 15px;
  content: 'INSERT IMAGE'; }

.editor-side-menu {
  width: 20%;
  background: #D1D8E5;
  border: 1px solid #8D959A;
  padding: 10px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px; }
  .editor-side-menu p {
    font-size: 16px;
    margin-bottom: 5px; }
  .editor-side-menu .side-menu-subtitle {
    font-weight: lighter; }
  .editor-side-menu input {
    width: 100%;
    margin-bottom: 10px;
    padding-left: 5px;
    border: 1px solid #8D959A;
    height: 30px; }
  .editor-side-menu .placeholdersContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 200px;
    overflow-y: hidden; }
  .editor-side-menu .placeholdersContainer:hover {
    overflow-y: scroll; }
  .editor-side-menu .side-menu-btn {
    width: 100%;
    background-color: #FFFFFF;
    color: #34ACFE !important;
    height: 30px;
    text-align: center;
    margin-bottom: 5px;
    padding-top: 2px;
    font-weight: normal;
    border-radius: 5px;
    border: 1px solid #8D959A;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 10px;
    padding-right: 10px; }

.editor-class {
  margin-left: 10px;
  margin-right: 10px;
  height: 483px;
  overflow-y: scroll; }

.rdw-link-modal {
  height: 260px; }

.rdw-link-decorator-icon {
  left: 100%; }

.editor-btn-group {
  margin-right: 20px; }

.editor-btn-alone {
  border: 1px solid #8D959A;
  background-color: #FFFFFF;
  margin: 0;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 15px 10px;
  font-size: 0; }
  .editor-btn-alone:before {
    font-size: 15px;
    color: black !important; }
  .editor-btn-alone img {
    width: 0;
    height: 0; }

.editorLogoButton {
  border: 1px solid #8D959A;
  background-color: #FFFFFF;
  height: 30px;
  margin: 0;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 3px 15px;
  margin-left: 15px;
  cursor: pointer; }

.editorLogoButton:hover {
  box-shadow: 1px 1px 0px #BFBDBD; }

.rdw-image-modal .rdw-image-modal-header-option {
  width: 100%; }
  .rdw-image-modal .rdw-image-modal-header-option .rdw-image-modal-header-label {
    width: 100%; }

.rdw-image-modal .rdw-image-modal-url-input {
  width: 100%; }

.rdw-image-modal .rdw-image-mandatory-sign {
  display: none; }
