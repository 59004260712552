.body-col {
  width: 92%;
  position: relative;
  left: 8%;
  transition: left .1s;
  height: 100vh; }

.overlay {
  z-index: 5;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(6, 0, 0, 0.7); }

.header {
  z-index: -10; }

.header-frame {
  background: #FFFFFF;
  padding: 2px 15px;
  box-shadow: 2px 0px 5px #CFCFD0; }

.nav-bn {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 1px solid;
  margin-right: 15px;
  border-radius: 3px; }
  .nav-bn:hover {
    text-decoration: none; }
  .nav-bn.header-nav-bn {
    color: #2E5965; }
    .nav-bn.header-nav-bn:hover {
      color: #1d3a42; }
  .nav-bn.plus-icon:before {
    content: '+';
    margin-right: 5px;
    font-size: 22px;
    position: relative;
    top: 2px; }

.header-nav-wrapper > h3 {
  font-size: 13px;
  color: #2E5965; }

.header-nav-wrapper nav ul {
  padding: 0; }
  .header-nav-wrapper nav ul li {
    display: inline-block; }

.notifications-wrapper {
  text-align: right; }
  .notifications-wrapper .searchWrapper {
    padding: 0 !important;
    margin-right: 15px !important;
    width: 230px; }
    .notifications-wrapper .searchWrapper .search_subcontractors, .notifications-wrapper .searchWrapper .magnifier {
      color: #4E4E4E;
      float: left;
      padding: 6px 5px;
      margin-top: 4px;
      font-size: 12px;
      width: 160px;
      border-radius: 3px;
      border: 1px solid #CFCFD0;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: white; }
    .notifications-wrapper .searchWrapper .magnifier {
      margin-left: 3px;
      width: 31px;
      background: transparent;
      cursor: pointer; }
    .notifications-wrapper .searchWrapper .magnifier:active {
      background-color: darkgray; }
  .notifications-wrapper .linear-icon-envelope,
  .notifications-wrapper .linear-icon-document,
  .notifications-wrapper .linear-icon-users2,
  .notifications-wrapper .linear-icon-film-play,
  .notifications-wrapper .hc-icon,
  .notifications-wrapper .sso-icon,
  .notifications-wrapper .linear-icon-archive2,
  .notifications-wrapper .linear-icon-shield,
  .notifications-wrapper .linear-icon-contacts,
  .notifications-wrapper .linear-icon-gear,
  .notifications-wrapper .linear-icon-question,
  .notifications-wrapper .linear-icon-binoculars,
  .notifications-wrapper .linear-icon-books,
  .notifications-wrapper .linear-icon-medal,
  .notifications-wrapper .linear-icon-warning,
  .notifications-wrapper .linear-icon-graph {
    display: inline-block;
    position: relative;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    border: 1px solid #2E5965;
    width: 36px;
    height: 36px;
    text-decoration: none;
    margin: 5px 2px 5px 2px; }
    .notifications-wrapper .linear-icon-envelope:before,
    .notifications-wrapper .linear-icon-document:before,
    .notifications-wrapper .linear-icon-users2:before,
    .notifications-wrapper .linear-icon-film-play:before,
    .notifications-wrapper .hc-icon:before,
    .notifications-wrapper .sso-icon:before,
    .notifications-wrapper .linear-icon-archive2:before,
    .notifications-wrapper .linear-icon-shield:before,
    .notifications-wrapper .linear-icon-contacts:before,
    .notifications-wrapper .linear-icon-gear:before,
    .notifications-wrapper .linear-icon-question:before,
    .notifications-wrapper .linear-icon-binoculars:before,
    .notifications-wrapper .linear-icon-books:before,
    .notifications-wrapper .linear-icon-medal:before,
    .notifications-wrapper .linear-icon-warning:before,
    .notifications-wrapper .linear-icon-graph:before {
      position: relative;
      color: #2E5965;
      top: 7px;
      left: 0px; }
  .notifications-wrapper .linear-icon-film-play::before {
    left: 1px;
    top: 5px;
    padding-right: 3px; }
  .notifications-wrapper .hc-icon, .notifications-wrapper .sso-icon {
    line-height: 25px; }
  .notifications-wrapper .hc-icon::before {
    content: "HC";
    font-size: 18px;
    top: 4px;
    left: -1px; }
  .notifications-wrapper .sso-icon::before {
    content: "SSO";
    font-size: 15px;
    top: 4px;
    left: 0px; }
  .notifications-wrapper .linear-icon-envelope::before,
  .notifications-wrapper .linear-icon-users2::before {
    left: 1px;
    top: 3px; }
  .notifications-wrapper .holder-icon::before {
    content: 'H'; }
  .notifications-wrapper .nots {
    display: inline-block;
    position: relative;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
    font-size: 22px;
    border: 1px solid #2E5965;
    width: 38px;
    height: 38px;
    text-decoration: none;
    margin: 5px 7px 5px 7px; }
    .notifications-wrapper .nots:before {
      position: relative;
      left: -1px;
      top: 6px;
      color: #2E5965; }
    .notifications-wrapper .nots.open {
      background-color: #2E5965; }
      .notifications-wrapper .nots.open:before {
        color: white; }
    .notifications-wrapper .nots a.nots-badge {
      position: absolute;
      background: #EA5071;
      color: white;
      border-radius: 20px;
      padding: 1px 4px;
      font-size: 12px;
      top: 2px;
      right: -6px;
      user-select: none; }
      .notifications-wrapper .nots a.nots-badge:hover {
        color: white; }
  .notifications-wrapper .profile-container {
    display: inline-flex;
    align-items: center;
    margin-left: 20px; }
    .notifications-wrapper .profile-container .pl-user-name {
      margin-right: 15px;
      user-select: none;
      color: black; }
    .notifications-wrapper .profile-container .pl-btn-logout {
      font-size: 23px;
      margin-right: 15px;
      cursor: pointer; }
    .notifications-wrapper .profile-container .pl-user-name,
    .notifications-wrapper .profile-container .pl-btn-logout {
      position: relative;
      top: 3px; }
  .notifications-wrapper .profile-link {
    display: inline-block;
    margin-left: 20px; }
    .notifications-wrapper .profile-link label {
      margin-right: 30px;
      user-select: none; }
    .notifications-wrapper .profile-link .arrow-container {
      height: 23px;
      display: inline-block;
      width: 28px;
      padding: 5px;
      position: absolute;
      right: 0px;
      z-index: 2;
      border: 2px solid transparent; }
    .notifications-wrapper .profile-link .arrow-container.open {
      background-color: white;
      border: 2px solid #34ada1;
      border-bottom: transparent;
      z-index: 2; }
    .notifications-wrapper .profile-link .dropdown-arrow {
      border-left: 7px solid transparent;
      color: transparent;
      border-top: 9px solid #2E5965;
      border-right: 7px solid transparent; }
    .notifications-wrapper .profile-link .dropdown-content {
      position: absolute;
      display: flex;
      flex-direction: column;
      background-color: white;
      right: 0;
      z-index: 1;
      border: 2px solid #34ada1;
      top: 31px;
      padding: 10px; }
  .notifications-wrapper .notifications-container {
    position: absolute;
    /*display: flex;
    flex-direction: column;
    background-color: white;*/
    top: 0;
    right: 0;
    z-index: 1;
    /*border: 2px solid #34ada1;
    top: 31px;
    padding: 10px;*/ }
    .notifications-wrapper .notifications-container .bell-border {
      position: absolute;
      right: -13px;
      top: -16px;
      width: 60px;
      height: 70px;
      padding: 0;
      border: 2px solid #34ada1;
      border-bottom: 2px solid #D1D8E5;
      pointer-events: none; }
    .notifications-wrapper .notifications-container .notifications-content {
      position: absolute;
      right: -13px;
      top: 52px;
      display: flex;
      flex-direction: column;
      background-color: #f8f8f8;
      border: 2px solid #34ada1;
      min-width: 270px; }
      .notifications-wrapper .notifications-container .notifications-content .notification {
        border-left: 6px solid white;
        background-color: white;
        margin-top: 10px;
        display: inline-flex;
        align-items: center;
        padding: 15px 12px 15px 6px; }
        .notifications-wrapper .notifications-container .notifications-content .notification:first-child {
          margin-top: 0px; }
        .notifications-wrapper .notifications-container .notifications-content .notification.new {
          border-left: 6px solid #34ada1; }
        .notifications-wrapper .notifications-container .notifications-content .notification label {
          display: inline-flex;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
          color: #A3A1AA;
          font-size: 16px;
          width: 90%;
          user-select: none;
          cursor: pointer; }
        .notifications-wrapper .notifications-container .notifications-content .notification .remove-notification {
          width: 15px;
          height: 15px;
          background-color: #D6D7D6;
          color: #A3A1AA;
          font-size: 13px;
          border-radius: 8px;
          margin-left: 12px;
          user-select: none; }

.only-header-style {
  background-color: #085DAD; }
  .only-header-style .prequal-logo-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0; }
    .only-header-style .prequal-logo-container .prequal-logo {
      width: 100%;
      height: 100%;
      max-height: 50px; }
  .only-header-style .screen-title {
    color: white;
    padding: 15px 20px; }
  .only-header-style .only-header-notifications {
    padding: 10px 20px 10px 0;
    color: white; }
  .only-header-style .profile-container .pl-user-name {
    color: white; }
  .only-header-style .icon-notifications,
  .only-header-style .linear-icon-envelope,
  .only-header-style .linear-icon-users2,
  .only-header-style .linear-icon-document,
  .only-header-style .linear-icon-film-play,
  .only-header-style .hc-icon,
  .only-header-style .sso-icon,
  .only-header-style .linear-icon-archive2,
  .only-header-style .linear-icon-shield,
  .only-header-style .linear-icon-contacts,
  .only-header-style .linear-icon-gear,
  .only-header-style .linear-icon-question,
  .only-header-style .linear-icon-binoculars,
  .only-header-style .linear-icon-books,
  .only-header-style .linear-icon-medal,
  .only-header-style .linear-icon-graph {
    border-color: white; }
    .only-header-style .icon-notifications:before,
    .only-header-style .linear-icon-envelope:before,
    .only-header-style .linear-icon-users2:before,
    .only-header-style .linear-icon-document:before,
    .only-header-style .linear-icon-film-play:before,
    .only-header-style .hc-icon:before,
    .only-header-style .sso-icon:before,
    .only-header-style .linear-icon-archive2:before,
    .only-header-style .linear-icon-shield:before,
    .only-header-style .linear-icon-contacts:before,
    .only-header-style .linear-icon-gear:before,
    .only-header-style .linear-icon-question:before,
    .only-header-style .linear-icon-binoculars:before,
    .only-header-style .linear-icon-books:before,
    .only-header-style .linear-icon-medal:before,
    .only-header-style .linear-icon-graph:before {
      color: white; }

.titleContainer {
  align-items: flex-start;
  display: inline-flex; }

.screen-title {
  color: #2E5965;
  font-style: italic;
  font-weight: 200;
  font-size: 23px;
  text-transform: uppercase;
  position: relative;
  margin: 0;
  user-select: none; }

.nameChangeInput, .nameChangeBtn {
  color: #4E4E4E;
  float: left;
  padding: 6px 5px;
  font-size: 12px;
  height: 32px;
  width: 180px;
  border-radius: 3px;
  border: 1px solid #CFCFD0;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: white; }

.nameChangeBtn {
  margin-left: 3px;
  width: 31px;
  background: transparent;
  cursor: pointer; }

.nameChangeBtn:active {
  background-color: darkgreen; }

.edit {
  cursor: pointer; }
