.need-help {
  z-index: 1000;
  background-color: #205081;
  position: fixed;
  left: 100%;
  top: 40%;
  transform: rotate(90deg);
  transform-origin: top left;
  padding: 5px;
  border: 2px solid white;
  border-top: none;
  font-weight: bold;
  color: white !important;
  display: block;
  white-space: nowrap;
  text-decoration: none !important;
  font-size: 12px;
  border-radius: 0 0 5px 5px;
  cursor: pointer; }
