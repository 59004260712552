.taskEditor .modal-dialog {
  max-width: 80%;
  width: 80%; }

.taskEditor .modal-content {
  top: 100px; }

.taskEditor .modal-body {
  padding: 0 !important;
  margin: 0; }

.taskEditor header {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: #7ED0BC; }
  .taskEditor header .title {
    color: #305866;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase; }

.taskEditor .taskForm {
  margin: 0;
  padding: 25px 30px 40px 30px; }
  .taskEditor .taskForm .row {
    min-height: 71px; }
  .taskEditor .taskForm .filter-fields label {
    color: #6c8994; }
  .taskEditor .taskForm .filter-fields input {
    height: 32px; }
  .taskEditor .taskForm .filter-fields select {
    height: 32px; }
  .taskEditor .taskForm .filter-fields textarea {
    height: 140px;
    resize: none; }
  .taskEditor .taskForm .admin-form-field-wrapper {
    margin-top: 15px; }
  .taskEditor .taskForm .pad-left {
    padding-left: 20px; }
  .taskEditor .taskForm .pad-right {
    padding-right: 20px; }
  .taskEditor .taskForm span.field-validation-message {
    right: 22px;
    top: 22px; }
  .taskEditor .taskForm .select-wrapper select.invalid {
    border-color: #FF0000; }
  .taskEditor .taskForm .EditorButtons {
    justify-content: flex-end;
    display: inline-flex;
    width: 100%;
    height: 45px;
    margin-top: 45px;
    padding: 0; }
    .taskEditor .taskForm .EditorButtons button, .taskEditor .taskForm .EditorButtons a {
      border-radius: 6px;
      border-bottom: 4px solid #305866;
      text-transform: uppercase; }
    .taskEditor .taskForm .EditorButtons a {
      padding: 12px 25px;
      color: white;
      font-size: 13px;
      display: inline-block;
      margin-right: 15px;
      cursor: pointer; }
      .taskEditor .taskForm .EditorButtons a:hover {
        color: white; }
