.add-item-modal.add-entity .modal-dialog {
  max-width: 89.9%; }

.add-item-modal.add-entity-small .modal-dialog {
  max-width: 500px; }

.add-item-modal.add-entity-medium .modal-dialog {
  max-width: 600px; }

.new-entity-form .add-item-header,
.add-entity-form-small .add-item-header {
  margin: 10px; }

.new-entity-form .modal-wiz-title,
.add-entity-form-small .modal-wiz-title {
  margin-top: 25px;
  margin-bottom: 25px; }

.new-entity-form .save-entity-modal-error,
.add-entity-form-small .save-entity-modal-error {
  position: absolute;
  display: inline-block;
  width: 100%;
  bottom: 50px;
  text-align: center;
  color: red;
  font-size: 12px;
  pointer-events: none; }

.new-entity-form .entity-info-form,
.add-entity-form-small .entity-info-form {
  height: auto;
  padding: 30px; }
  .new-entity-form .entity-info-form .admin-form-field-wrapper,
  .add-entity-form-small .entity-info-form .admin-form-field-wrapper {
    position: relative;
    display: flex;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin-bottom: 25px !important; }
    .new-entity-form .entity-info-form .admin-form-field-wrapper label,
    .add-entity-form-small .entity-info-form .admin-form-field-wrapper label {
      min-width: 125px;
      line-height: 34px;
      width: auto !important; }
    .new-entity-form .entity-info-form .admin-form-field-wrapper .tip-span,
    .add-entity-form-small .entity-info-form .admin-form-field-wrapper .tip-span {
      font-size: 12px;
      color: black;
      font-weight: 400;
      position: absolute;
      left: 125px;
      top: -18px; }
    .new-entity-form .entity-info-form .admin-form-field-wrapper :last-child,
    .add-entity-form-small .entity-info-form .admin-form-field-wrapper :last-child {
      width: 100%; }
      .new-entity-form .entity-info-form .admin-form-field-wrapper :last-child input.invalid,
      .new-entity-form .entity-info-form .admin-form-field-wrapper :last-child textarea.invalid,
      .add-entity-form-small .entity-info-form .admin-form-field-wrapper :last-child input.invalid,
      .add-entity-form-small .entity-info-form .admin-form-field-wrapper :last-child textarea.invalid {
        border: 1px solid #FF0000; }
      .new-entity-form .entity-info-form .admin-form-field-wrapper :last-child textarea,
      .add-entity-form-small .entity-info-form .admin-form-field-wrapper :last-child textarea {
        min-height: 120px; }
      .new-entity-form .entity-info-form .admin-form-field-wrapper :last-child input[type="checkbox"],
      .add-entity-form-small .entity-info-form .admin-form-field-wrapper :last-child input[type="checkbox"] {
        height: 16px !important;
        margin: 8px 0;
        border: 1px solid #CFCFD0; }
    .new-entity-form .entity-info-form .admin-form-field-wrapper .field-validation-message,
    .add-entity-form-small .entity-info-form .admin-form-field-wrapper .field-validation-message {
      text-align: right; }

.add-entity-form-small .entity-info-form {
  padding: 30px 0 0 0; }
  .add-entity-form-small .entity-info-form .admin-form-field-wrapper input[type="checkbox"] {
    height: 16px !important;
    margin: 8px 0;
    border: 1px solid #CFCFD0; }
  .add-entity-form-small .entity-info-form .admin-form-field-wrapper .select-wrapper {
    width: 100% !important; }
