.workers-col.col-no-padding {
  padding: 0; }
  .workers-col.col-no-padding .workers-picture-wrapper {
    height: 100vh;
    background-image: url(./../../../assets/img/workers-small.jpg);
    background-position: left top;
    background-size: 144%;
    background-repeat: no-repeat; }

.col-custom-padding.form-wrapper {
  padding: 0 35px 0; }

.form-header {
  margin-bottom: 50px; }
  .form-header h4.title-login-fom {
    font-size: 16px;
    color: #666666;
    font-weight: 500;
    margin-bottom: 5px; }
  .form-header h5.subtitle-login-fom {
    font-size: 16px;
    color: #8D959A;
    font-weight: 400; }
  .form-header .logo-link {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 150px;
    margin-bottom: 45px;
    padding: 0 10% 0; }

.auth-form input {
  border-width: 0;
  border-bottom: 1px solid #A3A1AA;
  width: 100%;
  font-size: 14px;
  color: #8D959A;
  margin-bottom: 27px; }
  .auth-form input::placeholder {
    color: #8D959A; }
  .auth-form input::focus {
    outline: 0;
    border-bottom: 1px solid #666666; }

form.auth-form {
  margin-bottom: 20px; }
  form.auth-form button.bn-forgot,
  form.auth-form button.sign-in-icon {
    font-size: 14.5px;
    font-weight: 500; }
  form.auth-form button.sso-sign-in-icon {
    background-color: #ea6c20;
    font-size: 14.5px;
    font-weight: 500;
    padding: 15px 48px;
    margin-right: 10px; }
  form.auth-form label {
    display: inline-block;
    cursor: pointer;
    margin-bottom: 29px; }
    form.auth-form label .label {
      color: #666666;
      margin-left: 5px;
      font-weight: 500;
      font-size: 14px; }
    form.auth-form label input {
      display: inline-block;
      width: auto;
      margin: 0;
      vertical-align: text-bottom; }
      form.auth-form label input.remember-me {
        top: -1px;
        border-radius: 2px;
        border: 1.3px solid #666666; }

.form-buttons button.sign-in-icon {
  background-color: #085DAD;
  padding: 15px 48px;
  margin-right: 10px; }

.form-buttons button.cancel {
  background-color: #A3A1AA;
  padding: 15px 48px;
  margin-right: 10px; }

.form-buttons a {
  color: #5ECEFF;
  font-size: 14px;
  text-decoration: underline;
  margin-left: 15px; }
  .form-buttons a::hover {
    text-decoration: none;
    color: #5ECEFF; }

@media screen and (max-width: 1820px) {
  .form-buttons a {
    margin-left: 0;
    margin-top: 10px;
    display: block; } }

.form-buttons .icon-login-door:before {
  margin-right: 7px; }

.position-privacy-link a {
  position: relative;
  top: 10px; }

.field-wrapper {
  position: relative; }

span.errorMessage {
  position: absolute;
  top: 26px;
  left: 0;
  color: #EA5071;
  font-size: 10px;
  display: none; }
  span.errorMessage.show-error {
    display: inline-block; }

.step-body {
  margin-bottom: 13px;
  padding-top: 10px; }

span.server-error-reset {
  display: block;
  font-size: 10px;
  color: #EA5071;
  margin-top: 20px; }

@media (min-width: 768px) and (max-width: 1180px) {
  .auth-form .bn {
    display: block;
    width: calc(100% - 20px);
    margin-left: 10px; }
  .form-buttons a {
    margin-left: 0;
    display: inline-block; }
  .form-buttons {
    text-align: center; } }

@media (min-width: 1180px) and (max-width: 1700px) {
  .position-privacy-link a {
    margin-left: 0;
    position: relative;
    top: 30px; } }

@media (min-width: 911px) and (max-width: 1070px) {
  .workers-col.col-no-padding .workers-picture-wrapper {
    background-size: 174%; } }

@media (max-width: 910px) {
  .workers-col.col-no-padding .workers-picture-wrapper {
    background-size: 204%; } }

@media (max-width: 767px) {
  .form-header .logo-link {
    margin-top: 40px;
    padding: 0 20% 0; }
  .col-custom-padding.form-wrapper {
    padding: 0 60px 0; }
    .col-custom-padding.form-wrapper h4.title-login-fom,
    .col-custom-padding.form-wrapper h5.subtitle-login-fom {
      color: #FFFFFF;
      text-align: center; }
    .col-custom-padding.form-wrapper h4.title-login-fom {
      font-size: 17.5px; }
    .col-custom-padding.form-wrapper h5.subtitle-login-fom {
      font-size: 16px;
      margin-top: 10px; }
  .login .form-wrapper,
  .forgot .form-wrapper {
    height: 100vh;
    background-color: #085DAD; }
  span.errorMessage {
    color: #FFFFFF; }
  form.auth-form input {
    background: transparent;
    border-bottom: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-size: 14px; }
    form.auth-form input::placeholder {
      color: #FFFFFF;
      font-style: italic; }
  form.auth-form label .label {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    font-style: italic; }
  .form-buttons {
    text-align: center;
    margin-top: 33px; }
    .form-buttons a {
      display: inline-block;
      margin-left: 0;
      margin-top: 20px;
      color: #FFFFFF;
      font-style: italic;
      font-size: 14px; }
      .form-buttons a::hover {
        color: #FFFFFF; }
  span.link-forgot-wrapper {
    display: block;
    width: 100%; } }

@media screen and (max-width: 767px) and (max-width: 1820px) {
  span.link-forgot-wrapper {
    width: auto; } }

@media (max-width: 767px) {
  .auth-form label input.pretty-checkbox {
    border-color: #FFFFFF; }
  .auth-form .pretty-checkbox:checked + span.check:after {
    color: #FFFFFF; }
  .auth-form button.sign-in-icon, .auth-form button.bn-forgot {
    background: #FFFFFF;
    color: #34ACFE;
    font-weight: 700;
    font-size: 17.5px;
    padding-top: 16px;
    padding-bottom: 16px; }
  .auth-form button.sign-in-icon.icon-login-door:before {
    color: #34ACFE; }
  .login .form-header {
    margin-bottom: 63px; }
  .field-wrapper.user {
    margin-bottom: 7px; }
  .button-forgot-wrapper {
    text-align: center;
    margin-top: 30px; } }

@media (max-height: 550px) {
  .workers-col.col-no-padding {
    padding: 0;
    background-image: url(./../../../assets/img/workers.jpg);
    background-position: left center;
    background-size: 144%; }
  .workers-picture-wrapper {
    height: 0vh; } }
