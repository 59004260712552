.holder-requirement-sets-list .holder-requirement-sets-list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap; }

.holder-requirement-sets-list a {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  color: #2E5965;
  white-space: nowrap; }
  .holder-requirement-sets-list a.set-default {
    color: #8CC739 !important;
    position: relative;
    cursor: pointer; }
    .holder-requirement-sets-list a.set-default:hover {
      text-decoration: underline; }
    .holder-requirement-sets-list a.set-default:before {
      color: #8CC739;
      position: absolute;
      top: 1px;
      left: -16px; }
  .holder-requirement-sets-list a.unset-default {
    color: #FF0000 !important;
    position: relative;
    cursor: pointer; }
    .holder-requirement-sets-list a.unset-default:hover {
      text-decoration: underline; }
    .holder-requirement-sets-list a.unset-default:before {
      color: #FF0000;
      position: absolute;
      top: 1px;
      left: -16px; }
