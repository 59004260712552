.portal-form-container header {
  display: flex;
  align-items: center;
  color: #085DAD;
  padding-bottom: 5px;
  border-bottom: 5px solid #085DAD; }

.portal-form-container .portal-url-form .admin-field-wrapper-file .files-list > li {
  list-style: initial; }

.portal-form-container .portal-url-form .admin-field-wrapper-file .input-label {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 45px;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #085DAD;
  border-radius: 3px;
  line-height: initial; }
  .portal-form-container .portal-url-form .admin-field-wrapper-file .input-label::before {
    margin-right: 7px;
    font-weight: normal;
    color: white; }

.portal-form-container .portal-url-form .admin-field-wrapper-file .field-validation-message {
  top: 5px !important; }

.portal-form-container .portal-url-form .wiz-buttons {
  display: flex;
  justify-content: center;
  align-items: center; }
