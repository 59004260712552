.register-sidebar {
  height: 100vh; }
  .register-sidebar + .register-main {
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px; }

.logos-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 20px; }

.vertikal-img {
  display: block;
  margin-bottom: 40px !important;
  max-width: 100%; }

.vertikal-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  padding-top: 40px; }

.logos-wrapper img {
  max-width: 80%; }

.side-by-side-logos {
  display: flex;
  justify-content: center; }

.side-by-side-logos img {
  margin: 0 10px; }

@media (min-width: 768px) {
  .logos-wrapper {
    margin-top: 250px;
    margin-bottom: 40px; }
  .logos-wrapper img {
    max-width: 100%; } }

.register-sidebar-img {
  margin: 0 10px 0;
  width: 40%; }

p.register-slogan {
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  margin-top: 20px; }

h1.register-title {
  color: #085DAD;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 22px; }

p.register-instr {
  text-align: center;
  margin-bottom: 0px;
  font-size: 13px;
  color: #8D959A;
  letter-spacing: 0.3px; }

.register-main-header {
  margin-bottom: 30px; }

.tab-wrapper {
  width: 50%; }

.tabs-frame .nav-tabs {
  border-bottom: 0px; }
  .tabs-frame .nav-tabs a.step-nav {
    width: 100%;
    display: inline-block;
    color: #8D959A;
    text-align: center;
    padding: 7px 0;
    border-top: 1px solid #8D959A;
    border-bottom: 1px solid #8D959A;
    font-size: 13px;
    cursor: pointer;
    height: 100%; }
    .tabs-frame .nav-tabs a.step-nav.active {
      color: #085DAD;
      border-top: 2px solid #085DAD;
      border-bottom: 2px solid #085DAD; }

.tabs-frame .step-col-left {
  padding-left: 0; }

.tabs-frame .tabs-frame .step-col-right {
  padding-right: 0; }

.align-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.register-field-wrapper {
  padding-top: 20px;
  position: relative; }

span.field-validation-message {
  position: absolute;
  font-size: 10px;
  color: #FF0000;
  right: 0;
  left: auto;
  top: 27px;
  margin: 0; }

.register-main input, .register-main select {
  width: 100%;
  border: 1px solid #BABBBD;
  border-radius: 3px;
  padding: 7px 10px;
  font-size: 13px; }

.register-main input::placeholder {
  color: #CFCFD0;
  font-style: italic; }

.register-main select {
  padding: 7px 10px;
  font-style: italic;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  z-index: 0; }

.register-main label {
  color: #085DAD;
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 14px;
  letter-spacing: 0.3px; }
  .register-main label.required:before {
    content: '*';
    color: #FF0000;
    position: absolute;
    top: 45px;
    right: 3px;
    z-index: 0; }

.select-wrapper {
  position: relative; }
  .select-wrapper:after {
    content: 'a';
    position: absolute;
    top: 13px;
    right: 10px;
    border-top: 6px solid #085DAD;
    color: transparent;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    width: 0;
    z-index: -1; }

.step-button {
  margin-left: -15px;
  margin-top: 45px;
  position: relative; }

input.pretty-checkbox.agree {
  border: 1px solid #BABBBD;
  border-radius: 3px; }
  input.pretty-checkbox.agree:checked + span.check:after {
    top: 2px !important;
    color: #BABBBD !important; }
  input.pretty-checkbox.agree.invalid {
    border: 1px solid #FF0000; }

.agree-checkbox {
  position: absolute;
  top: -90px;
  display: inline-block;
  width: 100%; }
  .agree-checkbox p a {
    color: #085DAD; }

label[for='agree'] {
  cursor: pointer;
  position: relative;
  color: #8D959A;
  font-size: 13px; }
  label[for='agree'] .label {
    vertical-align: text-bottom;
    margin-left: 12px; }

label[for='previousWorkedForHC'] {
  cursor: pointer;
  position: relative;
  font-size: 13px; }
  label[for='previousWorkedForHC'] .label {
    vertical-align: text-bottom;
    margin-right: 12px; }

.agreement-container {
  padding-top: 40px; }
  .agreement-container .agreement-label {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #8D959A; }
    .agreement-container .agreement-label a {
      color: #085DAD; }

.step-button p {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #8D959A; }

.step-button .error-msj {
  position: absolute;
  top: -153%;
  width: 100%;
  text-align: left;
  font-size: 10px;
  color: #FF0000; }

.register-main .register-field-wrapper input.invalid, .register-main .register-field-wrapper select.invalid {
  border: 1px solid #FF0000; }

.error-register {
  position: absolute;
  font-size: 10px;
  color: #FF0000;
  top: -23px; }

.register-field-wrapper textarea {
  height: 140px;
  resize: none;
  width: 100%;
  color: #4E4E4E;
  padding: 6px 5px;
  font-size: 12px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #CFCFD0;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent; }

@media (min-width: 768px) and (max-height: 1140px) {
  .register-sidebar-img {
    margin-bottom: 20px; }
  .logos-wrapper {
    margin-top: 200px; } }

@media (max-width: 767px) {
  .logos-wrapper {
    margin-top: 100px; }
  .register-sidebar {
    height: auto; }
  .tabs-frame .step-col-left, .tabs-frame .step-col-right {
    padding: 0; } }

@media (min-width: 768px) {
  .row .register-main {
    overflow-y: scroll;
    height: stretch; } }
