.modal-title {
  margin-left: 10px; }

.projectInsureds-list {
  overflow: auto !important; }
  .projectInsureds-list table {
    margin: 2px !important;
    padding: 2px !important; }
  .projectInsureds-list table th {
    margin: 0 !important;
    padding: 0 !important;
    white-space: pre-wrap; }
  .projectInsureds-list table td {
    padding: 8px !important;
    white-space: unset; }

.copy-buttons-container {
  display: flex;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: #60818A; }

.copy-status-container {
  display: inline-flex;
  align-items: center; }

.copy-status-checkbox {
  top: .8rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 2px; }

.copy-status-label {
  color: #D5DEE0 !important; }

.show-all-btn {
  margin: 2px;
  background-color: #1d3a42; }
  .show-all-btn:disabled {
    cursor: not-allowed;
    opacity: 0.5 !important; }

.copy-to-selected-btn {
  margin: 2px;
  background-color: #8CC739; }
  .copy-to-selected-btn:disabled {
    cursor: not-allowed;
    opacity: 0.5 !important; }

.copy-to-all-btn {
  margin: 2px;
  background-color: #f04c41; }

.skip-step-btn {
  margin: 2px;
  background-color: #8CC739; }

.custom-control-label::before,
.custom-control-label::after {
  top: .8rem;
  width: 1.25rem;
  height: 1.25rem; }

.select-wrapper-for-multiple {
  position: relative;
  z-index: 5;
  color: #4E4E4E;
  /* padding: 6px 5px; */
  font-size: 12px;
  width: 100%; }

.input-sm {
  width: 270px;
  color: #4E4E4E !important;
  padding: 6px 5px;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid #CFCFD0;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent; }

.title {
  display: flex;
  justify-content: flex-start;
  padding: 8px;
  background-color: darkslategray;
  color: whitesmoke; }

.project-insured-table {
  overflow-y: auto; }

.project-insured-table table th {
  background-color: #2E5965;
  color: #eee;
  padding: 4px 4px;
  position: sticky;
  top: 0;
  z-index: 1; }

.project-insured-table table td {
  padding: 4px 8px; }
  .project-insured-table table td a {
    color: #8CC739;
    position: relative;
    cursor: pointer; }

.filter-field {
  margin: 2px;
  display: -webkit-inline-box;
  width: 80%; }

.copied-label {
  color: #f04c41;
  border: 1px solid  whitesmoke;
  cursor: pointer; }

.copy-certificate-filter-actions {
  display: flex;
  float: right;
  justify-content: flex-end;
  margin-top: 1px;
  min-width: 250px; }
  .copy-certificate-filter-actions .list {
    margin: 0; }
    .copy-certificate-filter-actions .list .list-item {
      display: inline-block; }
      .copy-certificate-filter-actions .list .list-item .filter-button {
        background-color: #8CC739;
        border: none;
        color: #fff;
        cursor: pointer;
        font-size: 13px;
        font-weight: 700;
        min-width: 140px;
        padding: 10px 25px;
        text-transform: uppercase; }
        .copy-certificate-filter-actions .list .list-item .filter-button:hover {
          text-decoration: none; }
