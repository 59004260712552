.add-file-modal {
  box-shadow: 1px 1px 3px #7ED0BC, -1px -1px 3px #7ED0BC; }
  .add-file-modal .add-file-modal-header {
    background: linear-gradient(to right, #7ED0BC, #29AEA2);
    color: #2E5965;
    font-size: 17px;
    font-weight: bold;
    height: 65px;
    padding: 25px; }
  .add-file-modal .add-file-content {
    border: 1px solid #29AEA2;
    font-size: 15px;
    padding: 50px; }
    .add-file-modal .add-file-content .add-file-label {
      color: #2E5965;
      font-weight: bold;
      font-size: 14px; }
    .add-file-modal .add-file-content .add-file-text {
      background-color: #EFEFEF;
      border: none;
      color: #7ED0BC;
      font-weight: 500;
      padding: 7px 10px;
      margin: 2px 10px 30px 0; }
    .add-file-modal .add-file-content .file-input {
      background-color: #F3FBF9;
      border: 2px dashed #E3E7EF;
      color: #2E5965;
      cursor: pointer;
      font-size: 14px;
      font-style: italic;
      font-weight: bold;
      padding: 20px 0;
      text-align: center;
      width: 100%; }
    .add-file-modal .add-file-content .description-input {
      border: 1px solid #29AEA2;
      color: #000;
      margin: 2px 10px 30px 0;
      min-height: 200px;
      padding: 7px 10px;
      width: 100%; }
  .add-file-modal span.field-validation-message {
    right: 3px;
    top: 0; }
  .add-file-modal .upload-file-wrapper span.field-validation-message {
    right: -316px !important;
    top: -20px !important; }
