.popover.fade {
  opacity: 1;
  padding: 0; }

nav.list-view-nav {
  margin-bottom: 20px;
  text-align: right;
  position: relative; }
  nav.list-view-nav ul {
    margin: 0;
    padding: 0;
    display: inline-block; }
    nav.list-view-nav ul li {
      display: inline-block; }
      nav.list-view-nav ul li .list-view-nav-link.nav-bn {
        color: #fff;
        border: 0px solid transparent;
        background-color: #8CC739;
        border: 1px solid #2E5965;
        padding: 10px 25px;
        margin-right: 0;
        margin-left: 20px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 13px;
        cursor: pointer; }
        nav.list-view-nav ul li .list-view-nav-link.nav-bn:before {
          margin-right: 10px;
          font-weight: 100;
          color: white; }
        nav.list-view-nav ul li .list-view-nav-link.nav-bn.active {
          background: transparent;
          border: 1px solid #2E5965;
          color: #2E5965;
          box-shadow: 0 0 10px 0px rgba(41, 174, 162, 0.3); }
          nav.list-view-nav ul li .list-view-nav-link.nav-bn.active:before {
            color: #2E5965; }
  nav.list-view-nav .hide-popover {
    display: none; }

.filters-col {
  padding-left: 16px;
  padding-right: 0; }

ul.filter-tabs {
  margin: 0;
  padding: 0; }
  ul.filter-tabs.filter-tabs-disabled button {
    opacity: 0.5;
    pointer-events: none; }
  ul.filter-tabs li {
    display: inline-block;
    padding: 0;
    margin: 0; }
    ul.filter-tabs li button.filter-tab {
      font-family: 'Ubuntu', sans-serif;
      background: none;
      text-transform: uppercase;
      border-top: 0 solid transparent;
      border-right: 0 solid transparent;
      border-left: 0 solid transparent;
      border-bottom: 1px solid #A6A09E;
      font-size: 14px;
      font-weight: 200;
      color: #A6A09E;
      cursor: pointer;
      padding: 0 10px 2px; }
      ul.filter-tabs li button.filter-tab:focus {
        outline: 0px; }
      ul.filter-tabs li button.filter-tab.active {
        border-bottom: 1px solid #2E5965;
        color: #2E5965;
        font-weight: 500;
        box-shadow: inset 0px -1px 0px #2E5965; }

section.list-view-header {
  margin-bottom: 23px; }

section.list-view-filters + section.list-view-table {
  box-shadow: rgba(46, 89, 101, 0.2) 0px 4px 5px; }

.popover-additem {
  position: absolute;
  width: 21%;
  text-align: center;
  background: #FFFFFF;
  padding: 15px 10px;
  margin-top: 20px;
  border: 1px solid #2E5965;
  z-index: 10;
  left: 66%;
  top: 120px; }
  .popover-additem div.popover-row a.nav-bn {
    width: 48.2%; }
    .popover-additem div.popover-row a.nav-bn:first-child {
      margin: 0 1.8% 0 0; }
    .popover-additem div.popover-row a.nav-bn:last-child {
      margin-left: 0 0 0 1.8%; }
  .popover-additem a.nav-bn {
    background-color: #8CC739;
    padding: 6px 10px;
    color: #FFFFFF !important;
    margin: 0;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer; }
    .popover-additem a.nav-bn:hover {
      color: #FFFFFF; }
  .popover-additem a.nav-bn-full-width {
    margin-top: 8px;
    display: inline-block;
    width: 100%; }
  .popover-additem:before, .popover-additem:after {
    content: 'a';
    left: 45%;
    position: absolute;
    top: -18.9%;
    color: transparent;
    width: 0;
    display: inline-block;
    border-bottom: 8px solid #FFFFFF;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent; }
  .popover-additem:before {
    border-bottom: 8px solid #2E5965;
    top: -19.6%; }
  .popover-additem h5 {
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
    font-weight: 500;
    margin-bottom: 11.5px;
    color: #2E5965; }

a.icon-quick_view.cell-table-link > .quickview-popover, .quickview-popover.locationPopover {
  display: none;
  width: 200px;
  top: 10px !important;
  left: -60px !important; }
  a.icon-quick_view.cell-table-link > .quickview-popover.changepassword-role, .quickview-popover.locationPopover.changepassword-role {
    width: 230px; }

a.icon-quick_view.cell-table-link > .quickview-popover.locationPopover {
  left: -140px !important; }

a.icon-quick_view.cell-table-link > .quickview-popover.top {
  top: auto !important;
  bottom: 30px; }

a.icon-quick_view.cell-table-link:hover > .quickview-popover {
  display: block;
  padding-bottom: 20px; }

.popover.quickview-popover::before {
  width: 100%;
  top: -20px; }

.popover.quickview-popover::after {
  width: 100%;
  bottom: -20px; }

.quickview-popover.popover.locationPopover {
  max-width: 500px !important;
  min-width: 300px !important; }

.quickview-popover {
  color: #A3A1AA;
  margin-top: 15px !important;
  box-shadow: 0px 0px 46px -12px #2E5965; }
  .quickview-popover.popover::before {
    width: 100%;
    top: -20px; }
  .quickview-popover.popover::after {
    width: 100%;
    bottom: -20px; }
  .quickview-popover.popover > .popover-content:after, .quickview-popover.popover > .popover-content:before {
    content: 'a';
    left: 26%;
    position: absolute;
    top: -27px;
    color: transparent;
    width: 0;
    display: inline-block;
    border-bottom: 8px solid #FFFFFF;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent; }
  .quickview-popover.popover > .popover-content:before {
    border-bottom: 8px solid #ccc;
    top: -28.5px; }
  .quickview-popover.popover.top > .popover-content:before {
    border-bottom: 0;
    border-top: 8px solid #ccc !important;
    bottom: -29px !important;
    top: auto !important; }
  .quickview-popover.popover.top > .popover-content:after,
  .quickview-popover.popover.top > .popover-content:before {
    border-bottom: 0;
    border-top: 8px solid #fff;
    bottom: -28px;
    top: auto !important; }
  .quickview-popover.popover.locationPopover > .popover-content:after, .quickview-popover.popover > .popover-content:before {
    top: -27px; }
  .quickview-popover .popoverbody.location {
    width: 100% !important; }
  .quickview-popover .locationRow, .quickview-popover .locationRowTop, .quickview-popover .locationRowBottom {
    display: flex;
    flex-wrap: wrap;
    padding-left: 2% !important;
    padding-right: 2% !important; }
  .quickview-popover .locationRowTop {
    padding-top: 2% !important; }
  .quickview-popover .locationRowBottom {
    padding-bottom: 2% !important; }
  .quickview-popover .popover-content {
    padding: 0; }
    .quickview-popover .popover-content .line-separator {
      border-top: 1px solid #CFCFD0;
      margin-top: 10px; }
    .quickview-popover .popover-content .popoverheader {
      padding: 3px 7px;
      border-bottom: 1px solid #CFCFD0; }
    .quickview-popover .popover-content .item-name {
      color: #60818A;
      font-weight: 700;
      font-size: 13px;
      text-transform: uppercase;
      margin: 0;
      display: inline-block; }
    .quickview-popover .popover-content span.item-status {
      padding-left: 17px;
      position: relative;
      float: right;
      color: #8D959A;
      margin-top: 2px;
      font-size: 12px;
      font-weight: 700; }
      .quickview-popover .popover-content span.item-status:before {
        content: '\2022';
        position: absolute;
        font-size: 30px;
        left: 0px;
        color: transparent;
        top: -18px; }
      .quickview-popover .popover-content span.item-status.inactive:before {
        color: #EA5071; }
      .quickview-popover .popover-content span.item-status.active:before, .quickview-popover .popover-content span.item-status.completed:before {
        color: #29AEA2; }
      .quickview-popover .popover-content span.item-status.changepassword:before, .quickview-popover .popover-content span.item-status.pending:before {
        color: #EA8856; }
    .quickview-popover .popover-content span.item-role {
      text-transform: uppercase;
      padding: 3px 7px 0 7px;
      margin-top: 10px; }
    .quickview-popover .popover-content span.item-role,
    .quickview-popover .popover-content span.item-email,
    .quickview-popover .popover-content span.item-phone,
    .quickview-popover .popover-content span.item-location,
    .quickview-popover .popover-content span.item-location-label,
    .quickview-popover .popover-content span.item-timezone {
      font-size: 12px;
      padding: 0px 7px;
      display: inline-block;
      width: 100%; }
    .quickview-popover .popover-content span.item-location {
      padding: 0 !important; }
    .quickview-popover .popover-content span.item-location-label {
      padding: 0 !important; }
    .quickview-popover .popover-content .fetching-popover {
      margin-bottom: 20px;
      margin-top: 20px; }
    .quickview-popover .popover-content .quickview-list-wrapper {
      padding: 10px 7px 0px 7px; }
      .quickview-popover .popover-content .quickview-list-wrapper.hiringclients {
        margin-top: 7px;
        border-top: 1px solid #CFCFD0; }
      .quickview-popover .popover-content .quickview-list-wrapper ul, .quickview-popover .popover-content .quickview-list-wrapper li {
        list-style: none;
        padding: 0;
        margin: 0; }
      .quickview-popover .popover-content .quickview-list-wrapper li {
        font-size: 11px; }
        .quickview-popover .popover-content .quickview-list-wrapper li:before {
          content: '-'; }
      .quickview-popover .popover-content .quickview-list-wrapper h5 {
        font-size: 12px;
        color: #60818A;
        margin: 0;
        font-weight: 700; }
      .quickview-popover .popover-content .quickview-list-wrapper.item-log-quickview {
        border-top: 1px solid #CFCFD0;
        margin-top: 12px;
        padding-top: 21px;
        padding-bottom: 13px; }
        .quickview-popover .popover-content .quickview-list-wrapper.item-log-quickview li {
          margin: 10px 0 5px; }
          .quickview-popover .popover-content .quickview-list-wrapper.item-log-quickview li:before {
            content: ''; }
          .quickview-popover .popover-content .quickview-list-wrapper.item-log-quickview li .item-log-itemname, .quickview-popover .popover-content .quickview-list-wrapper.item-log-quickview li .target-link {
            font-weight: 700;
            color: #60818A; }
        .quickview-popover .popover-content .quickview-list-wrapper.item-log-quickview h5.icon-log:before {
          color: #60818A;
          margin-right: 3px;
          font-size: 14px;
          font-weight: 200;
          position: relative;
          top: 1px; }
    .quickview-popover .popover-content .more-logs {
      text-align: center;
      padding: 0 0 12px;
      margin-top: 20px; }
      .quickview-popover .popover-content .more-logs .bn-more-logs {
        margin-right: 0;
        font-size: 11px;
        padding: 3px 7px;
        background: transparent;
        color: #60818A;
        cursor: pointer; }

.no-results-msg {
  color: #2E5965;
  text-align: center;
  margin-top: 30px;
  margin-bottom: -10px;
  font-size: 15px; }

.light-blue {
  color: #34ACFE;
  font-weight: bold; }

.add-user-modal .modal-body {
  max-height: 700px;
  overflow-y: scroll; }

.add-user-modal .admin-form-field-wrapper label[for="role"].required:before,
.add-user-modal .admin-form-field-wrapper label[for="cfRole"].required:before {
  right: 112px !important; }

.add-user-modal .admin-form-field-wrapper input[type="checkbox"]:checked + span.add-user-check:after {
  left: 9px !important; }

.ReactTags__tagInputField[name="associatedSC"] {
  display: none !important; }

.SubcontractorSelect .react-tags-wrapper .ReactTags__selected {
  padding: 0 !important; }

.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center; }

.text-center {
  text-align: center !important;
  display: block;
  margin-top: 10px; }

.type-ahead-search-bar {
  padding: 0; }

.add-item-view {
  min-height: 350px; }

.type-ahead-search-bar .results-container {
  max-height: 200px;
  overflow: auto; }

.type-ahead-search-bar-container {
  width: 100%; }

.linear-icon-circle-cross {
  float: right;
  padding-top: 5px;
  font-size: 30px; }

.email-checkbox {
  height: fit-content; }

.email-checkbox:checked:after {
  top: -10px !important; }

.filter-header {
  display: flex;
  align-items: center; }

.switch {
  margin-left: 20px;
  margin-bottom: 10px; }
