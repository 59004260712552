.change-status-modal .first-row {
  display: flex;
  justify-content: center; }

.change-status-modal .change-status-modal-header {
  color: #FFFFFF;
  font-size: 20px; }

.change-status-modal .change-status-content {
  font-size: 15px;
  padding: 30px 50px; }
  .change-status-modal .change-status-content .statusContainer {
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px; }
    .change-status-modal .change-status-content .statusContainer label {
      color: #2E5965;
      font-weight: 500;
      margin-right: 20px; }
    .change-status-modal .change-status-content .statusContainer select {
      height: 32px; }
  .change-status-modal .change-status-content .action-button {
    margin-left: 20px; }
