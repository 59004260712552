.view-task-modal .suspended {
  position: absolute;
  z-index: 100px; }

.view-task-modal .first-row {
  display: flex;
  justify-content: center; }

.view-task-modal .view-task-modal-header {
  background-color: #7ED0BC !important;
  color: #305866 !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  height: 60px !important;
  padding: 25px !important; }

.view-task-modal .view-task-content {
  font-size: 15px;
  padding: 50px; }
  .view-task-modal .view-task-content .view-task-label {
    color: #6c8994 !important;
    font-size: 13px !important; }
  .view-task-modal .view-task-content .view-task-text, .view-task-modal .view-task-content .description-text {
    background-color: white !important;
    border: 0.5px solid lightgray !important;
    border-radius: 3px !important;
    color: #000 !important;
    padding: 7px 10px;
    margin: 2px 10px 30px 0;
    font-size: 13px !important; }
  .view-task-modal .view-task-content .normalFontWeight {
    font-weight: normal !important; }
  .view-task-modal .view-task-content .description-text {
    margin: 2px 10px 30px 0;
    min-height: 200px;
    padding: 7px 10px; }

.view-task-modal .action-button {
  margin-left: 20px; }

.view-task-modal button, .view-task-modal a {
  border-radius: 6px !important;
  border-bottom: 4px solid #305866 !important;
  text-transform: uppercase !important; }
