.question-tab .question-tab-content {
  padding: 50px 100px; }
  .question-tab .question-tab-content .question-tab-label {
    color: #2E5965;
    font-weight: bold;
    font-size: 14px; }
  .question-tab .question-tab-content .question-tab-text {
    background-color: #EFEFEF;
    border: none;
    color: #7ED0BC;
    font-weight: 500;
    padding: 7px 10px;
    margin: 2px 10px 30px 0; }
  .question-tab .question-tab-content .file-input {
    background-color: #F3FBF9;
    border: 2px dashed #E3E7EF;
    color: #2E5965;
    cursor: pointer;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    padding: 20px 0;
    text-align: center;
    width: 100%; }
  .question-tab .question-tab-content .description-input {
    border: 1px solid #29AEA2;
    color: #000;
    min-height: 100px;
    padding: 7px 10px;
    width: 100%; }
  .question-tab .question-tab-content .admin-form-field-wrapper {
    padding-right: 0px !important; }

.question-tab span.field-validation-message {
  right: 3px;
  top: 0; }

.question-tab .upload-file-wrapper span.field-validation-message {
  right: -316px !important;
  top: -20px !important; }
