.certificates .documents-scroll {
  color: #fff !important;
  background: #020024;
  background: linear-gradient(0deg, #020024 0%, #363980 18%, #696cb1 67%);
  border: 1px solid #080c5b;
  padding: 5px;
  padding-left: 12px;
  margin: 0;
  text-transform: uppercase !important;
  font-weight: bold;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer; }
  .certificates .documents-scroll:before {
    margin-right: 10px;
    font-weight: 100;
    color: white; }

.certificates .buttons-section {
  background-color: darkslategray;
  padding-top: 6px;
  padding-bottom: 6px;
  justify-content: center; }

.certificates .info-section span {
  white-space: nowrap;
  font-size: 13px; }

.certificates input[type="text"] {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .175rem .35rem;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.certificates .sm-input {
  width: 200px !important; }

.certificates a.bn {
  padding: 11px 30px; }

.certificates .disabledBtn {
  cursor: not-allowed;
  opacity: 0.5 !important; }

.certificates button.bn:disabled {
  opacity: 0.2; }

.certificates .bn-small {
  padding-left: 22px !important;
  padding-right: 22px !important; }

.certificates .compliance-status {
  font-weight: 400;
  font-size: x-large;
  text-align: center;
  color: #fff;
  text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
  height: 70px;
  padding-top: 18px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
  .certificates .compliance-status.compliant {
    background-color: #8CC739; }
  .certificates .compliance-status.non-compliant {
    background-color: #f04c41; }
  .certificates .compliance-status.escalated {
    background-color: #FEAB0F; }
  .certificates .compliance-status.minor {
    padding-top: 10px;
    background-color: #FEEC0F; }
  .certificates .compliance-status.on-hold {
    background-color: #2BAFF4; }

.large-modal {
  margin-top: 0;
  min-height: 650px !important; }

.view-comments {
  margin-left: 10px;
  text-align: justify; }
  .view-comments td:first-child {
    white-space: nowrap;
    vertical-align: top !important; }
