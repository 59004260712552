.common-spinner-wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 100, 100, 0.5);
  position: absolute;
  z-index: 100000;
  display: flex; }

.common-spinner-wrapper .common-spinner {
  border: 6px solid #D1D8E5;
  border-top: 6px solid #2E5965;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: inline-block; }
