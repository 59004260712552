.hiring-clients-list .hiring-clients-list-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap; }

h1.list-view-title.hc {
  color: #2E5965;
  font-style: italic;
  font-weight: 200;
  font-size: 23px;
  text-transform: uppercase;
  position: relative;
  top: -76px; }

.list-view section.list-view-table .table-wrapper td a.hctable-link {
  text-transform: lowercase;
  color: #34ACFE;
  text-decoration: underline;
  cursor: pointer; }
  .list-view section.list-view-table .table-wrapper td a.hctable-link:hover {
    text-decoration: none; }

.list-view section.list-view-table.hc-list-both-roles .table-wrapper td {
  padding: 0px 11px;
  line-height: 28px;
  vertical-align: middle; }

.list-view section.list-view-table.hc-list .table-wrapper th:first-child {
  padding-left: 10px; }

.list-view section.list-view-table.hc-list .table-wrapper td:first-child {
  padding-left: 21px; }

.add-item-view .add-item-form-subsection .admin-form-field-wrapper > label {
  cursor: pointer;
  user-select: none; }

.add-item-view .add-item-form-subsection .admin-form-field-wrapper.wiz-field .select-wrapper {
  width: 100%; }

.add-item-view .add-item-form-subsection .admin-form-field-wrapper.wiz-field label[for="changeuponlogin"] span.label {
  top: -3px;
  cursor: pointer;
  user-select: none; }

.add-item-modal .modal-content .modal-body.add-item-modal-body {
  padding: 0;
  margin: 0; }

.add-item-modal.add-hc .modal-dialog {
  max-width: 89.9%; }

.wiz-wrapper header {
  background: #2E5965;
  padding-top: 40px;
  padding-bottom: 50px; }

h2.modal-wiz-title {
  color: #FFFFFF;
  text-align: center;
  font-size: 19px;
  margin-bottom: 50px;
  font-weight: 400;
  text-transform: uppercase; }

h2.modal-title {
  color: #FFFFFF;
  text-align: center;
  font-size: 19px;
  font-weight: 400; }

.modal-header-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }
  .modal-header-container .return-icon {
    background-color: transparent;
    border: none;
    position: absolute;
    left: 0;
    color: #ffffff;
    font-weight: 500;
    padding: 0; }
    .modal-header-container .return-icon span {
      padding-left: 5px; }

.configuration-modal-main-container {
  position: relative;
  max-height: 70vh;
  padding: 20px 10px; }
  .configuration-modal-main-container .configuration-modal-container {
    display: block;
    width: 100%;
    column-count: 2;
    column-gap: 30px;
    padding-bottom: 60px; }
    .configuration-modal-main-container .configuration-modal-container .configuration-modal-item {
      break-inside: avoid;
      width: 541px;
      height: fit-content;
      margin-bottom: 30px; }
      .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-header {
        display: flex;
        align-items: center;
        gap: 10px; }
        .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-header h3 {
          font-size: 20px;
          font-weight: 500; }
      .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%; }
        .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body {
          display: flex;
          flex-direction: column;
          width: 100%;
          border-radius: 4px;
          border: 1px solid #2E5965; }
          .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .error-msg {
            color: #e74a3b;
            margin-bottom: 0; }
          .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 5px;
            background-color: #509aaf;
            width: 100%;
            padding: 5px;
            text-align: center;
            color: #ffffff;
            font-weight: 500;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            border-bottom: 1px solid #2E5965;
            transition: all 0.3s ease-out; }
          .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .btn:hover {
            background-color: #2E5965;
            color: #ffffff; }
          .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .btn:disabled {
            cursor: not-allowed; }
          .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container {
            display: flex;
            flex-direction: column;
            width: 100%; }
            .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              border-top: 1px solid #2E5965; }
              .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .content-content-label {
                width: 50%;
                padding: 5px 10px;
                font-size: 16px;
                margin-bottom: 0;
                text-align: start;
                font-weight: 400;
                border-right: 1px solid #2E5965;
                word-break: break-word; }
              .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .content-content-item {
                display: flex;
                width: 50%;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                padding: 5px 10px; }
                .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .content-content-item h5 {
                  width: 150px;
                  font-size: 16px;
                  margin-bottom: 0;
                  font-weight: 400; }
                .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .content-content-item .btn {
                  background-color: transparent;
                  border-radius: 4px;
                  font-size: 14px;
                  color: #2E5965;
                  border: 1px solid #2E5965;
                  padding: 2px 5px;
                  width: fit-content;
                  transition: all 0.3s ease-out; }
                .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .content-content-item .btn:hover {
                  background-color: #2E5965;
                  color: #ffffff; }
              .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container {
                display: flex;
                align-items: flex-start;
                width: 100%; }
                .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-label-input-container {
                  width: 50%;
                  padding: 5px 10px;
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column; }
                  .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-label-input-container .label {
                    margin: 0 0 5px 0;
                    font-size: 16px;
                    font-weight: 500; }
                  .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-label-input-container input {
                    width: 100%;
                    outline: 0;
                    font-size: 14px;
                    padding: 2px 7px; }
                .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container {
                  width: 50%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 5px 10px; }
                  .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons {
                    display: flex;
                    align-items: flex-end;
                    width: 100%;
                    justify-content: space-between; }
                    .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons .editable-dropdown {
                      width: 50%;
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      gap: 5px; }
                      .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons .editable-dropdown .label {
                        font-size: 16px;
                        font-weight: 500;
                        margin: 0; }
                      .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons .editable-dropdown .dropdown-content {
                        padding: 5px 7px;
                        outline: none;
                        font-size: 14px;
                        cursor: pointer;
                        text-transform: capitalize;
                        width: 100%; }
                        .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons .editable-dropdown .dropdown-content .dropdown-content-option {
                          cursor: pointer;
                          text-transform: capitalize; }
                    .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons .editable-btn-container {
                      display: flex;
                      align-items: center;
                      gap: 5px; }
                      .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons .editable-btn-container .save {
                        background-color: #509aaf;
                        color: #ffffff;
                        border: 1px solid #509aaf; }
                      .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons .editable-btn-container .cancel {
                        background-color: transparent;
                        color: #e74a3b;
                        border: 1px solid #e74a3b; }
                      .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons .editable-btn-container .save, .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons .editable-btn-container .cancel {
                        border-radius: 4px;
                        font-size: 14px;
                        padding: 5px 7px;
                        width: fit-content;
                        transition: all 0.3s ease-out; }
                      .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons .editable-btn-container .cancel:hover {
                        background-color: #e74a3b;
                        color: #ffffff; }
                      .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-container .content-content .editable-content-container .editable-dropdown-container .editable-dropdown-buttons .editable-btn-container .save:hover {
                        background-color: #2E5965;
                        color: #ffffff; }
          .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend {
            display: flex;
            align-items: flex-start;
            width: 100%;
            position: relative; }
            .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .label-input {
              width: 50%;
              padding: 5px 10px;
              display: flex;
              flex-direction: column;
              gap: 5px; }
              .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .label-input .label {
                font-size: 16px;
                margin-bottom: 0; }
              .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .label-input .label-input {
                width: 100%;
                padding: 2px 7px;
                font-size: 14px; }
              .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .label-input .label-input:focus {
                outline: none; }
            .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options {
              width: 50%;
              padding: 5px 10px; }
              .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%; }
                .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons .content-content-dropdown {
                  width: 50%;
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  gap: 5px; }
                  .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons .content-content-dropdown .content-content-dropdown-label {
                    font-size: 16px;
                    margin: 0; }
                  .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons .content-content-dropdown .dropdown-content {
                    width: 100%;
                    padding: 5px 7px;
                    outline: none;
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 14px;
                    text-transform: capitalize; }
                    .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons .content-content-dropdown .dropdown-content .dropdown-content-option {
                      cursor: pointer;
                      border-radius: 0;
                      text-transform: capitalize; }
                .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons .btn-container {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  gap: 5px;
                  margin-left: 5px;
                  width: 50%; }
                  .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons .btn-container .save {
                    background-color: #509aaf;
                    color: #ffffff;
                    border: 1px solid #509aaf; }
                  .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons .btn-container .cancel {
                    background-color: transparent;
                    color: #e74a3b;
                    border: 1px solid #e74a3b; }
                  .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons .btn-container .save, .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons .btn-container .cancel {
                    border-radius: 4px;
                    font-size: 14px;
                    padding: 5px 7px;
                    width: fit-content;
                    transition: all 0.3s ease-out; }
                  .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons .btn-container .cancel:hover {
                    background-color: #e74a3b;
                    color: #ffffff; }
                  .configuration-modal-main-container .configuration-modal-container .configuration-modal-item .item-content .content-body .content-content-extend .content-content-options .content-content-dropdown-buttons .btn-container .save:hover {
                    background-color: #2E5965;
                    color: #ffffff; }

.wiz-wrapper h2.step-title {
  color: #2E5965;
  text-align: center;
  font-size: 19px;
  margin-bottom: 50px;
  margin-top: 30px;
  font-weight: 500;
  text-transform: uppercase; }

.step-icons {
  padding: 0;
  margin: 0;
  display: block;
  text-align: center; }
  .step-icons li {
    display: inline-block;
    padding: 0;
    margin: 0;
    position: relative; }
    .step-icons li:first-child .step-icon-bubble:before {
      display: none; }
    .step-icons li:first-child .step-icon-bubble .step-icon.icon-company:before {
      font-size: 29px;
      position: relative;
      width: 19px;
      display: inline-block;
      top: 5px;
      left: -6px; }
    .step-icons li .step-icon-bubble {
      background: #D6D7D6;
      padding: 9px;
      border-radius: 20px;
      margin: 0 30px; }
      .step-icons li .step-icon-bubble.active {
        background: #FFFFFF; }
      .step-icons li .step-icon-bubble .step-icon:before {
        color: #2E5965; }
      .step-icons li .step-icon-bubble:before {
        content: 'a';
        display: inline-block;
        position: absolute;
        color: transparent;
        border-top: 5px solid #D6D7D6;
        width: 60px;
        left: -30px;
        top: 10px; }

.step-body.add-item-form-subsection {
  display: none; }
  .step-body.add-item-form-subsection.active {
    display: block; }

form.wiz-form {
  padding-left: 20px;
  padding-bottom: 30px;
  padding-right: 70px;
  height: 470px;
  position: relative; }

.newhc-form form.wiz-form {
  min-height: 445px;
  height: auto;
  position: relative; }

.newhc-form .wiz-buttons {
  display: block;
  width: 100%;
  bottom: 20px;
  right: 74px; }

.wiz-buttons {
  text-align: right;
  padding-right: 17px;
  padding-top: 20px; }
  .wiz-buttons .wiz-cancel-button {
    color: #2E5965 !important;
    text-transform: uppercase;
    font-size: 14px;
    font-style: italic;
    display: inline-block;
    margin-left: 15px;
    cursor: pointer;
    margin-right: 20px; }
    .wiz-buttons .wiz-cancel-button:hover {
      text-decoration: underline !important; }
  .wiz-buttons button.wiz-continue-btn {
    text-transform: uppercase;
    border-bottom: 4px solid #2E5965; }

.wiz-submitting-btn {
  color: #2E5965 !important; }

.wiz-continue-btn-disabled {
  filter: opacity(0.3); }

.save-hc-modal-error {
  position: absolute;
  display: inline-block;
  width: 100%;
  bottom: 50px;
  text-align: center;
  color: red;
  font-size: 12px;
  pointer-events: none; }

.wiz-field label + .select-wrapper:after {
  border-top: 6px solid #2E5965; }

.wiz-field label + .select-wrapper select {
  background: transparent; }
  .wiz-field label + .select-wrapper select.invalid {
    border-color: #FF0000; }

[name=companyLogo] + span.field-validation-message {
  left: 0 !important;
  right: auto !important; }

.upload-file-wrapper {
  border: 2px dashed #29AEA2;
  background-color: #f9f9f9; }
  .upload-file-wrapper label#upload-file-wrapper {
    height: 18.75rem;
    cursor: pointer !important;
    font-size: 0.7rem !important;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column; }
  .upload-file-wrapper div span {
    top: 1rem !important; }

.upload-file-wrapper .field-validation-message {
  width: 100%;
  margin-left: 0; }

.select-parent, .select-user, .select-entity-type {
  font-size: .8rem;
  font-style: italic; }

.hidden-field-wrapper {
  display: none; }

.person-contact-tabs {
  margin-top: 5px; }

a.wiz-tab-link {
  color: #2E5965 !important;
  cursor: pointer;
  text-transform: uppercase;
  display: block;
  background: #F2F5F5;
  margin-left: -35px;
  padding: 6px 10px 3px 35px;
  margin-right: 30px;
  font-weight: 100;
  font-size: 13.4px; }
  a.wiz-tab-link.active {
    font-weight: 700;
    background: #7ED0BC;
    position: relative; }
    a.wiz-tab-link.active:after {
      content: 'a';
      position: absolute;
      color: transparent;
      width: 0;
      height: 0;
      display: inline-block;
      border-left: 15px solid #7ED0BC;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      top: -1px;
      right: -15px; }

.wiz-tab-container {
  display: none; }
  .wiz-tab-container.active {
    display: block; }
  .wiz-tab-container input[type=checkbox] {
    width: 10px !important;
    padding: 5px !important;
    vertical-align: text-top; }

form.operator-form {
  padding-top: 100px;
  padding-right: 20px;
  margin-right: -20px; }

.hc-modal-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.5);
  z-index: 10000;
  position: absolute;
  top: -5;
  bottom: -5; }

.newhcuser-form {
  display: flex;
  flex-direction: column;
  align-items: center; }

.add-item-modal.add-hcuser {
  background: rgba(255, 255, 255, 0);
  border-top: 5px; }
  .add-item-modal.add-hcuser .modal-content {
    width: 600px;
    height: 300px;
    border-radius: 0;
    border: none;
    box-shadow: 0 0 0.3em #666666; }
    .add-item-modal.add-hcuser .modal-content .modal-body {
      margin-top: 5px; }

.assign-hc-title-small {
  color: rgba(46, 89, 101, 0.7);
  font-weight: lighter;
  font-style: italic;
  font-size: 14px;
  font-weight: bold; }

.assign-hc-title {
  color: #60818A;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: 1px; }

.assign-hc-button {
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: 1px;
  background: linear-gradient(to bottom right, #7ED0BC, #29AEA2);
  height: 50px;
  width: 190px;
  border: none;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 5px solid #2E5965;
  margin-top: 30px; }

.add-hcuser-backdrop {
  background: rgba(255, 255, 255, 0.5) !important; }

.assign-hc-select {
  background-color: #FFFFFF;
  width: 350px;
  height: 40px;
  border-color: #E3E7EF; }

.assign-hc-label {
  margin-top: 60px;
  color: #2E5965;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px; }
