.robot-verification-container {
  width: 100vw;
  height: calc(100vh - 100px);
  display: flex;
  background-color: #FFF; }

.robot-verification-nav-bar {
  height: 100px;
  width: 100vw;
  display: flex;
  padding: 16px 75px; }

.robot-verification-nav-bar img {
  float: left;
  width: 160px;
  height: 50px;
  max-height: 50px; }

.robot-section {
  width: 50vw;
  position: relative; }

.robot-section .robot-image {
  max-width: 400px; }

.robot-content-center {
  position: absolute;
  top: 40%;
  transform: translateY(-40%); }

.robot-section-left {
  left: 20px; }

.robot-section-left > div {
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-width: 350px;
  color: #23285C; }

.robot-section-left > div > p {
  font-size: 16px !important; }

.robot-section-left > div > img {
  max-width: 200px; }

.robot-section-right {
  right: 20px; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.main-container {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@media only screen and (max-width: 600px) {
  .robot-verification-container {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
  .robot-content-center > div:nth-child(2) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center; }
  .robot-section-left > div {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    max-width: 100vw;
    color: #23285C; }
  .robot-section {
    width: 100vw;
    padding: 0px 16px; }
  .robot-content-center {
    position: relative;
    top: 0;
    transform: none; }
  .robot-section-left, .robot-section-right {
    left: 0; }
  .robot-section-right > img {
    width: 250px;
    margin: 30px 0px; }
  .robot-verification-nav-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .main-container {
    padding-bottom: 40px; } }
