.tasks-list .limitedWidthText {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.tasks-list .task-certificate-link {
  display: block; }

.tasks-list .tasks-list-table .status-cell.inprogress::before {
  color: orange; }

.tasks-list .tasks-list-table .status-cell.completed::before {
  color: #29AEA2; }

.urgent {
  color: red; }

.span-left {
  border-right: 1px solid; }

a.totalizerLink {
  cursor: pointer;
  margin: 0 5px; }

.title {
  background-color: transparent !important; }
