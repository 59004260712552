.holders-profile .section-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap; }

.holders-profile .holder-logo {
  max-width: 100%;
  max-height: 250px; }

.holders-profile .profile-view-header-title {
  color: #2E5965;
  font-weight: bold;
  margin-bottom: 0; }

.holders-profile .profile-view-info-table {
  width: 100%;
  height: 100%;
  max-width: 100%;
  -webkit-box-shadow: rgba(46, 89, 101, 0.5) 0px 0px 5px;
  box-shadow: rgba(46, 89, 101, 0.5) 0px 0px 5px;
  border-radius: 5px;
  margin-bottom: 0; }
  .holders-profile .profile-view-info-table td {
    vertical-align: initial;
    padding: 0.4rem;
    border-top: none; }
    .holders-profile .profile-view-info-table td:first-child {
      padding-left: 5%;
      color: #2E5965;
      font-weight: bold; }
