.type-ahead-search-bar-container {
  position: relative;
  max-width: 100%;
  width: 370px;
  height: 40px;
  margin: 0 10px 0 10px; }

.type-ahead-search-bar {
  width: 100%;
  padding: 0 35px;
  position: absolute; }
  .type-ahead-search-bar .searchResults {
    display: flex;
    padding: 8px 20px 8px 20px;
    cursor: pointer; }
    .type-ahead-search-bar .searchResults:hover {
      background-color: rgba(128, 128, 128, 0.5); }
  .type-ahead-search-bar .input-container {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 8px 30px 8px 25px;
    position: relative; }
    .type-ahead-search-bar .input-container input {
      outline: none;
      border: none;
      overflow: hidden;
      background-color: transparent;
      font-size: 13px;
      width: 90%; }
    .type-ahead-search-bar .input-container svg {
      color: rgba(1, 1, 1, 0.38);
      position: absolute;
      top: 12px; }
  .type-ahead-search-bar .results-container {
    padding: 0 14px; }
    .type-ahead-search-bar .results-container .spinner-wrapper {
      margin: 0 0 10px 0; }
    .type-ahead-search-bar .results-container .user-container {
      margin-bottom: 10px; }
      .type-ahead-search-bar .results-container .user-container .img-border {
        width: 34px;
        height: 34px; }
      .type-ahead-search-bar .results-container .user-container .img-container .profile-initials,
      .type-ahead-search-bar .results-container .user-container .img-container .profile-picture {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 15px; }
      .type-ahead-search-bar .results-container .user-container .basic-info {
        display: flex;
        flex-direction: row;
        height: 34px;
        align-items: center;
        padding-left: 15px;
        padding-right: 30px;
        width: 100%;
        justify-content: space-between; }
        .type-ahead-search-bar .results-container .user-container .basic-info .name {
          padding-right: 5px; }
        .type-ahead-search-bar .results-container .user-container .basic-info .role {
          text-align: right; }
        .type-ahead-search-bar .results-container .user-container .basic-info .name, .type-ahead-search-bar .results-container .user-container .basic-info .role {
          font-size: 13px;
          font-weight: normal; }
  .type-ahead-search-bar .grey-border {
    border: 1px solid rgba(112, 112, 112, 0.2); }

.type-ahead-search-bar.focused {
  border-radius: 5px;
  border: 1px solid #CCCCCC;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 20px;
  padding: 0;
  z-index: 6; }
  .type-ahead-search-bar.focused .input-container {
    padding-left: 14px;
    padding-right: 14px;
    background-color: transparent; }
  .type-ahead-search-bar.focused .grey-border {
    border: none; }
