.pdf-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; }
  .pdf-buttons-container .pdf-button {
    height: 40px;
    min-width: 130px;
    border-radius: 5px;
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: #29AEA2;
    border-bottom-color: #305866;
    border-bottom-width: 4px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 20px; }

.formSheetContainer {
  width: 100%; }
  .formSheetContainer .multi-select-field {
    overflow-x: auto;
    width: 100%; }
    .formSheetContainer .multi-select-field .multi-select__placeholder {
      color: black; }
    .formSheetContainer .multi-select-field .multi-select__control {
      border-color: #B9B9B9;
      border-width: 2px;
      border-radius: 5px; }
    .formSheetContainer .multi-select-field .multi-select__value-container {
      overflow-x: auto;
      display: flex;
      max-height: 126px;
      overflow-y: auto; }
    .formSheetContainer .multi-select-field .multi-select__multi-value {
      flex: 0 0 auto; }
    .formSheetContainer .multi-select-field .multi-select__menu {
      position: fixed; }
  .formSheetContainer .field {
    border-style: solid;
    pointer-events: all;
    width: 100%;
    height: fit-content;
    border-radius: 5px; }
  .formSheetContainer label {
    user-select: none; }
  .formSheetContainer .fileContainer {
    border-style: solid;
    border-radius: 5px;
    font-size: 12px;
    margin: 0;
    justify-content: left;
    overflow: hidden;
    height: auto;
    display: inline-block;
    text-overflow: ellipsis; }
    .formSheetContainer .fileContainer input[type="file"] {
      display: none; }
    .formSheetContainer .fileContainer .fileButton {
      cursor: pointer;
      pointer-events: auto;
      background-color: lightgrey;
      border-radius: 5px;
      border: 1px solid #ccc;
      display: inline-block;
      padding: 1px 3px;
      margin: 1px 5px 2px -8px;
      min-width: 45px; }
  .formSheetContainer .incomplete::after {
    pointer-events: none;
    position: absolute;
    float: left;
    top: 0px;
    right: 7px;
    color: red;
    content: "*"; }
  .formSheetContainer .incomplete[name="DropDown"]::after,
  .formSheetContainer .incomplete[name="Source of financial statement"]::after,
  .formSheetContainer .incomplete[name="Period"]::after,
  .formSheetContainer .incomplete[name="Company Type"]::after {
    right: 18px; }
  .formSheetContainer .incomplete[name="CheckBox"]::after,
  .formSheetContainer .incomplete[name="Radio Button"]::after {
    right: -8px; }
  .formSheetContainer .incomplete[name="Date"]::after,
  .formSheetContainer .incomplete[name="Financial statement date"]::after {
    top: -2px; }
  .formSheetContainer input[type=date] {
    overflow: auto; }

.form-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .form-header-container .form-title {
    margin: 50px;
    color: #42b9f4;
    font-size: 25px; }
  .form-header-container .form-description {
    width: 50%;
    font-size: 18px;
    margin-bottom: 50px; }

.form-footer-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  min-height: 80px; }
  .form-footer-container .btn-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 150px; }
  .form-footer-container .page-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 40px;
    margin-right: 40px;
    overflow-x: auto; }
  .form-footer-container .btn-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
  .form-footer-container .continue-btn {
    background-color: #42b9f4;
    color: white;
    border-radius: 6px;
    border-color: #42b9f4;
    border: solid;
    min-width: 150px;
    min-height: 45px;
    font-size: 14px; }
  .form-footer-container .gray-btn {
    background-color: #d1d8e6;
    color: #8d929c;
    border-radius: 4px;
    border: none;
    min-width: 150px;
    min-height: 30px;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px; }
  .form-footer-container .blue-btn {
    background-color: #42b9f4;
    color: white;
    border-color: #42b9f4;
    border-radius: 4px;
    border: none;
    min-width: 150px;
    min-height: 30px;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px; }
  .form-footer-container .page-number-btn {
    background-color: white;
    color: #42b9f4;
    border: solid;
    border-radius: 30px;
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
    font-size: 15px;
    padding: 0;
    margin-right: 5px;
    pointer-events: none; }
  .form-footer-container .selected-page {
    background-color: #42b9f4;
    color: white;
    border-color: #42b9f4;
    border: none; }

.viewFileLink {
  pointer-events: auto;
  color: white;
  background-color: #42b9f4;
  cursor: pointer;
  font-size: 13px;
  float: right;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 1px 4px;
  margin: 0px 2px 1px 0px;
  min-width: 39px; }

.fileInputContainer {
  position: relative;
  width: 100% !important; }
  .fileInputContainer .viewFileSpinnerContainer {
    float: right;
    margin-right: 12px !important;
    margin-top: 3px !important;
    padding: 0 !important; }
    .fileInputContainer .viewFileSpinnerContainer .spinner-wrapper {
      margin: 0 !important; }
      .fileInputContainer .viewFileSpinnerContainer .spinner-wrapper .spinner {
        height: 18px !important;
        width: 18px !important; }
