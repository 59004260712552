.progress-bar-main {
  position: sticky;
  bottom: 15px;
  right: 15px;
  float: right;
  width: 350px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.75);
  z-index: 999;

  .header {
    padding: 8px 8px 8px 12px;
    background: #292929;
    display: flex;
    color: #BABABA;
    align-items: center;

    .title {
      flex-grow: 1;
      color: inherit;
      padding: 0;
      font-size: 15px;
    }

    .action {
      gap: 5px;
      display: flex;
      align-items: center;
    }
  }

  .content {
    font-size: 13px;
    background: #FFFFFF;

    .item {
      display: flex;
      align-items: center;
      padding: 5px 12px;
      border-bottom: 1px solid #bdbdbd;

      &:last-child {
        border-bottom: none;
      }

      .file-icon {
        color: #085DAD;
      }

      .name {
        flex-grow: 1;
        margin-left: 5px;
        line-height: 15px;
        max-width: 270px;

        p {
          text-overflow: ellipsis;
          width: 270px;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 0;
        }

        div {
          font-size: 11px;
        }
      }

      .process {
        color: #085DAD;
      }
    }
  }
}
